import * as React from "react";
import {
 Button,
 Paper,
 Box,
 TextField,
 Select,
 FormControl,
 InputLabel,
 MenuItem,
 Divider,
 FormLabel,
 FormControlLabel,
 Radio,
 RadioGroup,
 Switch,
 List,
 InputAdornment,
 Pagination,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SearchIcon from "@mui/icons-material/Search";

import AutoLoadSite from "../components/AutoLoadSite";
import { PageContainer, PageContent } from "../components/styled-components";
import DeleteRecordDialog from "../components/Search/DeleteRecordDialog";
import Result from "../components/Search/Result";

import { Context } from "../SDK/context";

import FOV from "../SDK/src/main";

/**
 * Object mapping record types to mappings of select element labels to
 * their corresponding search attribute string.
 */
const attributeMapping = {
 burial: {
  "Full Name": "forename,surname",
  Forename: "forename",
  Surname: "surname",
 },
 monument: { Name: "name" },
 poi: { Name: "name" },
};

const perPage = 20;

/**
 * Search page containing search parameter form and quicklook list.
 */
const Search = () => {
 const { state } = React.useContext(Context);
 const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
 const [recordToDelete, setRecordToDelete] = React.useState(false);

 const [searchQuery, setSearchQuery] = React.useState("");
 const [recordType, setRecordType] = React.useState("burial");
 const [searchAttribute, setSearchAttribute] = React.useState(Object.keys(attributeMapping.burial)[0]);
 // search filter states
 const [appActive, setAppActive] = React.useState("both");
 const [notable, setNotable] = React.useState("both");
 // search results array
 const [results, setResults] = React.useState([]);

 // search result found pages
 const [pages, setPages] = React.useState(1);
 // current page
 const [page, setPage] = React.useState(1);

 const [previousSearch, setPreviousSearch] = React.useState({});

 const [loadingResults, setLoadingResults] = React.useState(false);
 const [viewAll, setViewAll] = React.useState(false);

 /**
  * Sets the record to delete state to the provided record and
  * opens the delete modal.
  */
 const deleteRecord = async (record) => {
  setRecordToDelete(record);
  setDeleteModalOpen(true);
 };

 /**
  * Updates record type state to new type.
  * If the currently selected search attribute is invalid for the new
  * type, sets the search attribute state to the first valid value.
  * Resets app active filter state and notable filter state to "both".
  * Resets found pages and page state to 1.
  * @param {*} e select value change event
  */
 const handleChangeType = (e) => {
  const newType = e.target.value;
  setRecordType(newType);
  if (!Object.keys(attributeMapping[newType]).includes(searchAttribute)) {
   setSearchAttribute(Object.keys(attributeMapping[newType])[0]);
  }
  setAppActive("both");
  setNotable("both");
  setPages(1);
  setPage(1);
 };

 /**
  * Calls performSearch.
  * @param {*} e search form submit event
  */
 const handleSubmit = async (e) => {
  e.preventDefault();
  performSearch();
 };

 /**
  * Attempts to perform a record search.
  * Initialises results state to an empty array.
  * Combines filter states into a single string, separated by '&&'.
  * Generates a search config and resets pages and page state to 1 if
  * parameters have changed.
  * Sets previous search state to the new search config.
  * Sets results state to the array of results and updates pages state
  * on success.
  * Sets loading results state to true for the duration of the search.
  * @param {*} page
  */
 const performSearch = async (page = 1) => {
  setLoadingResults(true);
  setResults([]);
  const attribute = attributeMapping[recordType][searchAttribute];

  let queries = [];
  if (appActive !== "both") {
   queries.push("active:" + appActive);
  }
  if (notable !== "both") {
   queries.push("notable:" + notable);
  }

  const currentSearch = {
   cemetery: state.selectedSite,
   type: recordType + "s",
   attribute,
   queries: queries.join("&&"),
   searchQuery: viewAll ? "" : searchQuery,
  };

  for (let key of Object.keys(currentSearch)) {
   if (previousSearch[key] !== currentSearch[key]) {
    setPages(1);
    setPage(1);
    page = 1;
   }
  }
  setPage(page);
  setPreviousSearch(currentSearch);

  try {
   const result = await FOV.api.search(currentSearch.cemetery, currentSearch.type, currentSearch.attribute, currentSearch.queries, "", currentSearch.searchQuery, page, perPage);
   if (result.data.hits) {
    const totalResults = result.data.found;
    const perPage = result.data.request_params.per_page;
    const totalPages = Math.ceil(totalResults / perPage);
    setPages(totalPages);
    const ids = result.data.hits.map((hit) => hit.document.id.split("_")[1]);
    if (ids.length) {
     const batchResult = await FOV.api.bulkGet(state.selectedSite, recordType, ids);
     let results = ids.map((id) => {
      for (let item of batchResult.data.Responses.Cemetery) {
       if (item.SK.split("_")[1] === id) {
        return item;
       }
      }
      return null;
     });
     results = results.filter((a) => a !== null);
     setResults(results);
    }
   }
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
  setLoadingResults(false);
 };

 return (
  <PageContainer className="scrollbar-hidden">
   <AutoLoadSite />
   <DeleteRecordDialog open={deleteModalOpen} setOpen={setDeleteModalOpen} recordToDelete={recordToDelete} setRecordToDelete={setRecordToDelete} results={results} setResults={setResults} />
   <PageContent
    sx={{
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     height: "100%",
     flexGrow: 1,
    }}
   >
    <Paper
     sx={{
      background: (theme) => theme.palette.tertiary.light,
      m: 1,
      p: 1,
      width: "100%",
      maxWidth: "1200px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
     }}
    >
     <form
      style={{
       display: "flex",
       flexDirection: "column",
       width: "100%",
      }}
      onSubmit={handleSubmit}
     >
      <Box sx={{ display: "flex" }}>
       <TextField
        disabled={viewAll}
        sx={{
         m: 1,
         display: "flex",
         flexGrow: 1,
         backgroundColor: viewAll ? (theme) => theme.palette.grey.main : "none",
        }}
        value={searchQuery}
        onChange={(e) => {
         setSearchQuery(e.target.value);
        }}
        InputProps={{
         startAdornment: (
          <InputAdornment position="start">
           <SearchIcon />
          </InputAdornment>
         ),
        }}
       />
       <Paper sx={{ m: "auto", color: (theme) => theme.palette.primary.main }}>
        <FormControlLabel sx={{}} control={<Switch checked={viewAll} onChange={(e) => setViewAll(e.target.checked)} />} label="SHOW ALL" labelPlacement="top" />
       </Paper>
       <LoadingButton loading={loadingResults} sx={{ m: 1 }} variant="contained" type="submit">
        Submit
       </LoadingButton>
      </Box>
      <Box
       sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
       }}
      >
       <FormControl sx={{ display: "flex", flexGrow: 1, m: 1 }}>
        <InputLabel id="demo-simple-select-label">Record Type</InputLabel>
        <Select sx={{ display: "flex" }} variant="standard" value={recordType} name="test" label="Record Type" onChange={handleChangeType}>
         <MenuItem value={"burial"}>Burial</MenuItem>
         <MenuItem value={"monument"}>Monument</MenuItem>
         <MenuItem value={"poi"}>Point of Interest</MenuItem>
        </Select>
       </FormControl>

       <FormControl sx={{ display: "flex", flexGrow: 1, m: 1 }}>
        <InputLabel id="demo-simple-select-label">Search Attribute</InputLabel>
        <Select
         sx={{ display: "flex" }}
         variant="standard"
         value={searchAttribute}
         label="Search Attribute"
         onChange={(e) => {
          setSearchAttribute(e.target.value);
         }}
        >
         {Object.keys(attributeMapping[recordType]).map((attribute) => (
          <MenuItem key={attribute} value={attribute}>
           {attribute}
          </MenuItem>
         ))}
        </Select>
       </FormControl>

       <Button type="submit" sx={{ display: "none" }}></Button>
      </Box>
      <Box
       sx={{
        display: "flex",
        flexDirection: { sx: "column", md: "row" },
        justifyContent: "space-evenly",
       }}
      >
       {recordType !== "poi" && (
        <FormControl component="fieldset">
         <FormLabel component="legend">Display in mobile app</FormLabel>
         <RadioGroup
          row
          aria-label="Display in mobile app"
          name="row-radio-buttons-group"
          defaultValue="both"
          value={appActive}
          onChange={(e) => {
           setAppActive(e.target.value);
          }}
         >
          <FormControlLabel value="true" control={<Radio />} label="True" />
          <FormControlLabel value="false" control={<Radio />} label="False" />
          <FormControlLabel value="both" control={<Radio />} label="both" />
         </RadioGroup>
        </FormControl>
       )}
       {recordType === "burial" && (
        <FormControl component="fieldset">
         <FormLabel component="legend">Notable</FormLabel>
         <RadioGroup
          row
          aria-label="Notable"
          name="row-radio-buttons-group"
          defaultValue="both"
          value={notable}
          onChange={(e) => {
           setNotable(e.target.value);
          }}
         >
          <FormControlLabel value="true" control={<Radio />} label="True" />
          <FormControlLabel value="false" control={<Radio />} label="False" />
          <FormControlLabel value="both" control={<Radio />} label="both" />
         </RadioGroup>
        </FormControl>
       )}
      </Box>
     </form>
    </Paper>
    <Divider />
    <Paper
     elevation={2}
     sx={{
      m: 1,
      display: "flex",
      flexGrow: 1,
      flexBasis: 0,
      flexShrink: 1,
      width: "100%",
      maxWidth: "1200px",
      minHeight: "300px",
      background: "none",
     }}
    >
     <List
      className="scrollbar-hidden"
      sx={{
       p: 1,
       overflowY: "scroll",
       width: "100%",
      }}
     >
      {results.map((result) => (
       <Result key={result.SK} record={result} deleteRecord={deleteRecord} />
      ))}
     </List>
    </Paper>
    <Pagination
     page={page}
     count={pages}
     shape="rounded"
     onChange={(e, page) => {
      performSearch(page);
     }}
    />
   </PageContent>
  </PageContainer>
 );
};

export default Search;
