import MonumentDetails from "./Monument/MonumentDetails";
import BurialDetails from "./Burial/BurialDetails";
import PoiDetails from "./Poi/PoiDetails";
import TourDetails from "./Tour/TourDetails";
import OrganizationDetails from "./Organization/OrganizationDetails";

/**
 * Returns the appropriate form details component for the given type
 * and passes on the formState and setFormState function as props
 * @param {*} param0 object containing form item type, formState, and setFormState function
 * @returns specific form details component for the given type
 */
const FormDetails = ({ type, formState, setFormState }) => {
 switch (type) {
  case "burial":
   return <BurialDetails formState={formState} setFormState={setFormState} />;
  case "monument":
   return <MonumentDetails formState={formState} setFormState={setFormState} />;
  case "poi":
   return <PoiDetails formState={formState} setFormState={setFormState} />;
  case "tour":
   return <TourDetails formState={formState} setFormState={setFormState} />;
  case "organization":
   return <OrganizationDetails formState={formState} setFormState={setFormState} />;
  default:
   return <></>;
 }
};

export default FormDetails;
