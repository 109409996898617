import React from "react";
import { MapContext } from "react-mapbox-gl";

/**
 * Utility component to trigger the mapbox map to jump to a location
 * when this component is mounted
 */
const JumpTo = ({ location, zoom = 17 }) => {
 const map = React.useContext(MapContext);

 const [lastLocation, setLastLocation] = React.useState([0, 0]);
 const [lastZoom, setLastZoom] = React.useState(17);

 React.useEffect(() => {
  // Update coordinates using the map context api
  if (
   Math.round(location[0] * 100000) != Math.round(lastLocation[0] * 100000) ||
   Math.round(location[1] * 100000) != Math.round(lastLocation[1] * 100000) ||
   parseFloat(zoom) != parseFloat(lastZoom)
  ) {
   location && map.flyTo({ center: location, zoom });

   setLastLocation(location);
   setLastZoom(zoom);
  }
 }, [location, zoom]);

 return null;
};

export default JumpTo;
