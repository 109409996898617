import * as React from "react";
import { Button, Paper, Typography, Divider, Box } from "@mui/material";

import { PageHeader, PageContainer, PageContent } from "../components/styled-components";

import { Context } from "../SDK/context";
import AutoLoadSite from "../components/AutoLoadSite";

import UploadDialog from "../components/Upload/UploadDialog";

import FOV from "../SDK/src/main";

import Guide from "../components/Upload/Guide";
import UploadProgressBox from "../components/Upload/UploadProgressBox";
import PreviewTable from "../components/Upload/PreviewTable";

/**
 * Bulk Upload Page containing upload form and upload preview table
 */
const Upload = ({ type }) => {
 const { state } = React.useContext(Context);
 const [lastBulkUploader, setLastBulkUploader] = React.useState();
 const [file, setFile] = React.useState({ name: "No zip selected" });
 const [uploading, setUploading] = React.useState(false);
 const [progress, setProgress] = React.useState();
 const [columns, setColumns] = React.useState([]);
 const [dataSource, setDataSource] = React.useState([]);

 /**
  * Sets progress state to provided values
  */
 function progressCallback(values) {
  setProgress(values);
 }

 /**
  * Attempts initiate the bulk upload process for records in a provided zip file to the database and S3 bucket.
  * Initialises progress state to an empty array.
  * Initialises data source state to an empty array.
  * Sets uploading state to false.
  * Sets file state to the provided file.
  * On success, sets columns and data source state from the result in order to preview the upload.
  * On success, also sets the last bulk uploader state to the bulk uploader instance used so that its upload
  * function can be invoked in order to continue the bulk upload.
  * @param {*} e upload form submit event
  */
 const onUpload = async (e) => {
  const file = e.target.files[0];
  if (!file) {
   return;
  }
  setProgress([]);
  setDataSource([]);
  setUploading(false);
  setFile(file);
  const result = await FOV.api.bulkUpload(state.selectedSite, type, file, progressCallback);

  if (result) {
   const cols = result.preview.columns;
   const dataSrc = result.preview.comparisons.map((comp, index) => ({
    ...comp,
    key: index,
   }));

   setColumns(cols);
   setDataSource(dataSrc);

   setLastBulkUploader(result.bulkUploader);
  }
 };

 /**
  * Attempts to complete the bulk upload operation initiated via onUpload.
  * Resets progress state to an empty array.
  * Resets data source state to an empty array.
  * Sets uploading state to true for the duration of the upload.
  */
 const upload = async () => {
  setUploading(true);
  setProgress([]);
  setDataSource([]);
  await lastBulkUploader.upload(progressCallback);
  setUploading(false);
 };

 return (
  <PageContainer
   sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    flexGrow: 1,
   }}
   className="scrollbar-hidden"
  >
   <AutoLoadSite />
   <UploadDialog open={uploading} setOpen={setUploading} progress={progress} uploading={uploading} />
   <PageHeader>{type.charAt(0).toUpperCase() + type.slice(1)}s</PageHeader>
   <PageContent
    sx={{
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     height: "100%",
     flexGrow: 1,
    }}
   >
    <Guide type={type} />
    <Divider sx={{ width: "100%" }} />
    <Paper
     elevation={3}
     sx={{
      display: "flex",
      flexDirection: "column",
      m: 2,
      p: 1,
      alignItems: "center",
      background: (theme) => theme.palette.form.main,
      minWidth: 0,
      maxWidth: "300px",
     }}
    >
     <Typography noWrap={true} color="primary" sx={{ textAlign: "center", maxWidth: "100%", minWidth: 0 }}>
      {file.name}
     </Typography>
     <Button variant="contained" component="label" sx={{ width: "100%", minWidth: 0 }}>
      Click to {file.size ? "update" : "add"} Zip
      <input type="file" hidden onChange={onUpload} />
     </Button>
     <Typography color="error" sx={{ textAlign: "center", maxWidth: "100%" }}>
      {file.size && file.name.split(".").pop() !== "zip" ? "File must be a zip" : ""}
     </Typography>
    </Paper>
    {progress && (
     <>
      <UploadProgressBox
       variant="determinate"
       progress={progress.progress * 100}
       process={progress.process}
       state={progress.state}
       message={progress.message}
       uploading={uploading}
      />
     </>
    )}
    <Divider sx={{ mt: 2, width: "100%" }} />
    {dataSource.length > 0 && (
     <Box
      sx={{
       display: "flex",
       flexGrow: 1,
       minHeight: 0,
       width: "100%",
       maxWidth: "100%",
       minHeight: "400px",
      }}
     >
      <PreviewTable headers={columns} rows={dataSource} upload={upload} />
     </Box>
    )}
   </PageContent>
  </PageContainer>
 );
};

export default Upload;
