import FOV from "./SDK/src/main";

/**
 * Splits an array into chunks and returns an array of those chunks.
 * @param {*} arr array to be split
 * @param {*} size size of chunks to split array into
 * @returns Array of arrays produced from splitting the provided array
 */
function chunkArrayInGroups(arr, size) {
 var myArray = [];
 for (var i = 0; i < arr.length; i += size) {
  myArray.push(arr.slice(i, i + size));
 }
 return myArray;
}

/**
 * Updates the value in state with the key corresponding to the
 * input name to the text input value.
 * @param {*} event input change event
 * @param {*} setter state setter
 * @param {*} state state
 */
const handleTextChange = (event, setter, state) => {
 setter({
  ...state,
  [event.target.name]: event.target.value,
 });
};

/**
 * Updates the value in state with the key corresponding to the
 * input name to the input value parsed as an int, or 0 if that fails.
 * @param {*} event input change event
 * @param {*} setter state setter
 * @param {*} state state
 */
const handleNumberChange = (event, setter, state) => {
 setter({
  ...state,
  [event.target.name]: parseInt(event.target.value, 10) || 0,
 });
};

/**
 * Updates the value in state with the key corresponding to the
 * input name to the input value parsed as a float or an empty string
 * if that fails.
 * Takes into account tailing 0s.
 * @param {*} event input change event
 * @param {*} setter state setter
 * @param {*} state state
 */
const handleFloatChange = (event, setter, state) => {
 const hasDecimal = event.target.value.includes(".");
 const decimalValue = (hasDecimal ? event.target.value.split(".") : [event.target.value, ""])[1];
 const parsed = parseFloat(event.target.value).toFixed(decimalValue.length);
 const value = isNaN(parsed) ? "" : parsed;
 setter({
  ...state,
  [event.target.name]: value,
 });
};

/**
 * Updates the value in state with the key corresponding to the
 * input name to the input checked value.
 * @param {*} event input change event
 * @param {*} setter state setter
 * @param {*} state state
 */
const handleSwitchChange = (event, setter, state) => {
 setter({
  ...state,
  [event.target.name]: event.target.checked,
 });
};

/**
 * Attempts to delete all of the provided media from the S3 bucket.
 * @param {*} mediaToRemove array of media objects with media type and name
 * @param {*} cemeteryOrOrganization Id of the cemetery or organization to which the media belongs
 * @param {*} type type of record containing the media
 * @param {*} id ID of the record
 */
const deleteMedia = (mediaToRemove, cemeteryOrOrganization, type, id) => {
 let mediaRemovePromises = [];
 for (let media of mediaToRemove) {
  mediaRemovePromises.push(
   new Promise(async (resolve, reject) => {
    try {
     await FOV.api.removeMedia(cemeteryOrOrganization, type, id.split("_")[1], media.type, media.name);
     resolve();
    } catch (err) {
     reject(err);
    }
   })
  );
 }
};

/**
 * Attempts to upload all of the provided media to the S3 bucket.
 * @param {*} mapping array of media objects with media file, type, and url.
 */
const uploadMedia = (mapping) => {
 let mediaUploadPromises = [];
 for (let map of mapping) {
  mediaUploadPromises.push(
   new Promise(async (resolve, reject) => {
    try {
     await FOV.api.uploadMedia(map.file, map.type, map.url, true);
     resolve();
    } catch (err) {
     reject(err);
    }
   })
  );
 }
 return Promise.all(mediaUploadPromises);
};

/**
 * Generates a new record item, replacing any temporary media urls with the
 * corresponding url from the provided mapping array.
 * @param {*} mapping array of media objects with media type, tempUrl, and url
 * @param {*} state original record item
 * @param {*} media new media urls including temporary urls
 */
const getNewItem = (mapping, state, media) => {
 const tempItem = { ...state };
 let tempMedia = [...media];
 for (let map of mapping) {
  if (map.type === "Media") {
   tempMedia = tempMedia.map((url) => {
    if (url === map.tempUrl) {
     return map.url.split("?")[0];
    } else {
     return url;
    }
   });
  } else {
   tempItem[map.type] = map.url.split("?")[0];
  }
 }
 tempItem.Media = tempMedia.join(";");
 return tempItem;
};

/**
 * Attempts to fetch media urls for all new media to upload, then resolves with an array
 * containing media objects with media url, type, tempUrl, and file.
 * @param {*} mediaToUpload Array of media objects to upload
 * @param {*} cemeteryOrOrganization Id of the cemetery or organization to which the media belongs
 * @param {*} type type of record containing the media
 * @param {*} id ID of the record
 */
const getMediaUrls = (mediaToUpload, cemeteryOrOrganization, type, id) => {
 let mediaUrlPromises = [];
 for (let mtu of mediaToUpload) {
  mediaUrlPromises.push(
   new Promise(async (resolve, reject) => {
    try {
     const result = await FOV.api.getMediaUrl(cemeteryOrOrganization, type, id.split("_")[1], mtu.type);
     resolve({
      url: result.data.url,
      type: mtu.type,
      tempUrl: mtu.tempUrl,
      file: mtu.file,
     });
    } catch (err) {
     reject(err);
    }
   })
  );
 }
 return Promise.all(mediaUrlPromises);
};

/**
 * Handles uploading new media for a record.
 * Generates an array of media to remove, including any existing media with the
 * same name or type (if not type "Media") as an image being uploaded.
 * Uses getMediaUrls and then calls uploadMedia with the result to upload the
 * new media.
 * Calls deleteMedia with the media to remove array to delete all media that needs
 * to be removed.
 * Returns mapping generated by getMediaUrls.
 * @param {*} newMedia object containing record media fields
 * @param {*} state record state
 * @param {*} cemeteryOrOrganization Id of the cemetery or organization to which the media belongs
 * @param {*} type of record containing the media
 * @param {*} id ID of the record
 */
const handleMedia = async (newMedia, state, cemeteryOrOrganization, type, id) => {
 let mediaToRemove = [];
 let mediaToUpload = [];
 for (let key of Object.keys(newMedia)) {
  if (key === "Media") {
   const originalMedia = state.Media.split(";");
   for (let url of Object.keys(newMedia.Media)) {
    if (originalMedia.includes(url)) {
     mediaToRemove.push({ type: key, name: url.split("/").pop() });
    } else if (newMedia.Media[url]) {
     mediaToUpload.push({
      type: key,
      tempUrl: url,
      file: newMedia.Media[url],
     });
    }
   }
  } else {
   if (state[key]) {
    mediaToRemove.push({
     type: key,
     name: state[key].split("/").pop(),
    });
   }
   if (newMedia[key]) {
    mediaToUpload.push({ type: key, file: newMedia[key] });
   }
  }
 }

 try {
  const mapping = await getMediaUrls(mediaToUpload, cemeteryOrOrganization, type, id);
  await uploadMedia(mapping);
  try {
   await deleteMedia(mediaToRemove, cemeteryOrOrganization, type, id);
  } catch (err) {
   console.error(err);
  }
  return mapping;
 } catch (err) {
  console.error(err);
 }
};

export { chunkArrayInGroups, handleTextChange, handleNumberChange, handleFloatChange, handleSwitchChange, deleteMedia, handleMedia, getNewItem };
