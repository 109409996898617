import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "get" method,
 * accepting an organization argument in the sendRequest function,
 * updating the url to organization
 */
class GetOrganization extends Request {
 constructor() {
  super("get", ``);
 }
 sendRequest(organization) {
  this.url = `organization/${organization}`;
  return this.sendRequestPromise({});
 }
}

export default GetOrganization;
