import * as React from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";

import { Toolbar, IconButton, Drawer, Box, AppBar, Menu, MenuItem, Typography, Backdrop, CircularProgress } from "@mui/material";

import Sidebar from "./components/Sidebar.js";

import { ReactComponent as Logo } from "./assets/memory-anchor.svg";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";

import AutoSignIn from "./components/AutoSignIn";
import { Context } from "./SDK/context";

import routes from "./routes.js";

const appBarHeight = 50;
const navBarWidth = 230;

/**
 * Router component, wrapping all of the content of the app.
 * Renders the current route.
 * Conditionally renders a side navbar and topbar.
 */
function Router(props) {
 const { state, logout, setLoading, getCemeteryItems } = React.useContext(Context);

 const { window } = props;
 const [mobileOpen, setMobileOpen] = React.useState(false);

 const [drawerWidth, setDrawerWidth] = React.useState(0);

 /**
  * When the state cemeteries list changes, calls getCemeteryItems with the list.
  */
 React.useEffect(() => {
  if (state.cemeteries) {
   getCemeteryItems(state.cemeteries);
  }
 }, [state.cemeteries]);

 /**
  * When the waiting or signedIn state changes, checks whether to show the navbar
  * (show if waiting but also signed in or show if waiting but at the home route).
  * If the waiting state is false, sets component loading state to false.
  */
 React.useEffect(() => {
  if ((!state.waiting && state.signedIn) || (state.waiting && document.location.pathname != "/")) {
   setDrawerWidth(navBarWidth);
  } else {
   setDrawerWidth(0);
  }
  if (!state.waiting) {
   setLoading(false);
  }
 }, [state.waiting, state.signedIn]);

 /**
  * Attempts to logout a user.
  * Sets the loading state to true and closes the user menu.
  */
 const logoutUser = async () => {
  setLoading(true);
  handleCloseUserMenu();
  try {
   await logout();
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
 };

 /**
  * Toggles the sidebar drawer (on mobile)
  */
 const handleDrawerToggle = () => {
  setMobileOpen(!mobileOpen);
 };

 const container = window !== undefined ? () => window().document.body : undefined;

 const [anchorElUser, setAnchorElUser] = React.useState(null);

 /**
  * Opens user menu
  */
 const handleOpenUserMenu = (event) => {
  setAnchorElUser(event.currentTarget);
 };
 /**
  * Closes user menu
  */
 const handleCloseUserMenu = () => {
  setAnchorElUser(null);
 };

 return (
  <BrowserRouter>
   {/* Auto sign in functionality */}
   <AutoSignIn />
   {/* Global loading backdrop */}
   <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={state.loading || state.loadingRecord || (state.loadingSites && !state.waiting && state.signedIn)}>
    <CircularProgress color="inherit" />
   </Backdrop>
   {/* App bar, side bar, routed content */}
   <Box sx={{ display: "flex", height: "100vh" }}>
    {/* App bar */}
    <AppBar
     position="fixed"
     sx={{
      width: { sm: `calc(100% - ${drawerWidth}px)` },
      ml: { sm: `${drawerWidth}px` },
      height: `${appBarHeight}px`,
      display: "flex",
      justifyContent: "center",
      background: (theme) => theme.palette.primary.main,
     }}
    >
     <Toolbar
      sx={{
       display: "flex",
       justifyContent: (!state.waiting && state.signedIn) || (state.waiting && document.location.pathname != "/") ? "space-between" : "center",
      }}
     >
      <IconButton
       color="inherit"
       aria-label="open drawer"
       edge="start"
       onClick={handleDrawerToggle}
       sx={{
        mr: 2,
        opacity: { xs: drawerWidth ? "1" : "0", sm: "0" },
        pointerEvents: { xs: drawerWidth ? "all" : "none", sm: "none" },
        height: `${appBarHeight - 10}`,
        width: `${appBarHeight - 10}`,
        display: (!state.waiting && state.signedIn) || (state.waiting && document.location.pathname != "/") ? "flex" : "none",
       }}
      >
       <MenuIcon
        sx={{
         height: `${appBarHeight - 10}px`,
         width: `${appBarHeight - 10}px`,
        }}
       />
      </IconButton>
      <Logo
       fill="white"
       height={`40px`}
       style={{
        position: "absolute",
        right: 0,
        left: 0,
        margin: "auto",
       }}
      />
      <Box
       sx={{
        ml: "auto",
        mr: 0,
        display: {
         xs: "none",
         md: (!state.waiting && state.signedIn) || (state.waiting && document.location.pathname != "/") ? "block" : "none",
        },
        textAlign: "right",
       }}
      >
       <Typography fontSize="large" sx={{ lineHeight: 1 }}>
        {state.email?.split("@")[0]}
       </Typography>
       <Typography fontSize="small" sx={{ fontStyle: "italic", lineHeight: 1 }}>
        {state.email ? `@${state.email.split("@")[1]}` : ""}
       </Typography>
      </Box>
      <Box
       sx={{
        position: "sticky",
        bottom: 0,
        display: (!state.waiting && state.signedIn) || (state.waiting && document.location.pathname != "/") ? "flex" : "none",
       }}
      >
       <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenUserMenu} color="inherit">
        <AccountCircle sx={{ height: "40px", width: "auto" }} />
       </IconButton>
      </Box>
      <Menu
       sx={{ mt: "45px" }}
       id="menu-appbar"
       anchorEl={anchorElUser}
       anchorOrigin={{
        vertical: "top",
        horizontal: "right",
       }}
       keepMounted
       transformOrigin={{
        vertical: "top",
        horizontal: "right",
       }}
       open={Boolean(anchorElUser)}
       onClose={handleCloseUserMenu}
      >
       <MenuItem component="a" href="https://memoryanchor.com/account">
        <Typography textAlign="center" color="primary" fontSize="large" sx={{ textDecoration: "none" }}>
         Account
        </Typography>
       </MenuItem>

       <MenuItem component="a" href="https://memoryanchor.com/cloudcontact">
        <Typography textAlign="center" color="primary" fontSize="large" sx={{ textDecoration: "none" }}>
         Contact
        </Typography>
       </MenuItem>
       <MenuItem component="a" href="https://memoryanchor.com/help">
        <Typography textAlign="center" color="primary" fontSize="large" sx={{ textDecoration: "none" }}>
         Help
        </Typography>
       </MenuItem>
       <MenuItem>
        <Typography textAlign="center" color="primary" onClick={logoutUser} fontSize="large">
         Logout
        </Typography>
       </MenuItem>
      </Menu>
     </Toolbar>
    </AppBar>
    {/* Side bar */}
    <Box
     component="nav"
     sx={{
      width: { sm: drawerWidth },
      flexShrink: { sm: 0 },
      zIndex: 1,
      boxShadow: "-5px 0px 10px rgba(0,0,0,0.2)",
     }}
     aria-label="mailbox folders"
    >
     <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
       keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
       display: { xs: "block", sm: "none" },
       "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        width: drawerWidth,
        maxWidth: drawerWidth,
       },
      }}
     >
      <Sidebar />
     </Drawer>
     <Drawer
      variant="permanent"
      sx={{
       display: { xs: "none", sm: drawerWidth ? "block" : "none" },
       "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        width: drawerWidth,
        position: "static",
        height: "100vh",
       },
      }}
      open
     >
      <Sidebar />
     </Drawer>
    </Box>
    {/* Content */}
    <Box
     component="main"
     sx={{
      flexGrow: 1,
      height: `calc(100% - ${appBarHeight}px)`,
      width: { sm: `calc(100% - ${drawerWidth}px)` },
      maxWidth: "100%",
      marginTop: `${appBarHeight}px`,
     }}
    >
     {routes}
    </Box>
   </Box>
  </BrowserRouter>
 );
}

export default Router;
