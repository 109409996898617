import { canvastoFile, filetoDataURL, imagetoCanvas, compressAccurately } from "image-conversion";

/**
 * Attempts to compress an image file, resolving with a
 * compressed blob, and rejecting with an error
 */
function convertAndCompress(file, size) {
 return new Promise(async (resolve, reject) => {
  // Image to convert to canvas
  try {
   const image = new Image();
   try {
    image.src = await filetoDataURL(file);
   } catch (e) {
    console.error(e);
    reject(e);
    return;
   }
   // Listen for the image to be ready
   image.onload = async function () {
    try {
     // Create a canvas from the image
     const canvas = await imagetoCanvas(image, {
      type: "image/png",
      width: image.naturalWidth,
      height: image.naturalHeight,
     });
     // Convert the canvas to a blob
     const canvasFile = await canvastoFile(canvas, 1, "image/png");
     const compressedFile = await compressAccurately(canvasFile, size);
     // Resolve with the blob
     resolve(compressedFile);
    } catch (e) {
     console.error(e);
     reject(e);
    }
   };
  } catch (e) {
   console.error(e);
   resolve(file); // fallback to passthrough
  }

  //   compress(file, {
  //    type: "image/png",
  //   })
  //    .then((blob) => {
  //     resolve(blob);
  //    })
  //    .catch((err) => {
  //     console.error(err);
  //     reject(err);
  //    });
 });
}

export { convertAndCompress };

// import { compress } from "image-conversion";

// /**
//  * Attempts to compress an image file, resolving with a
//  * compressed blob, and rejecting with an error
//  */
// function convertAndCompress(file, size) {
//  return new Promise((resolve, reject) => {
//   compress(file, {
//    type: "image/png",
//   })
//    .then((blob) => {
//     resolve(blob);
//    })
//    .catch((err) => {
//     console.error(err);
//     reject(err);
//    });
//  });
// }

// export { convertAndCompress };
