import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardActionArea, CardMedia, CardContent, Box, Typography, CardActions, Button } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import placeholderSite from "../../assets/PlaceholderSite.png";

import { Context } from "../../SDK/context";

/**
 * Site display card with buttons to trigger site navigation and deletion
 */
const SiteCard = ({ site, handleDeleteSite }) => {
 const navigate = useNavigate();
 const { state, setSelectedSite } = React.useContext(Context);

 function goTo(page) {
  setSelectedSite(site.SK);
  navigate(`/${page}/${site.SK}`);
 }

 return (
  <Card
   sx={{
    flexShrink: 0,
    margin: "30px auto",
    width: "100%",
    maxWidth: "780px",
    transition: "0.25s",
    ":hover": {
     boxShadow: 15,
    },
   }}
  >
   <CardActionArea
    onClick={() => {
     goTo("Overview");
    }}
   >
    <CardMedia
     component="img"
     height="180"
     image={
      site.Photo ||
      // "https://www.thesprucepets.com/thmb/hThcFCxT20ql0opGe4B8WGICbc4=/1851x1851/smart/filters:no_upscale()/cat-talk-eyes-553942-hero-df606397b6ff47b19f3ab98589c3e2ce.jpg"
      placeholderSite
     }
     alt="site logo"
    />
    <CardContent>
     <Box
      sx={{
       display: "flex",
       flexDirection: "row",
       justifyContent: "space-between",
       alignItems: "center",
      }}
     >
      <Typography variant="h5" component="div">
       {site.Name}
      </Typography>
      <Box
       sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        transition: "0.25s",
        ":hover": {
         color: (theme) => theme.palette.error.main,
        },
       }}
       color="error"
       onClick={(e) => {
        e.stopPropagation();
        handleDeleteSite(site);
       }}
      >
       <DeleteIcon />
      </Box>
     </Box>
     <Typography variant="body2" color="text.secondary">
      {site.Description && `${(site.Description || "").slice(0, 130)}${(site.Description || "").length > 130 ? "..." : ""}`}
     </Typography>
    </CardContent>
   </CardActionArea>
   <CardActions sx={{ display: "flex", justifyContent: "space-around" }}>
    <Button
     onClick={() => {
      goTo("Overview");
     }}
     sx={{ flexGrow: 1 }}
     color="primary"
     variant="outlined"
    >
     Overview
    </Button>
    <Button
     onClick={() => {
      goTo("Search");
     }}
     sx={{ flexGrow: 1 }}
     color="primary"
     variant="outlined"
    >
     Records
    </Button>
    <Button
     onClick={() => {
      goTo("Pathing");
     }}
     sx={{ flexGrow: 1 }}
     color="primary"
     variant="outlined"
    >
     Pathing
    </Button>
    <Button
     onClick={() => {
      goTo("Tours");
     }}
     sx={{ flexGrow: 1 }}
     color="primary"
     variant="outlined"
    >
     Tours
    </Button>
   </CardActions>
  </Card>
 );
};

export default SiteCard;
