import { v4 } from "uuid";
/**
 * Generates the default form for burials
 * @returns form object containing default burial properties
 */
const getDefaultBurialForm = () => ({
 Active: false,
 Id: v4(),
 BurialTypeVeteran: false,
 Notable: false,
 Forename: "",
 MiddleNames: "",
 Surname: "",
 Initials: "",
 Aliases: "",
 Nationality: "",
 Regiment: "",
 SecondaryRegiment: "",
 Country: "",
 EducationalHonours: "",
 CountryCommemorations: "",
 MonumentCommemorations: "",
 CountryOfService: "",
 Rank: "",
 Unit: "",
 ForceServiceBranch: "",
 Trade: "",
 DoB: "",
 DoD: "",
 DoD2: "",
 AgeAtDeath: 0,
 HonoursAwards: "",
 Description: "",
 Conflicts: "",
 FamilyInfo: "",
 GraveRefLot: "",
 GraveRefBlock: "",
 GraveRefSection: "",
 PlotOrMonument: "",
 Status: "",
 DoI: "",
 EstateDetails: "",
 Weblinks: "",
 Aux: "",
 Photo: "",
 Media: "",
 Lat: 0,
 Lng: 0,
});

/**
 * Generates the default form for monuments
 * @returns form object containing default monument properties
 */
const getDefaultMonumentForm = () => ({
 Active: false,
 Id: v4(),
 Name: "",
 Country: "",
 Locality: "",
 MonumentRefLocation: "",
 StateProvince: "",
 Accessibility: "",
 Owner: "",
 DedicatedBy: "",
 DedicatedDate: "",
 Conflicts: "",
 Casualties: 0,
 Artist: "",
 Architect: "",
 Description: "",
 Weblinks: "",
 Labels: "",
 Aux: "",
 Photo: "",
 Media: "",
 Lat: 0,
 Lng: 0,
});

/**
 * Generates the default form for POIs
 * @returns form object containing default POI properties
 */
const getDefaultPoiForm = () => ({
 Active: true,
 Id: v4(),
 Name: "",
 Description: "",
 Weblinks: "",
 Photo: "",
 Media: "",
 Lat: 0,
 Lng: 0,
});

/**
 * Generates the default form for tours
 * @returns form object containing default tour properties
 */
const getDefaultTourForm = () => ({
 Active: false,
 Name: "",
 Description: "",
 DateStart: "",
 DateEnd: "",
 ExpectedDuration: "",
 Logo: "",
 Media: "",
});

/**
 * Generates the default form for organizations
 * @returns form object containing default organization properties
 */
const getDefaultOrganizationForm = () => ({
 Name: "",
 Description: "",
 Weblinks: "",
 Logo: "",
 Media: "",
});

/**
 * Generates the default form for cemeteries
 * @returns form object containing default cemetery properties
 */
const getDefaultCemeteryForm = () => ({
 Active: false,
 Name: "",
 Country: "",
 City: "",
 StateProvince: "",
 Operator: "",
 Website: "",
 PrimaryAccountHolder: "",
 Casualties: 0,
 Description: "",
 Highlights: "",
 Photo: "",
 Logo: "",
 Map: "",
 Media: "",
 Accessibility: "",
 MapALat: 0,
 MapBLat: 0,
 MapCLat: 0,
 MapDLat: 0,
 MapALng: 0,
 MapBLng: 0,
 MapCLng: 0,
 MapDLng: 0,
});

/**
 * Calls the appropriate default form generator for the provided
 * type and returns the response
 * @param {*} type type of item to generate the default form for
 * @returns default form object for the provided type
 */
const getDefaultForm = (type) => {
 switch (type) {
  case "burial":
   return getDefaultBurialForm();
  case "monument":
   return getDefaultMonumentForm();
  case "poi":
   return getDefaultPoiForm();
  case "tour":
   return getDefaultTourForm();
  case "organization":
   return getDefaultOrganizationForm();
  case "cemetery":
   return getDefaultCemeteryForm();
  default:
   return {};
 }
};

export { getDefaultBurialForm, getDefaultForm };
