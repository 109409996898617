import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Button, TextField, Paper, Box, Divider, CircularProgress } from "@mui/material";

import AutoLoadSite from "../components/AutoLoadSite";
import { PageHeader, FooterButtons, PageContainer, PageContent } from "../components/styled-components";

import NewTourDialog from "../components/Tour/NewTourDialog";
import DeleteTourDialog from "../components/Tour/DeleteTourDialog";
import TourCard from "../components/Tour/TourCard";
import Fuse from "fuse.js";
import { Context } from "../SDK/context";

import FOV from "../SDK/src/main";

/**
 * Attempts to repeatedly bulk fetch tour records in batches
 * for a provided cemetery.
 * Returns a sorted list of tours.
 */
async function getAllTours(cemetery) {
 let gotAll = false;
 let startKey = undefined;
 let allTours = [];
 while (!gotAll) {
  try {
   const result = await FOV.api.getRecords(cemetery, "tour", startKey, 100);
   allTours.push(...result.data.Items);
   if (result.data.LastEvaluatedKey) {
    startKey = result.data.LastEvaluatedKey;
   } else {
    gotAll = true;
   }
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
   gotAll = true;
  }
 }
 return allTours.sort((a, b) => a.Name.localeCompare(b.Name));
}

/**
 * Tours page containing a searchable list of filterable tours
 */
const Tours = () => {
 const navigate = useNavigate();
 const { state } = React.useContext(Context);
 const [newDialogOpen, setNewDialogOpen] = React.useState(false);
 const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
 const [tourToDelete, setTourToDelete] = React.useState(false);
 const [tours, setTours] = React.useState([]);
 const [filteredTours, setFilteredTours] = React.useState([]);
 const [loadingTours, setLoadingTours] = React.useState(true);

 /**
  * When the selected site changes, fetches all tours and updates the tours
  * state with the result. Sets loading tours state to false once complete.
  */
 React.useEffect(async () => {
  if (!state.selectedSite) {
   return;
  }
  const allTours = await getAllTours(state.selectedSite);
  setLoadingTours(false);
  setTours(allTours);
 }, [state.selectedSite]);

 /**
  * When the tours array state changes, resets filtered tours state to the
  * whole array of tours.
  */
 React.useEffect(async () => {
  setFilteredTours(tours);
 }, [tours]);

 /**
  * Navigates to the Tour page for a provided tour
  */
 const goToTour = (tour) => {
  navigate(`/Tour/${state.selectedSite}/${tour.SK.split("_")[1]}`);
 };

 /**
  * Opens new tour dialog
  */
 const handleNewTour = () => {
  setNewDialogOpen(true);
 };

 /**
  * Opens delete tour dialog and sets the tour to delete state
  * to the provided tour
  */
 const handleDeleteTour = (tour) => {
  setDeleteDialogOpen(true);
  setTourToDelete(tour);
 };

 /**
  * Performs a fuzzy search using a query input and
  * updates the filtered tours list to the result.
  * @param {*} event tour search input change event
  */
 function fuzzySearch(event) {
  if (!event.target.value) {
   setFilteredTours(tours);
   return;
  }
  const options = {
   keys: ["Name"],
  };
  const fuse = new Fuse(tours, options);
  setFilteredTours([...fuse.search(event.target.value).map((a) => a.item)]);
 }

 return (
  <PageContainer className="scrollbar-hidden">
   <AutoLoadSite />
   <NewTourDialog open={newDialogOpen} setOpen={setNewDialogOpen} goToTour={goToTour} />
   <DeleteTourDialog
    open={deleteDialogOpen}
    setOpen={setDeleteDialogOpen}
    results={tours}
    setResults={setTours}
    tourToDelete={tourToDelete}
    setTourToDelete={setTourToDelete}
   />
   <PageHeader> Tours </PageHeader>
   <PageContent>
    <Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
     <Paper sx={{ display: "flex", m: 1 }}>
      <TextField autoComplete="off" varient="standard" id="outlined-required" label="Filter Tours" onChange={fuzzySearch} />
     </Paper>
    </Box>
    <Divider />
    {loadingTours && !(state.loading || state.loadingSites || state.waiting || !state.signedIn) && (
     <CircularProgress sx={{ margin: "auto", mt: 2 }} color="primary" />
    )}
    {filteredTours.map((tour, _i) => (
     <TourCard key={_i} tour={tour} goToTour={goToTour} handleDeleteTour={handleDeleteTour} />
    ))}
   </PageContent>
   <FooterButtons>
    <Button size="large" variant="contained" color="primary" onClick={handleNewTour}>
     Add Tour
    </Button>
   </FooterButtons>
  </PageContainer>
 );
};

export default Tours;
