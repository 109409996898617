import * as React from "react";

import { Typography, Box, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from "@mui/material";

/**
 * Preview table for bulk-upload
 */
const PreviewTable = ({ headers, rows, upload }) => {
 return (
  <Box
   className="scrollbar-hidden"
   sx={{
    maxWidth: "100%",
    maxHeight: "100%",
    overflowY: "scroll",
    p: 1,
   }}
  >
   <Typography fontSize="large" sx={{ textAlign: "center" }}>
    Preview of the first 50 records
   </Typography>
   <Button sx={{ width: "100%" }} onClick={upload} variant="contained">
    Accept and Upload
   </Button>
   <TableContainer component={Paper}>
    <Table aria-label="simple table">
     <TableHead>
      <TableRow>
       {headers.map((header, _i) => (
        <TableCell key={_i} color="primary">
         <Typography fontWeight={header.required ? "bold" : "normal"}>{header}</Typography>
        </TableCell>
       ))}
      </TableRow>
     </TableHead>
     <TableBody>
      {rows.map((col, _i) => (
       <TableRow key={_i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        {headers.map((head, _u) => (
         <TableCell key={`${_i}${_u}`}>
          {typeof col[head].old !== "undefined" && col[head].old !== col[head].new ? (
           <Box>
            {typeof col[head].new !== "undefined" && (
             <Typography noWrap={true} mx={{ color: "green" }}>
              {`${JSON.stringify(col[head].new).slice(0, 80)}${JSON.stringify(col[head].new).length > 80 ? "..." : ""}`}
             </Typography>
            )}
            {typeof col[head].old !== "undefined" && (
             <Typography noWrap={true} color="error">
              {`${JSON.stringify(col[head].old).slice(0, 80)}${JSON.stringify(col[head].old).length > 80 ? "..." : ""}`}
             </Typography>
            )}
           </Box>
          ) : (
           <Box>
            {typeof col[head].new !== "undefined" && <Typography noWrap={true}>{`${JSON.stringify(col[head].new).slice(0, 80)}${JSON.stringify(col[head].new).length > 80 ? "..." : ""}`}</Typography>}
           </Box>
          )}
         </TableCell>
        ))}
       </TableRow>
      ))}
     </TableBody>
    </Table>
   </TableContainer>
  </Box>
 );
};

export default PreviewTable;
