import ReactMapboxGl from "react-mapbox-gl";

import "./mapbox.css";

/**
 * Configured ReactMapboxGL instance
 */
const Mapbox = ReactMapboxGl({
 accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
 logoPosition: "top-left",
 doubleClickZoom: false,
 dragRotate: false,
 pitchWithRotate: false,
 // attributionControl: false,
});

export default Mapbox;
