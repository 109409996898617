import Controller from "../Controller.js";
import Request from "../utility/Request.js";
import jwtDecode from "jwt-decode";

/**
 * Extends the Request class, specifying the "post" method,
 * accepting two arguments (username, password) in the sendRequest function,
 * updating the url to authenticate,
 * overriding resolve request to update the Controller's idToken value,
 * and providing a body object containing the email (username) and password
 */
class Authenticate extends Request {
 constructor() {
  super("post", `authenticate`);
 }
 sendRequest(username, password) {
  return this.sendRequestPromise({ email: username, password });
 }
 resolveRequest(result, resolve) {
  Controller.idToken = result.data.IdToken;
  if (Controller.idToken) {
   const decoded = jwtDecode(Controller.idToken);
   const groups = decoded["cognito:groups"];
   if (groups) {
    Controller.groups = groups;
   }
  } else {
   Controller.groups = [];
  }
  resolve(result);
 }
}

export default Authenticate;
