import Controller from "../Controller.js";
import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "get" method,
 * updating the url to logout,
 * overriding resolve request to update the Controller's idToken and groups values
 */
class Logout extends Request {
 constructor() {
  super("get", `logout`);
 }
 resolveRequest(result, resolve) {
  Controller.idToken = null;
  Controller.groups = [];
  resolve(result);
 }
}

export default Logout;
