import { useEffect, useContext, useRef } from "react";

import { Context } from "../../SDK/context";

/**
 * Utility component to trigger a token refresh when mounted
 */
const AutoSignin = () => {
 const context = useRef(useContext(Context));
 useEffect(() => {
  context.current.refresh();
 }, []);

 return null;
};

export default AutoSignin;
