import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "delete" method,
 * accepting three arguments (cemetery, type, id) in the sendRequest function,
 * updating the url to record
 */
class DeleteRecord extends Request {
 constructor() {
  super("delete", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const cemetery = args[0];
  const type = args[1];
  const id = args[2];

  this.url = `record/${cemetery}/${type}/${id}`;
  return this.sendRequestPromise();
 }
}

export default DeleteRecord;
