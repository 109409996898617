import * as React from "react";
import { Button, TextField, Paper, Typography, FormControl, FormGroup, FormControlLabel, Switch, FormLabel, Divider, Box } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import AutoLoadSite from "../components/AutoLoadSite";
import { PageHeader, FooterButtons, PageContainer, PageContent, FormHeader } from "../components/styled-components";

import { Context } from "../SDK/context";
import ImageUpload from "../components/ImageUpload";
import GalleryUpload from "../components/GalleryUpload";

import OverviewDetails from "../components/Overview/OverviewDetails";
import MapEditor from "../components/Overview/MapEditor";
import FOV from "../SDK/src/main";

import SaveIcon from "@mui/icons-material/Save";

import { handleTextChange, handleNumberChange, handleFloatChange, handleSwitchChange, handleMedia, getNewItem } from "../utilities";

import { getDefaultForm } from "../assets/default-forms";

const defaultForm = getDefaultForm("cemetery");

/**
 * Site overview page containing organization form
 */
const Overview = () => {
 const submitButton = React.useRef();
 const { state, setLoading, setCemeteryItems } = React.useContext(Context);
 const [submitting, setSubmitting] = React.useState(false);
 const [formState, setFormState] = React.useState(defaultForm);
 const [newMedia, setNewMedia] = React.useState({});
 const [media, setMedia] = React.useState([]);

 // On selected site change, combines formState with site item corresponding to selected site.
 React.useEffect(() => {
  setNewMedia({});
  for (let item of state.cemeteryItems) {
   if (item.SK == state.selectedSite) {
    setFormState({ ...formState, ...item });
    break;
   }
  }
 }, [state.selectedSite]);

 // Attempts to update the cemetery with the provided cemetery item
 const updateCemetery = (newCemeteryItem) => {
  return FOV.api.updateCemetery(state.selectedSite, newCemeteryItem);
 };

 /**
  * Generates a media mapping using the handleMedia utility function with the newMedia state and formState.
  * Parses map coordinate input fields from form state to generate new, processed form state.
  * Generates a new cemetery item from the mapping, new form state, and media state.
  * Attempts to update the cemetery via updateCemetery.
  * Finally, updates cemetery items state and resets formState, newMedia, and media states using the new
  * cemetery item.
  * Sets loading to true for the duration of the update.
  * @param {*} event form submit event
  */
 const handleSubmit = async (event) => {
  event.preventDefault();
  setLoading(true);
  try {
   const mapping = await handleMedia(newMedia, formState, state.selectedSite, "cemetery", state.selectedSite);
   const newFormState = { ...formState };

   for (let field of ["MapALat", "MapALng", "MapBLat", "MapBLng", "MapCLat", "MapCLng", "MapDLat", "MapDLng"]) {
    const value = parseFloat(newFormState[field], 10);
    if (isNaN(value)) {
     newFormState[field] = 0;
    } else {
     newFormState[field] = value;
    }
   }

   const newCemetery = getNewItem(mapping, newFormState, media);

   await updateCemetery(newCemetery);
   setFormState(newCemetery);
   setCemeteryItems(state.cemeteryItems.map((item) => (item.SK === newCemetery.SK ? newCemetery : item)));
   setNewMedia({});
   setMedia(newCemetery.Media.split(";"));
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
  setLoading(false);
 };

 return (
  <PageContainer className="scrollbar-hidden">
   <AutoLoadSite />
   <PageHeader>Overview</PageHeader>
   <PageContent>
    <form
     id="myForm"
     onSubmit={handleSubmit}
     style={{
      height: "100%",
      display: "block",
      marginTop: "30px",
      marginBottom: "30px",
     }}
    >
     <Paper
      elevation={3}
      sx={{
       display: "flex",
       maxWidth: "840px",
       margin: "auto",
       background: (theme) => theme.palette.form.main,
      }}
     >
      <FormControl sx={{ flexGrow: 1 }}>
       <FormGroup>
        <FormControlLabel
         sx={{ margin: "auto" }}
         control={<Switch checked={formState.Active} name="Active" onChange={(e) => handleSwitchChange(e, setFormState, formState)} />}
         label="Display in mobile app"
        />
        <Divider />
        <FormHeader>Site Photo</FormHeader>

        <ImageUpload fieldName="Photo" formState={formState} setFormState={setFormState} newMedia={newMedia} setNewMedia={setNewMedia} />
        <Divider />
        <FormHeader>Site Details</FormHeader>
        <OverviewDetails setFormState={setFormState} formState={formState} />
        <Divider />
        <FormHeader>Site Map</FormHeader>
        <MapEditor formState={formState} setFormState={setFormState} newMedia={newMedia} setNewMedia={setNewMedia} />
        <FormHeader>Site Logo</FormHeader>
        <Divider />
        <ImageUpload fieldName="Logo" formState={formState} setFormState={setFormState} newMedia={newMedia} setNewMedia={setNewMedia} />
        <Divider />
        <FormHeader>Site Gallery</FormHeader>
        <Paper
         square
         sx={{
          m: "10px",
          padding: "10px",
          maxWidth: "calc(100% - 20px)",
          background: (theme) => theme.palette.tertiary.main,
         }}
        >
         <GalleryUpload formState={formState} newMedia={newMedia} setNewMedia={setNewMedia} media={media} setMedia={setMedia} />
        </Paper>
        <LoadingButton ref={submitButton} loading={submitting} variant="contained" sx={{ background: (theme) => theme.palette.primary.main, display: "none" }} type="submit">
         <Typography fontSize="large" sx={{ display: "flex", alignItems: "center" }}>
          Save
          <SaveIcon sx={{ m: 1 }} />
         </Typography>
        </LoadingButton>
       </FormGroup>
      </FormControl>
     </Paper>
    </form>
   </PageContent>
   <FooterButtons sx={{}}>
    <Button
     size="large"
     variant="contained"
     color="primary"
     onClick={() => {
      submitButton.current.click();
     }}
    >
     Save <SaveIcon sx={{ ml: 1 }} />
    </Button>
   </FooterButtons>
  </PageContainer>
 );
};

export default Overview;
