import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "get" method,
 * accepting a cemetery argument in the sendRequest function,
 * updating the url to cemetery
 */
class GetCemetery extends Request {
 constructor() {
  super("get", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const cemetery = args[0];
  this.url = `cemetery/${cemetery}`;
  return this.sendRequestPromise({});
 }
}

export default GetCemetery;
