import * as React from "react";
import { useNavigate } from "react-router-dom";
import RegisterForm from "../components/Admin/RegisterForm";
import { PageHeader, PageContainer, PageContent } from "../components/styled-components";
import Controller from "../SDK/src/Controller";

/**
 * Admin page containing a register form component if the user is in the staff group
 */
const Admin = () => {
 const navigate = useNavigate();
 React.useEffect(() => {
  if (!Controller.groups) {
   navigate("/Home");
  }
 }, []);
 return (
  <PageContainer className="scrollbar-hidden">
   <PageHeader>Admin</PageHeader>
   <PageContent>{Controller.groups[0] === "staff" && <RegisterForm />}</PageContent>
  </PageContainer>
 );
};

export default Admin;
