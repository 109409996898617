import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "get" method,
 * accepting four arguments (cemetery, type, id, mediaType) in the sendRequest function,
 * updating the url to media-url
 */
class GetMediaUrl extends Request {
 constructor() {
  super("get", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const cemetery = args[0];
  const type = args[1];
  const id = args[2];
  const mediaType = args[3];

  this.url = `media-url/${cemetery}/${type}/${id}/${mediaType}`;

  return this.sendRequestPromise({});
 }
 resolveRequest(result, resolve) {
  result.data = { url: result.data.url };
  resolve(result, resolve);
 }
}

export default GetMediaUrl;
