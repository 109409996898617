import React from "react";

import { Card, CardActionArea, CardContent, Box, Typography, IconButton, Avatar } from "@mui/material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOffIcon from "@mui/icons-material/EditOff";
import placeholderSite from "../../assets/PlaceholderSite.png";

/**
 * Organization display card
 */
const OrganizationCard = ({ organization, goToOrganization, deleteOrganization, assignable, assignOrganization, unassignOrganization, owned }) => {
 return (
  <Card
   square
   sx={{
    mt: 1,
    mb: 1,
    width: "100%",
    transition: "0.25s",
    ":hover": {
     boxShadow: 10,
    },
   }}
  >
   <CardActionArea
    onClick={() => {
     if (owned) {
      goToOrganization(organization);
     }
    }}
   >
    <CardContent sx={{ display: "flex" }}>
     <Avatar
      size="large"
      variant="rounded"
      sx={{ mr: 2, width: 56, height: 56 }}
      src={
       organization.Logo ||
       // "https://www.thesprucepets.com/thmb/hThcFCxT20ql0opGe4B8WGICbc4=/1851x1851/smart/filters:no_upscale()/cat-talk-eyes-553942-hero-df606397b6ff47b19f3ab98589c3e2ce.jpg"
       placeholderSite
      }
     />
     <Box
      sx={{
       display: "flex",
       flexDirection: "row",
       justifyContent: "space-between",
       alignItems: "center",
       flexGrow: 1,
      }}
     >
      <Typography variant="h5">{organization.Name}</Typography>
      <Box sx={{ ml: "auto" }}>
       {owned ? (
        assignable ? (
         <IconButton
          onClick={(e) => deleteOrganization(e, organization)}
          sx={{
           transition: "0.25s",
           ":hover": { color: (theme) => theme.palette.error.main },
          }}
         >
          <DeleteIcon />
         </IconButton>
        ) : null
       ) : (
        <IconButton disabled>
         <EditOffIcon />
        </IconButton>
       )}
       {assignable && (
        <IconButton onClick={(e) => assignOrganization(e, organization)}>
         <AddBoxIcon />
        </IconButton>
       )}
       {!assignable && (
        <IconButton onClick={(e) => unassignOrganization(e, organization)}>
         <IndeterminateCheckBoxIcon />
        </IconButton>
       )}
      </Box>
     </Box>
    </CardContent>
   </CardActionArea>
  </Card>
 );
};

export default OrganizationCard;
