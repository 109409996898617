import React from "react";

import { Button, Paper, Card, CardActionArea, CardMedia, CardContent, Box, Typography, CardActions } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import placeholderSite from "../../assets/PlaceholderSite.png";

import { Context } from "../../SDK/context";

/**
 * Tour display card
 */
const TourCard = ({ tour, goToTour, handleDeleteTour }) => {
 const { state, setSelectedSite } = React.useContext(Context);
 return (
  <Card
   square
   sx={{
    flexShrink: 0,
    margin: "30px auto",
    width: "100%",
    maxWidth: "780px",
    transition: "0.25s",
    ":hover": {
     boxShadow: 15, // theme.shadows[20]
     //   background: (theme) => theme.palette.tertiary.main,
    },
   }}
  >
   <CardActionArea
    onClick={() => {
     goToTour(tour);
    }}
   >
    <CardMedia
     component="img"
     height="120"
     image={
      tour.Logo ||
      // "https://www.thesprucepets.com/thmb/hThcFCxT20ql0opGe4B8WGICbc4=/1851x1851/smart/filters:no_upscale()/cat-talk-eyes-553942-hero-df606397b6ff47b19f3ab98589c3e2ce.jpg"
      placeholderSite
     }
     alt="site logo"
    />
    <CardContent>
     <Box
      sx={{
       display: "flex",
       flexDirection: "row",
       justifyContent: "space-between",
       alignItems: "center",
      }}
     >
      <Typography variant="h5" component="div">
       {tour.Name}
      </Typography>
      <Box
       sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        transition: "0.25s",
        ":hover": {
         color: (theme) => theme.palette.error.main,
        },
       }}
       color="error"
       onClick={(e) => {
        e.stopPropagation();
        handleDeleteTour(tour);
       }}
      >
       <DeleteIcon />
      </Box>
     </Box>
     <Typography variant="body2" color="text.secondary">
      {tour.Description && `${(tour.Description || "").slice(0, 130)}${(tour.Description || "").length > 130 ? "..." : ""}`}
     </Typography>
    </CardContent>
   </CardActionArea>
  </Card>
 );
};

export default TourCard;
