import * as React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Divider, List, ListItem, ListItemIcon, ListItemText, TextField, Paper, Button, Typography, Menu, MenuItem, IconButton, Box } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ViewListIcon from "@mui/icons-material/ViewList";
import EditIcon from "@mui/icons-material/Edit";
import TimelineIcon from "@mui/icons-material/Timeline";
import TourIcon from "@mui/icons-material/Tour";
import HomeIcon from "@mui/icons-material/Home";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ArticleIcon from "@mui/icons-material/Article";
import SearchIcon from "@mui/icons-material/Search";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import AddCircle from "@mui/icons-material/AddCircle";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import Dropdown from "../components/Dropdown";
import Fuse from "fuse.js";
import { Context } from "../SDK/context";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SettingsDialog from "./Settings/SettingsDialog";
import Controller from "../SDK/src/Controller";

/**
 * Sidebar nav panel component
 * Dislpays Admin page, Home page, Site-specific pages, Site list, and settings button.
 */
const Sidebar = () => {
 const navigate = useNavigate();
 const { state, setFilteredCemeteryItems, setSitesQuery, setSelectedSite, logout, setLoading } = React.useContext(Context);
 // Settings dialog open state
 const [open, setOpen] = React.useState(false);

 /**
  * Resets filtered cemetery items to the cemetery list in state when the list changes
  */
 React.useEffect(() => {
  setFilteredCemeteryItems(state.cemeteryItems);
 }, [state.cemeteryItems]);

 /**
  * Resets filtered cemetery items to the cemetery list in state if empty input.
  * Otherwise, initiates fuse fuzzy search and updates cemetery list with result.
  * @param {*} event input onChange event
  */
 function fuzzySearch(event) {
  setSitesQuery(event.target.value);
  if (!event.target.value) {
   setFilteredCemeteryItems(state.cemeteryItems);
   return;
  }
  const options = {
   keys: ["Name"],
  };
  const fuse = new Fuse(state.cemeteryItems, options);
  setFilteredCemeteryItems([...fuse.search(event.target.value).map((a) => a.item)]);
 }

 /**
  * Attempts to logout user.
  * Updates loading state.
  */
 const logoutUser = async () => {
  setLoading(true);
  try {
   await logout();
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
 };

 /**
  * Updates selected site and navigates to that site page
  */
 const goTo = (sk, section) => {
  {
   setSelectedSite(sk);
   navigate(`/${section}/${sk}`);
  }
 };

 /**
  * Navigates to Site Manager page
  */
 const goHome = () => {
  navigate(`/SiteManager`);
 };

 /**
  * Navigates to Admin page
  */
 const goToAdmin = () => {
  navigate(`/Admin`);
 };

 /**
  * Sets options dialog open
  */
 const handleClickOpen = () => {
  setOpen(true);
 };

 /**
  * Sets options dialog closed
  */
 const handleClose = (value) => {
  setOpen(false);
 };

 return (
  <div
   style={{
    height: "100%",
    maxHeight: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
   }}
  >
   {Controller.groups?.length > 0 && (
    <List>
     <ListItem component={Button} onClick={goToAdmin}>
      <ListItemIcon>
       <AdminPanelSettingsIcon />
      </ListItemIcon>
      <ListItemText sx={{ pl: 3 }} primary={Controller.groups[0]} />
     </ListItem>
    </List>
   )}

   <List>
    <ListItem component={Button} onClick={goHome}>
     <ListItemIcon>
      <HomeIcon />
     </ListItemIcon>
     <ListItemText sx={{ pl: 3 }} primary="Home" />
    </ListItem>
   </List>
   {state.selectedSite && (
    <>
     <Divider />
     <Paper elevation={2} sx={{ m: 1, background: (theme) => theme.palette.grey.main }}>
      <Paper
       sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderColor: (theme) => theme.palette.tertiary.main,
        background: (theme) => theme.palette.primary.main,
       }}
      >
       <Typography
        fontSize="18px"
        sx={{
         textAlign: "center",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         textTransform: "uppercase",
         color: (theme) => "white",
         minHeight: "40px",
        }}
       >
        {state.cemeteryItems.filter((a) => a.SK === state.selectedSite)[0].Name}
       </Typography>
      </Paper>
      <Divider />
      <List>
       <ListItem
        component={Button}
        onClick={() => {
         goTo(state.selectedSite, "Overview");
        }}
       >
        <ListItemIcon>
         <EditIcon />
        </ListItemIcon>
        <ListItemText primary="Overview" />
       </ListItem>

       <Dropdown icon={<ArticleIcon />} title="Records">
        <ListItem
         component={Button}
         onClick={() => {
          goTo(state.selectedSite, "Search");
         }}
        >
         <ListItemIcon>
          <SearchIcon />
         </ListItemIcon>
         <ListItemText primary="Search" />
        </ListItem>
        <Dropdown sx={{ ml: 1 }} icon={<AddCircle />} title="Add">
         <ListItem
          component={Button}
          onClick={() => {
           goTo(state.selectedSite, "Burial");
          }}
         >
          <ListItemIcon>
           <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Record" />
         </ListItem>
         <ListItem
          component={Button}
          onClick={() => {
           goTo(state.selectedSite, "Monument");
          }}
         >
          <ListItemIcon>
           <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Monument" />
         </ListItem>
         <ListItem
          component={Button}
          onClick={() => {
           goTo(state.selectedSite, "POI");
          }}
         >
          <ListItemIcon>
           <GpsFixedIcon />
          </ListItemIcon>
          <ListItemText primary="POI" />
         </ListItem>
        </Dropdown>
        <Dropdown sx={{ ml: 1 }} icon={<FolderZipIcon />} title="Upload">
         <ListItem
          component={Button}
          onClick={() => {
           goTo(state.selectedSite, "UploadBurials");
          }}
         >
          <ListItemIcon>
           <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Burials" />
         </ListItem>
         <ListItem
          component={Button}
          onClick={() => {
           goTo(state.selectedSite, "UploadMonuments");
          }}
         >
          <ListItemIcon>
           <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Monuments" />
         </ListItem>
        </Dropdown>
       </Dropdown>
       <ListItem
        component={Button}
        onClick={() => {
         goTo(state.selectedSite, "Pathing");
        }}
       >
        <ListItemIcon>
         <TimelineIcon />
        </ListItemIcon>
        <ListItemText primary="Pathing" />
       </ListItem>
       <ListItem
        component={Button}
        onClick={() => {
         goTo(state.selectedSite, "Tours");
        }}
       >
        <ListItemIcon>
         <TourIcon />
        </ListItemIcon>
        <ListItemText primary="Tours" />
       </ListItem>
       <ListItem
        component={Button}
        onClick={() => {
         goTo(state.selectedSite, "Organizations");
        }}
       >
        <ListItemIcon>
         <ViewListIcon />
        </ListItemIcon>
        <ListItemText primary="Organizations" />
       </ListItem>
      </List>
     </Paper>
    </>
   )}
   <Divider />
   <Paper
    elevation={2}
    sx={{
     margin: 1,
     marginTop: 1,
     pt: 1,
     background: (theme) => theme.palette.grey.main,
    }}
   >
    <Paper
     sx={{
      marginRight: 1,
      marginLeft: 1,
      display: "flex",
     }}
    >
     <TextField autoComplete="off" varient="standard" id="outlined-required" label="Filter Sites" value={state.sitesQuery} onChange={fuzzySearch} />
    </Paper>
    <Dropdown>
     {state.filteredCemeteryItems.map((item) => (
      <ListItem
       key={item.SK}
       component={Button}
       onClick={() => {
        goTo(item.SK, "Overview");
       }}
      >
       <ListItemText primary={item.Name || ""} />
       <ChevronRightIcon fontSize="small" />
      </ListItem>
     ))}
    </Dropdown>
   </Paper>
   {/* Spacer for settings button */}
   <Box
    sx={{
     height: "50px",
     minHeight: "50px",
     width: "100%",
    }}
   ></Box>
   <Button
    variant="contained"
    size="large"
    onClick={handleClickOpen}
    sx={{
     height: "50px",
     minHeight: "50px",
     position: "sticky",
     width: "100%",
     mt: "auto",
     bottom: 0,
     display: "flex",
     borderRadius: 0,
     display: "flex",
     justifyContent: "space-between",
     alignItems: "center",
     fontSize: "large",
    }}
   >
    Options
    <SettingsApplicationsIcon sx={{ height: "100%", width: "auto" }} />
   </Button>
   <SettingsDialog open={open} onClose={handleClose} logoutUser={logoutUser} />
  </div>
 );
};

export default Sidebar;
