/**
 * Example tables used for the bulk-upload guide
 */
const headers = {
 burial: [
  { name: "Id", required: true },
  { name: "Forename", required: true },
  { name: "Surname", required: true },
  { name: "Lat", required: true },
  { name: "Lng", required: true },
  { name: "MiddleNames" },
  { name: "Notable" },
  { name: "BurialTypeVeteran" },
  { name: "Aliases" },
  { name: "Initials" },
  { name: "EducationalHonours" },
  { name: "DoB" },
  { name: "DoD" },
  { name: "DoD2" },
  { name: "AgeAtDeath" },
  { name: "Country" },
  { name: "CountryCommemorations" },
  { name: "MonumentCommemorations" },
  { name: "CountryOfService" },
  { name: "ServiceNumber" },
  { name: "Nationality" },
  { name: "Rank" },
  { name: "ForceServiceBranch" },
  { name: "Regiment" },
  { name: "SecondaryRegiment" },
  { name: "Unit" },
  { name: "HonoursAwards" },
  { name: "Trade" },
  { name: "Description" },
  { name: "FamilyInfo" },
  { name: "Conflicts" },
  { name: "GraveRefLot" },
  { name: "GraveRefBlock" },
  { name: "GraveRefSection" },
  { name: "PlotOrMonument" },
  { name: "Photo" },
  { name: "Media" },
  { name: "Weblinks" },
  { name: "Status" },
  { name: "DoI" },
  { name: "EstateDetails" },
  { name: "Active" },
  { name: "Aux" },
 ],
 monument: [
  { name: "Id", required: true },
  { name: "Name", required: true },
  { name: "Lat", required: true },
  { name: "Lng", required: true },
  { name: "DedicatedBy" },
  { name: "DedicatedDate" },
  { name: "Conflicts" },
  { name: "Casualties" },
  { name: "Country" },
  { name: "Locality" },
  { name: "MonumentRefLocation" },
  { name: "StateProvince" },
  { name: "Accessibility" },
  { name: "Description" },
  { name: "Owner" },
  { name: "Artist" },
  { name: "Architect" },
  { name: "Labels" },
  { name: "Photo" },
  { name: "Media" },
  { name: "Weblinks" },
  { name: "Active" },
  { name: "Aux" },
 ],
};
const columns = {
 burial: [
  {
   description: "Unique string containing only letters, numbers, and dashes",
   example: "123",
  },
  { description: "", example: "John" },
  { description: "", example: "Smith" },
  { description: "Latitude", example: "36.10692695385191" },
  { description: "Longitude", example: "-112.11294947356791" },
  { description: "", example: "James" },
  { description: "true/false", example: "true" },
  { description: "true/false", example: "true" },
  { description: "", example: "Bravo" },
  { description: "", example: "J.S." },
  { description: "", example: "None" },
  { description: "", example: "04/06/1901" },
  { description: "", example: "12/01/1945" },
  { description: "", example: "16/01/1945" },
  { description: "", example: "44" },
  { description: "", example: "England" },
  { description: "", example: "None" },
  { description: "", example: "None" },
  { description: "", example: "England" },
  { description: "", example: "12345" },
  { description: "", example: "British" },
  { description: "", example: "CSM" },
  { description: "", example: "Cavalry" },
  { description: "", example: "Light Cavalry" },
  { description: "", example: "Armoured Reconnaissance" },
  { description: "", example: "COPRS" },
  { description: "", example: "The George Cross" },
  { description: "", example: "HR" },
  { description: "", example: "A true hero." },
  { description: "", example: "None" },
  { description: "", example: "None" },
  { description: "", example: "Lot  A" },
  { description: "", example: "Block C" },
  { description: "", example: "Section 2" },
  { description: "Plot / Monument", example: "Plot" },
  { description: "Local image path", example: "images/headstone.png" },
  {
   description: "local image paths (colon separated)",
   example: "images/profile.jpg;images/portrait.png",
  },
  {
   description: "Full URLs (colon separated)",
   example: "https://a.com;https://b.com",
  },
  { description: "", example: "Occupied" },
  { description: "", example: "02/01/1925" },
  { description: "", example: "None" },
  { description: "true/false", example: "false" },
  { description: "", example: "Extra info" },
 ],
 monument: [
  {
   description: "Unique string containing only letters, numbers, and dashes",
   example: "123",
  },
  { description: "", example: "Example Monument" },
  { description: "Latitude", example: "36.10692695385191" },
  { description: "Longitude", example: "-112.11294947356791" },
  { description: "", example: "Edward Smith" },
  { description: "", example: "26/12/1930" },
  { description: "", example: "None" },
  { description: "Positive Whole Number", example: "54" },
  { description: "", example: "USA" },
  { description: "", example: "Austin" },
  { description: "", example: "Entrance to Site" },
  { description: "", example: "Texas" },
  { description: "", example: "Ramps" },
  { description: "", example: "An example monument" },
  { description: "", example: "Sam Davids" },
  { description: "", example: "Peter Doe" },
  { description: "", example: "Peter Doe" },
  { description: "Local image path", example: "images/headstone.png" },
  { description: "words (colon separated)", example: "label_a;label_b" },
  {
   description: "local image paths (colon separated)",
   example: "images/profile.jpg;images/portrait.png",
  },
  {
   description: "Full URLs (colon separated)",
   example: "https://a.com;https://b.com",
  },
  { description: "true/false", example: "false" },
  { description: "", example: "Extra info" },
 ],
};

export { headers, columns };
