import * as React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { Context } from "../../SDK/context";

import FOV from "../../SDK/src/main";

/**
 * Confirmation dialog component for deleting an organization
 */
const DeleteTourDialog = ({ open, setOpen, tourToDelete, setTourToDelete, results, setResults }) => {
 const { state } = React.useContext(Context);
 const [submitting, setSubmitting] = React.useState(false);

 const handleClose = () => {
  setSubmitting(false);
  setTourToDelete(false);
  setOpen(false);
 };

 /**
  * Attempts to delete a tour given the submitted form values.
  * Updates tour list
  * @param {*} event form submit event
  */
 const handleSubmit = async (event) => {
  event.preventDefault();
  setSubmitting(true);
  const skToDelete = tourToDelete.SK;
  try {
   await FOV.api.deleteRecord(state.selectedSite, "tour", tourToDelete.SK.split("_")[1]);
   setResults(results.filter((result) => result.SK !== skToDelete));
   handleClose();
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
 };

 return (
  <Dialog open={open}>
   <DialogTitle>Delete tour</DialogTitle>
   <form onSubmit={handleSubmit}>
    <DialogContent>
     <DialogContentText sx={{ mb: 2 }}>
      Are you sure you want to delete <b>"{tourToDelete.Name}"</b> ?
     </DialogContentText>
     <DialogContentText>
      This will permanently remove:
      <li>All files related to the tour</li>
      <li>The database entry for the tour</li>
     </DialogContentText>
    </DialogContent>
    <DialogActions>
     <Button disabled={submitting} onClick={handleClose}>
      Cancel
     </Button>
     <LoadingButton color="error" loading={submitting} type="submit">
      Yes, I'm sure
     </LoadingButton>
    </DialogActions>
   </form>
  </Dialog>
 );
};

export default DeleteTourDialog;
