import * as React from "react";
import { Navigate, Outlet, Routes, Route } from "react-router-dom";
import { Context } from "./SDK/context";
import SignIn from "./views/SignIn";
import SiteList from "./views/SiteList";
import Overview from "./views/Overview";
import Pathing from "./views/Pathing";
import Search from "./views/Search";
import Tours from "./views/Tours";
import RecordForm from "./views/RecordForm";
import Upload from "./views/Upload";
import Tour from "./views/Tour";
import Organizations from "./views/Organizations";
import Organization from "./views/Organization";
import Admin from "./views/Admin";

/**
 * Utility component, returns the private route component if the user is signedIn or waiting,
 * otherwise renders a navigate component to the login page.
 */
function PrivateOutlet() {
 const { state } = React.useContext(Context);

 return (state.signedIn && state.email) || state.waiting ? <Outlet /> : <Navigate to="/" />;
}

/**
 * Router component, mapping all pre-defined and dynamic routes to page components.
 */
const routes = (
 <Routes>
  <Route path="/" exact element={<SignIn />}></Route>
  <Route path="/" element={<PrivateOutlet />}>
   <Route path="/SiteManager" element={<SiteList />} />
   <Route path="/Admin" element={<Admin />} />
   <Route path="/Overview/:cemetery" element={<Overview />} />
   <Route path="/Search/:cemetery" element={<Search />} />
   <Route path="/Burial/:cemetery/:Burial" element={<RecordForm key="burial-existing" type="burial" paramName="Burial" />} />
   <Route path="/Burial/:cemetery/" element={<RecordForm key="burial" type="burial" paramName="Burial" />} />
   <Route path="/Monument/:cemetery/:Monument" element={<RecordForm key="monument-existing" type="monument" paramName="Monument" />} />
   <Route path="/Monument/:cemetery/" element={<RecordForm key="monument" type="monument" paramName="Monument" />} />
   <Route path="/POI/:cemetery/:POI" element={<RecordForm key="poi-existing" type="poi" paramName="POI" />} />
   <Route path="/POI/:cemetery/" element={<RecordForm key="poi" type="poi" paramName="POI" />} />
   <Route path="/UploadBurials/:cemetery" element={<Upload key="record" type="burial" />} />
   <Route path="/UploadMonuments/:cemetery" element={<Upload key="monument" type="monument" />} />
   <Route path="/Pathing/:cemetery" element={<Pathing />} />
   <Route path="/Tours/:cemetery" element={<Tours />} />
   <Route path="/Tour/:cemetery/:Tour" element={<Tour />} />
   <Route path="/Organizations/:cemetery" element={<Organizations />} />
   <Route path="/Organization/:Organization" element={<Organization />} />
   <Route path="/*" element={<SiteList />} />
  </Route>
 </Routes>
);

export default routes;
