import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "post" method,
 * accepting three arguments (email, confirmationCode, password) in the sendRequest function,
 * updating the url to reset-password,
 * and providing a body object containing the email, confirmationCode, and password
 */
class ResetPassword extends Request {
 constructor() {
  super("post", `reset-password`);
 }
 sendRequest(email, confirmationCode, password) {
  return this.sendRequestPromise({ email, confirmationCode, password });
 }
}

export default ResetPassword;
