import React from "react";
import { Button, Paper, ButtonGroup } from "@mui/material";

/**
 * Component for adding and removing photos from the media field of a form.
 */
const GalleryUpload = ({ formState, newMedia, setNewMedia, media, setMedia }) => {
 // Selected item state used for deleting specific photos
 const [selectedItem, setSelectedItem] = React.useState(null);
 // Initialises media array in state when the form record changes
 React.useEffect(() => {
  if (formState.Media) {
   setMedia(formState.Media.split(";"));
  } else {
   setMedia([]);
  }
  setSelectedItem(null);
 }, [formState.SK]);

 /**
  * When a photo file is uploaded (to the browser), an object url is created and used as a temporary source
  * (used in place of image url) as well as being added to the media array.
  * The url is also mapped to the file in the newMedia object.
  */
 const onUpload = (event) => {
  const files = event.target.files;

  let mediaToAdd = [];
  let newMediaToAdd = {};

  for (let file of files) {
   if (file.type.split("/")[0] !== "image") {
    console.error("file is not an image");
   } else {
    const tempSrc = URL.createObjectURL(file);
    newMediaToAdd[tempSrc] = file;
    mediaToAdd.push(tempSrc);
   }
  }
  setNewMedia({
   ...newMedia,
   Media: { ...(newMedia.Media || {}), ...newMediaToAdd },
  });
  setMedia([...media, ...mediaToAdd]);
 };

 // Selects/deselects photo source
 const selectItem = (src) => {
  if (selectedItem === src) {
   setSelectedItem(null);
  } else {
   setSelectedItem(src);
  }
 };

 // Removes photo source from media array and newMedia object.
 // Deselects all photos
 const onDeleteItem = () => {
  setMedia(media.filter((a) => a !== selectedItem));
  setNewMedia({
   ...newMedia,
   Media: { ...newMedia.Media, [selectedItem]: undefined },
  });
  setSelectedItem(null);
 };

 return (
  <div
   style={{
    padding: `10px`,
    maxWidth: `100%`,
   }}
  >
   <Paper
    elevation={3}
    sx={{
     p: 0.5,
     background: (theme) => theme.palette.grey.main,
     height: "200px",
     display: "flex",
     justifyContent: "start",
     alignItems: "center",
     maxWidth: "100%",
     overflowX: "scroll",
     overflowY: "hidden",
    }}
   >
    {[...media].map((image, index) => (
     <img
      key={index}
      src={image}
      style={{
       height: "90%",
       maxHeight: "100%",
       objectFit: "contain",
       boxShadow: selectedItem == image ? `0px 0px 7px rgba(0,0,0,0.5)` : "",
       margin: "10px",
      }}
      onClick={() => selectItem(image)}
     />
    ))}
   </Paper>
   <ButtonGroup sx={{ width: "100%" }}>
    <Button variant="contained" component="label" sx={{ width: "100%" }}>
     Add Photos
     <input type="file" multiple hidden onChange={onUpload} accept=".jpg, .jpeg, .png, image/jpeg, image/png" />
    </Button>
    <Button disabled={!selectedItem} color="error" variant="contained" component="label" sx={{ width: "100%" }} onClick={onDeleteItem}>
     Delete
    </Button>
   </ButtonGroup>
   <p style={{ fontSize: "medium", textAlign: "center" }}>Please note supported filetypes are: jpg, png</p>
  </div>
 );
};

export default GalleryUpload;
