import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "post" method,
 * accepting eight arguments (cemetery, type, query_by, filter_by, sort_by, input, page, per_page) in the sendRequest function,
 * updating the url to search,
 * and providing a body object containing all the provided arguments
 */
class Search extends Request {
 constructor() {
  super("post", `search`);
 }
 sendRequest(cemetery, type, query_by, filter_by, sort_by, input, page, per_page) {
  return this.sendRequestPromise({
   cemetery,
   type,
   query_by,
   filter_by,
   sort_by,
   input,
   page,
   per_page,
  });
 }
}

export default Search;
