import * as React from "react";

import { Button } from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import { PageHeader, FooterButtons, PageContainer, PageContent } from "../components/styled-components";

import { Context } from "../SDK/context";
import SiteCard from "../components/SiteList/SiteCard";
import NewSiteDialog from "../components/SiteList/NewSiteDialog";
import DeleteSiteDialog from "../components/SiteList/DeleteSiteDialog";

/**
 * Site List (home) page containing list of the user's sites.
 */
const SiteList = () => {
 const { state, setSelectedSite } = React.useContext(Context);
 const [newDialogOpen, setNewDialogOpen] = React.useState(false);
 const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
 const [siteToDelete, setSiteToDelete] = React.useState(false);

 /**
  * Clears the selected site state on mount.
  */
 React.useEffect(() => {
  setSelectedSite(null);
 }, []);

 /**
  * Opens new site dialog.
  */
 const handleNewSite = () => {
  setNewDialogOpen(true);
 };

 /**
  * Opens delete site dialog and sets site to delete state
  * to the provided state.
  */
 const handleDeleteSite = (site) => {
  setDeleteDialogOpen(true);
  setSiteToDelete(site);
 };
 return (
  <PageContainer className="scrollbar-hidden">
   <NewSiteDialog open={newDialogOpen} setOpen={setNewDialogOpen} />
   <DeleteSiteDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} siteToDelete={siteToDelete} setSiteToDelete={setSiteToDelete} />
   <PageHeader> Site Manager </PageHeader>
   <PageContent>
    {state.filteredCemeteryItems.map((site) => (
     <SiteCard key={site.SK} site={site} handleDeleteSite={handleDeleteSite} />
    ))}
   </PageContent>
   <FooterButtons>
    <Button size="large" variant="contained" color="primary" onClick={handleNewSite}>
     <AddCircleIcon size="large" sx={{ mr: 1 }} /> Add New Site
    </Button>
   </FooterButtons>
  </PageContainer>
 );
};

export default SiteList;
