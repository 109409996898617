import * as React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { handleTextChange } from "../../utilities";

import { Context } from "../../SDK/context";

import FOV from "../../SDK/src/main";

/**
 * Organization creation dialog
 */
const NewTourDialog = ({ open, setOpen, goToTour }) => {
 const { state, setCemeteries, setCemeteryItems, setSelectedSite } = React.useContext(Context);
 const [formState, setFormState] = React.useState({ Name: "" });
 const [submitting, setSubmitting] = React.useState(false);

 const handleClose = () => {
  setOpen(false);
 };

 const postTour = (newItem) => {
  return FOV.api.postRecord(state.selectedSite, newItem, "tour");
 };

 /**
  * Attempts to create a tour given the submitted form values.
  * Calls goToTour prop to navigate to new tour
  * @param {*} event form submit event
  */
 const handleSubmit = async (event) => {
  event.preventDefault();
  setSubmitting(true);
  try {
   const result = await postTour(formState);
   goToTour(result.data);
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
 };

 return (
  <Dialog open={open}>
   <DialogTitle>Create New Tour</DialogTitle>
   <form onSubmit={handleSubmit}>
    <DialogContent>
     <DialogContentText>Enter a name for your new tour.</DialogContentText>
     <TextField
      required
      autoFocus
      autoComplete="off"
      margin="dense"
      label="Site Name"
      name="Name"
      fullWidth
      variant="standard"
      value={formState.Name}
      onChange={(event) => {
       handleTextChange(event, setFormState, formState);
      }}
     />
    </DialogContent>
    <DialogActions>
     <Button disabled={submitting} onClick={handleClose}>
      Cancel
     </Button>
     <LoadingButton loading={submitting} type="submit">
      Submit
     </LoadingButton>
    </DialogActions>
   </form>
  </Dialog>
 );
};

export default NewTourDialog;
