import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "put" method,
 * accepting two arguments (cemetery, organization) in the sendRequest function,
 * updating the url to dissociate-organization,
 * and providing a body object containing the organization
 */
class DissociateOrganization extends Request {
 constructor() {
  super("put", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const cemetery = args[0];
  const organization = args[1];

  this.url = `dissociate-organization/${cemetery}`;
  return this.sendRequestPromise({ organization });
 }
}

export default DissociateOrganization;
