import * as zip from "@zip.js/zip.js";
import * as Papa from "papaparse";
import { convertAndCompress } from "../utility/ImageConversion.js";

import TrimMediaFolder from "../routes/TrimMediaFolder.js";
import BulkUpload from "../routes/BulkUpload.js";
import UploadMedia from "../routes/UploadMedia.js";
import GetMediaUrl from "../routes/GetMediaUrl.js";
import BulkGet from "../routes/BulkGet.js";
import { wrapRefresh } from "./Wrappers.js";
const trimMediaFolder = wrapRefresh(TrimMediaFolder);
const bulkUploadRequest = wrapRefresh(BulkUpload);
const uploadMedia = wrapRefresh(UploadMedia);
const getMediaUrl = wrapRefresh(GetMediaUrl);
const bulkGet = wrapRefresh(BulkGet);

function chunkArrayInGroups(arr, size) {
 var myArray = [];
 for (var i = 0; i < arr.length; i += size) {
  myArray.push(arr.slice(i, i + size));
 }
 return myArray;
}
function delay(s) {
 return new Promise((resolve) => setTimeout(resolve, s * 1000));
}

/**
 * Class for performing a bulk upload of records (burials or monuments) to a cemetery
 */
class BulkUploader {
 constructor(type, cemetery) {
  this.progressCallbacks = [];
  this.cemetery = cemetery;
  this.type = type;
  this.imageNames = [];
  this.csv = null;
  this.validatedCsv = [];
  // burial field requirements, used for validation
  const burialFieldMapping = [
   { name: "Active", type: "boolean" },
   {
    name: "Id",
    type: "string",
    required: true,
    regex: /[^A-Za-z0-9\-]+/,
    regexDescription: "must only contain numbers, letters, and dashes",
   },
   { name: "BurialTypeVeteran", type: "boolean" },
   { name: "Notable", type: "boolean" },
   { name: "Forename", type: "string", required: true },
   { name: "MiddleNames", type: "string" },
   { name: "Surname", type: "string", required: true },
   { name: "Initials", type: "string" },
   { name: "Nationality", type: "string" },
   { name: "Regiment", type: "string" },
   { name: "SecondaryRegiment", type: "string" },
   { name: "Country", type: "string" },
   { name: "Aliases", type: "string" },
   { name: "EducationalHonours", type: "string" },
   { name: "CountryCommemorations", type: "string" },
   { name: "MonumentCommemorations", type: "string" },
   { name: "CountryOfService", type: "string" },
   { name: "ServiceNumber", type: "string" },
   { name: "Rank", type: "string" },
   { name: "Unit", type: "string" },
   { name: "ForceServiceBranch", type: "string" },
   { name: "Trade", type: "string" },
   { name: "DoB", type: "string" },
   { name: "DoD", type: "string" },
   { name: "DoD2", type: "string" },
   { name: "AgeAtDeath", type: "number" },
   { name: "HonoursAwards", type: "string" },
   { name: "Description", type: "string" },
   { name: "Conflicts", type: "string" },
   { name: "FamilyInfo", type: "string" },
   { name: "GraveRefLot", type: "string" },
   { name: "GraveRefBlock", type: "string" },
   { name: "GraveRefSection", type: "string" },
   { name: "PlotOrMonument", type: "string" },
   { name: "Status", type: "string" },
   { name: "DoI", type: "string" },
   { name: "EstateDetails", type: "string" },
   { name: "Weblinks", type: "string" },
   { name: "Aux", type: "string" },
   { name: "Photo", type: "string" },
   { name: "Media", type: "string" },
   { name: "Lat", type: "number", required: true },
   { name: "Lng", type: "number", required: true },
   { name: "AssetsFolder", type: "string" },
  ];
  // monument field requirements, used for validation
  const monumentFieldMapping = [
   { name: "Active", type: "boolean" },
   {
    name: "Id",
    type: "string",
    required: true,
    regex: /[^A-Za-z0-9\-]+/,
    regexDescription: "must only contain numbers, letters, and dashes",
   }, //
   { name: "Name", type: "string", required: true },
   { name: "Country", type: "string" },
   { name: "Locality", type: "string" },
   { name: "MonumentRefLocation", type: "string" },
   { name: "StateProvince", type: "string" },
   { name: "Accessibility", type: "string" },
   { name: "Owner", type: "string" },
   { name: "DedicatedBy", type: "string" },
   { name: "DedicatedDate", type: "string" },
   { name: "Conflicts", type: "string" },
   { name: "Casualties", type: "number" },
   { name: "Artist", type: "string" },
   { name: "Architect", type: "string" },
   { name: "Description", type: "string" },
   { name: "Weblinks", type: "string" },
   { name: "Labels", type: "string" },
   { name: "Aux", type: "string" },
   { name: "Photo", type: "string" },
   { name: "Media", type: "string" },
   { name: "Lat", type: "number", required: true },
   { name: "Lng", type: "number", required: true },
  ];

  if (type === "monument") {
   this.fieldMapping = monumentFieldMapping;
  } else {
   this.fieldMapping = burialFieldMapping;
  }
 }
}

/**
 * Attempts to unzip a provided file, then invokes handleEntry on all
 * the expanded files
 */
BulkUploader.prototype.unzip = function (file) {
 this.file = file;
 return new Promise(async (resolve, reject) => {
  try {
   const reader = new zip.ZipReader(new zip.BlobReader(file));
   const entries = await reader.getEntries();

   let handleEntryPromises = [];
   for (let entry of entries) {
    handleEntryPromises.push(this.handleEntry(entry));
   }
   await Promise.all(handleEntryPromises)
    .then(() => {
     resolve();
    })
    .catch((err) => {
     reject(err);
    });
   reader.close();
  } catch (err) {
   reject(err);
  }
 });
};

/**
 * Handles a file expanded from a zip.
 * If the file is a csv, attempts to parse it and set this.csv to the result.
 * If the file is an image, adds its name to this.imageNames.
 */
BulkUploader.prototype.handleEntry = function (entry) {
 return new Promise((resolve, reject) => {
  if (!entry.directory) {
   const name = entry.filename;

   const ext = name.split(".").pop().toLowerCase();
   if (ext === "csv" && !this.csv) {
    entry
     .getData(new zip.TextWriter("text/plain"))
     .then((csv) => {
      // surrounds comma-separated values in speech marks
      csv = csv.replace(/", "/g, '","');
      csv = csv.replace(/, "/g, ',"');
      this.csv = Papa.parse(csv, {
       header: true,
       skipEmptyLines: true,
      }).data;
      resolve();
     })
     .catch((err) => {
      reject(err);
     });
   } else if (ext === "png" || ext === "jpg") {
    this.imageNames.push(name);
    resolve();
   } else {
    resolve();
   }
  } else {
   resolve();
  }
 });
};

/**
 * Checks that every row of this.csv is valid for bulk upload and parses types for a row if it is.
 * Every row ID must be unique.
 * Every media reference must have a corresponding photo in this.imageNames.
 * Every row must conform to the validation requirements specified by this.fieldMapping (enforced by this.validateRow).
 * Returns an object containing a success status and an optional errors array.
 * Sets this.validatedCSV to an array of validated and processed CSV rows.
 */
BulkUploader.prototype.validateCSV = function () {
 let existingIds = [];
 let errors = [];
 let rowIndex = 0;
 for (let row of this.csv) {
  ++rowIndex;
  if (existingIds.includes(row.Id)) {
   errors.push(`The ID ${row.Id} on row ${rowIndex + 1} has been used for multiple rows`);
  } else {
   existingIds.push(row.Id);
  }
  if (row.Photo && !this.imageNames.includes(row.Photo)) {
   errors.push(`Row ${rowIndex + 1} includes the photo ${row.Photo} but it has not been provided.`);
  }
  if (row.Media) {
   let photos = row.Media.split(";");
   for (let photo of photos) {
    if (!this.imageNames.includes(photo)) {
     errors.push(`Row ${rowIndex + 1} includes the media ${photo} but it has not been provided.`);
    }
   }
  }
  const validateRowResult = this.validateRow(row, rowIndex, this.fieldMapping);
  if (!validateRowResult.status) {
   errors.push(validateRowResult.reason);
  } else {
   this.validatedCsv.push(validateRowResult.newObject);
  }
 }
 if (errors.length) {
  return { status: false, errors };
 } else {
  return { status: true };
 }
};

/**
 * Checks that a csv row complies with this.fieldMapping and parses field types if it is.
 * Returns an object containing a success status and either a reason for failure or a new object
 * made by processing the row.
 */
BulkUploader.prototype.validateRow = function (row, index) {
 let newObject = {};
 for (let field of this.fieldMapping) {
  if (typeof row[field.name] === "undefined") {
   if (!field.required) {
    continue;
   } else {
    const failedReturn = {
     status: false,
     reason: `The field "${field.name}" for row ${index} is required but not provided"`,
    };
    return failedReturn;
   }
  }
  if (typeof row[field.name] === field.type) {
   if (typeof field.regex !== "undefined") {
    if (field.regex.test(row[field.name])) {
     const failedReturn = {
      status: false,
      reason: `The field "${field.name}" for row ${index} does not match the requirement (${field.regexDescription})`,
     };
     return failedReturn;
    }
   }
   newObject[field.name] = row[field.name];
  } else {
   const failedReturn = {
    status: false,
    reason: `The field "${field.name}" for row ${index} is not of the type "${field.type}"`,
   };
   switch (field.type) {
    case "number":
     const num = Number(row[field.name]);
     if (num) {
      newObject[field.name] = num;
     } else {
      return failedReturn;
     }
     break;
    case "string":
     newObject[field.name] = `${row[field.name]}`;
     break;
    case "boolean":
     if ((row[field.name] + "").toLowerCase() === "false" || row[field.name].toLowerCase() === "no") {
      newObject[field.name] = false;
     } else {
      newObject[field.name] = Boolean(row[field.name]);
     }
     break;
    default:
     return failedReturn;
   }
  }
 }
 return { status: true, newObject };
};

/**
 * Generates a table to preview the result of proceeding with a bulk upload.
 * Bulk fetches existing records to visualise upcoming changes.
 * Calls progressCallback with the status of the preview process.
 */
BulkUploader.prototype.preview = async function (progressCallback) {
 return new Promise((resolve, reject) => {
  progressCallback({
   process: "Previewing",
   state: "in progress",
   progress: 0,
   message: [],
  });
  const previewItems = this.validatedCsv.slice(0, 50);
  const previewIds = previewItems.map((a) => a.Id);
  progressCallback({
   process: "Previewing",
   state: "completed",
   progress: 1,
   message: [],
  });
  bulkGet(this.cemetery, this.type, previewIds)
   .then((result) => {
    progressCallback({
     process: "Previewing",
     state: "completed",
     progress: 1,
     message: [],
    });
    const existingItems = result.data.Responses[Object.keys(result.data.Responses)[0]];
    const existingIds = existingItems.map((a) => a.SK);
    let comparisons = [];
    for (let item of previewItems) {
     const oldIndex = existingIds.indexOf(`${this.type}_${item.Id}`);
     const oldItem = existingItems[oldIndex];
     let comparison = {};
     if (oldItem) {
      delete oldItem.Author;
      delete oldItem.Timestamp;
      for (let key of Object.keys(oldItem)) {
       comparison[key] = { old: oldItem[key] };
      }
     }
     for (let key of Object.keys(item)) {
      if (comparison[key]) {
       comparison[key].new = item[key];
      } else {
       comparison[key] = { new: item[key] };
      }
     }
     comparisons.push(comparison);
    }

    const columns = [];
    for (let col of this.fieldMapping) {
     for (let row of comparisons) {
      if (row[col.name]) {
       columns.push(col.name);
       break;
      }
     }
    }

    resolve({
     comparisons,
     columns,
    });
   })
   .catch((err) => {
    progressCallback({
     process: "Previewing",
     state: "failed",
     progress: 0,
     message: err.toString().toString(),
    });
    resolve();
   });
 });
};

/**
 * Attempts to upload all of the records in this.validatedCSV in chunks.
 * Calls progressCallback with the status of the upload process.
 * Implements exponential backoff.
 */
BulkUploader.prototype.upload = async function (progressCallback) {
 const chunks = chunkArrayInGroups(this.validatedCsv, 25);

 let chunksUploaded = 0;
 for (let chunk of chunks) {
  let uploaded = false;
  let timeout = 5;
  while (!uploaded) {
   try {
    const result = await this.demoUploadChunk(chunk, this.file, progressCallback);
    chunks[chunksUploaded] = result;
    uploaded = true;
   } catch (result) {
    if (result) {
     console.log("chunk", chunksUploaded + 1, "failed, waiting", timeout, "seconds to retry");
     await delay(timeout);
     timeout = parseInt(timeout * 1.5);
    } else {
     return false;
    }
   }
  }
  progressCallback({
   process: "Uploading",
   state: "in progress",
   progress: ++chunksUploaded / chunks.length,
   message: [],
  });
 }
 progressCallback({
  process: "Uploaded",
  state: "completed",
  progress: 1,
  message: [],
 });
 return true;
};

/**
 * Attempts to unzip a provided file and generate a compressed version of all
 * image files corresponding to the names in the provided imageNames array.
 * Resolves with a mapping of image names to compressed image files.
 */
BulkUploader.prototype.getImageBuffers = async function (imageNames, file) {
 return new Promise(async (resolve, reject) => {
  let mapping = {};
  const reader = new zip.ZipReader(new zip.BlobReader(file));
  const entries = await reader.getEntries();
  for (let entry of entries) {
   const name = entry.filename;
   if (imageNames.includes(name)) {
    await entry
     .getData(new zip.BlobWriter(file))
     .then(async (blob) => {
      try {
       const file = await convertAndCompress(blob, 5000);
       mapping[name] = file;
      } catch (err) {
       reject(err);
      }
     })
     .catch((err) => {
      reject(err);
     });
   }
  }
  reader.close();
  resolve(mapping);
 });
};
/**
 * Attempts to upload a chunk of records, making use of getImageBuffers and demoUploadMedia to upload any
 * media for the record and trimMediaFolder to delete any pre-existing media.
 * Calls progressCallback with the status of the upload process.
 */
BulkUploader.prototype.demoUploadChunk = function (chunk, file, progressCallback) {
 return new Promise(async (resolve, reject) => {
  let s3Promises = [];
  let trimPromises = [];
  let chunkImages = [];
  let imageNames = [];
  let itemIndex = 0;
  for (let item of chunk) {
   let mapping = [];
   if (item.Photo) {
    mapping.push({ name: item.Photo, type: "photo" });
   }
   if (item.Media) {
    for (let photo of item.Media.split(";")) {
     mapping.push({ name: photo, type: "media" });
    }
   }

   let thisItemIndex = itemIndex;

   trimPromises.push(
    new Promise((resolve, reject) => {
     trimMediaFolder(this.cemetery, this.type, item.Id, mapping)
      .then((result) => {
       for (let map of result.data.mapping) {
        if (!map.exists) {
         chunkImages.push({
          itemIndex: thisItemIndex,
          type: map.type,
          name: map.name,
          id: item.Id,
         });
         imageNames.push(map.name);
        } else {
         if (map.type === "media") {
          chunk[thisItemIndex].Media = chunk[thisItemIndex].Media.split(";")
           .map((a) => (a === map.name ? `${result.data.baseUrl}/${map.path}` : a))
           .join(";");
         } else if (map.type === "photo") {
          chunk[thisItemIndex].Photo = `${result.data.baseUrl}/${map.path}`;
         }
        }
       }
       resolve();
      })
      .catch((err) => {
       resolve();
      });
    })
   );
   ++itemIndex;
  }
  progressCallback({
   process: "Trimming Storage",
   state: "in progress",
   progress: 0,
   message: [],
  });
  try {
   await Promise.all(trimPromises);
   progressCallback({
    process: "Trimming Storage",
    state: "completed",
    progress: 1,
    message: [],
   });
  } catch (err) {
   progressCallback({
    process: "Trimming Storage",
    state: "failed",
    progress: 0,
    message: [err.toString()],
   });
  }

  if (imageNames.length) {
   progressCallback({
    process: "Getting Image Buffers",
    state: "in progress",
    progress: 0,
    message: [],
   });
   await this.getImageBuffers(imageNames, file)
    .then((mapping) => {
     for (let image of chunkImages) {
      image.buffer = mapping[image.name];
      s3Promises.push(this.demoUploadMedia(image));
     }
    })
    .catch();

   await Promise.all(s3Promises)
    .then((results) => {
     progressCallback({
      process: "Getting Image Buffers",
      state: "completed",
      progress: 1,
      message: [],
     });
     for (let result of results) {
      if (result.type === "photo") {
       chunk[result.itemIndex].Photo = result.url;
      } else if (result.type === "media") {
       chunk[result.itemIndex].Media = chunk[result.itemIndex].Media.split(";")
        .map((a) => (a === result.name ? result.url : a))
        .join(";");
      }
     }
    })
    .catch((err) => {
     progressCallback({
      process: "Getting Image Buffers",
      state: "failed",
      progress: 0,
      message: [err.toString()],
     });
     reject();
    });
  }

  progressCallback({
   process: "Uploading Chunk",
   state: "in progress",
   progress: 0,
   message: [],
  });
  bulkUploadRequest(this.cemetery, this.type, chunk)
   .then(() => {
    progressCallback({
     process: "Uploading Chunk",
     state: "completed",
     progress: 1,
     message: [],
    });
    setTimeout(() => {
     resolve();
    }, 500);
   })
   .catch((err) => {
    if (err.response) {
     if (err.response.status === 500) {
      progressCallback({
       process: "Uploading Chunk",
       state: "failed",
       progress: 0,
       message: [err.response],
      });
      reject(true);
     } else if (err.resopnse.status === 400) {
      progressCallback({
       process: "Uploading Chunk",
       state: "failed",
       progress: 0,
       message: [err.response],
      });
      reject(false);
     }
    }
   });
 });
};

/**
 * Attempts to fetch a media url to upload a file to S3, then
 * uses this url to upload a provided image.
 */
BulkUploader.prototype.demoUploadMedia = function (image) {
 return new Promise(async (resolve, reject) => {
  const { itemIndex, type, name, buffer, id } = image;
  getMediaUrl(this.cemetery, this.type, id, type, name)
   .then((result) => {
    uploadMedia(buffer, type, result.data.url)
     .then(() => {
      resolve({
       itemIndex,
       type,
       name,
       url: result.data.url.split("?")[0],
      });
     })
     .catch(reject);
   })
   .catch(reject);
 });
};

/**
 * Function to initiate the bulk upload process, returning an object containing a
 * preview and the instance of the BulkUploader being used. This BulkUploader instance
 * can then be used to perform a bulk upload by calling upload on the instance.
 * Generates a new BulkUploader and calls unzip, validateCSV, then preview on the instance.
 * Calls progressCallback with the status of the process.
 */
async function bulkUpload(cemetery, type, file, progressCallback) {
 const bulkUploader = new BulkUploader(type, cemetery);
 let result = false;
 progressCallback({
  process: "Expanding ZIP",
  state: "in progress",
  progress: 0,
  message: [],
 });
 await bulkUploader
  .unzip(file)
  .then(async () => {
   if (bulkUploader.csv) {
    progressCallback({
     process: "Expanding ZIP",
     state: "completed",
     progress: 1,
     message: [],
    });
   } else {
    progressCallback({
     process: "Expanding ZIP",
     state: "failed",
     progress: 0,
     message: ["no csv found"],
    });
    return false;
   }

   progressCallback({
    process: "Validating",
    state: "in progress",
    progress: 0,
    message: [],
   });
   let validationResult;
   try {
    validationResult = await bulkUploader.validateCSV(progressCallback);
   } catch (err) {
    progressCallback({
     process: "Validating",
     state: "failed",
     progress: 0,
     message: [err.toString()],
    });
    return false;
   }

   if (!validationResult.status) {
    progressCallback({
     process: "Validating",
     state: "failed",
     progress: 0,
     message: validationResult.errors,
    });
    return false;
   } else {
    progressCallback({
     process: "Validating",
     state: "completed",
     progress: 1,
     message: [],
    });
    progressCallback({
     process: "Uploading",
     state: "in progress",
     progress: 0,
     message: [],
    });
    try {
     const preview = await bulkUploader.preview(progressCallback);
     result = { preview, bulkUploader };
    } catch (err) {
     progressCallback({
      process: "Previewing",
      state: "failed",
      progress: 0,
      message: [err.toString()],
     });
    }
   }
  })
  .catch((err) => {
   progressCallback({
    process: "Expanding ZIP",
    state: "failed",
    progress: 0,
    message: ["Unable to unzip file"],
   });
  });
 return result;
}

export default bulkUpload;
