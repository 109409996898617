import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ResultForm from "./Quicklook";

import { Context } from "../../SDK/context";

/**
 * General, expandable search result component.
 * Uses the appropriate ResultForm given the prop-provided record type
 */
const Result = ({ record, deleteRecord }) => {
 const navigate = useNavigate();
 const { state } = React.useContext(Context);
 const [title, setTitle] = React.useState("");
 const [formComponent, setFormComponent] = React.useState();
 const [type, setType] = React.useState();

 // Navigates to record form on edit
 const handleEdit = (e) => {
  e.stopPropagation();
  navigate(`/${type}/${state.selectedSite}/${record.SK.split("_")[1]}`);
 };

 const handleDelete = (e) => {
  e.stopPropagation();
  deleteRecord(record);
 };

 React.useEffect(() => {
  switch (record.SK.split("_")[0]) {
   case "burial":
    setType("Burial");
    setTitle(`${record.Forename} ${record.Surname}`);
    setFormComponent(<ResultForm record={record} setTitle={setTitle} />);
    break;
   case "monument":
    setType("Monument");
    setTitle(record.Name);
    setFormComponent(<ResultForm record={record} setTitle={setTitle} />);
    break;
   case "poi":
    setType("Poi");
    setTitle(record.Name);
    setFormComponent(<ResultForm record={record} setTitle={setTitle} />);
    break;
   default:
    break;
  }
 }, []);
 return (
  <Accordion>
   <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ width: "100%" }}>
    <Typography>{title}</Typography>
    <Box sx={{ display: "flex", ml: "auto" }}>
     <Button sx={{ mr: 2, p: 0 }} onClick={handleDelete}>
      <DeleteIcon />
     </Button>
     <Button variant="contained" sx={{ mr: 2, p: 0 }} onClick={handleEdit}>
      <EditIcon />
     </Button>
    </Box>
   </AccordionSummary>
   <Divider />
   <AccordionDetails>{formComponent}</AccordionDetails>
  </Accordion>
 );
};

export default Result;
