import styled from "@emotion/styled";
import { Card, Box, Typography } from "@mui/material";

const FormHeader = styled(Typography)(({ theme }) => ({
 m: 2,
 textAlign: "center",
 fontSize: "30px",
 color: theme.palette.primary.main,
}));

const Logo = styled("SvgIcon")(({ theme }) => ({
 color: "white",
}));

const PageHeader = styled(Card)(({ theme }) => ({
 background: theme.palette.tertiary.main,
 opacity: 1,
 width: "100%",
 height: "50px",
 margin: 0,
 borderRadius: 0,
 display: "flex",
 justifyContent: "center",
 alignItems: "center",
 fontSize: "40px",
 color: theme.palette.primary.main,
 boxShadow: "0 -1px 8px rgba(0,0,0,0.2)",
}));

const FooterButtons = styled(Box)(({ theme }) => ({
 background: theme.palette.tertiary.main,
 height: "50px",
 borderRadius: 0,
 position: "sticky",
 bottom: 0,
 justifyContent: "center",
 alignItems: "center",
 textAlign: "center",
 color: theme.palette.light.main,
 boxShadow: "0 1px 8px rgba(0,0,0,0.2)",
 zIndex: 1,
 display: "flex",
 flexGrow: "1",
 flexDirection: "column",
 width: "100%",
}));

const PageContainer = styled("div")(({ theme }) => ({
 height: "100%",
 overflowY: "scroll",
}));

const PageContainerNoScroll = styled("div")(({ theme }) => ({
 height: "100%",
 overflow: "hidden",
}));

const PageContent = styled(Box)(({ theme }) => ({
 paddingLeft: 2,
 paddingRight: 2,
 display: "flex",
 flexDirection: "column",
 minHeight: "100%",
}));

export { Logo, PageHeader, FooterButtons, PageContainer, PageContent, FormHeader, PageContainerNoScroll };
