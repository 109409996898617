import { createTheme } from "@mui/material";

// material ui colour theme
export const theme = createTheme({
 palette: {
  primary: {
   main: "rgb(71, 95, 117)",
  },
  secondary: {
   main: "rgb(201, 96, 21)",
  },
  tertiary: {
   main: "#e2e2e2",
  },
  light: {
   main: "rgb(255,255,255)",
  },
  grey: {
   main: "#F5F5F5",
  },
  dark: {
   main: "161616",
  },
  form: {
   main: "#F2F2F2",
  },
  success: {
   main: "#bac778",
  },
 },
});
