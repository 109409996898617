import { Provider } from "./SDK/context";
import Router from "./Router";
import "./App.css";

/**
 * App component.
 * Returns the Router component, wrapped in the SDK context provider.
 */
function App() {
 return (
  <Provider>
   <Router />
  </Provider>
 );
}

export default App;
