import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "put" method,
 * accepting two arguments (cemetery, Item) in the sendRequest function,
 * updating the url to trim-media,
 * and providing the Item as the request body
 */
class UpdateCemetery extends Request {
 constructor() {
  super("put", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const cemetery = args[0];
  const Item = args[1];

  this.url = `cemetery/${cemetery}`;
  return this.sendRequestPromise(Item);
 }
}

export default UpdateCemetery;
