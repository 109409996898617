import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "post" method,
 * accepting an email argument in the sendRequest function,
 * updating the url to forgot-password,
 * and providing a body object containing the email
 */
class ForgotPassword extends Request {
 constructor() {
  super("post", `forgot-password`);
 }
 sendRequest(email) {
  return this.sendRequestPromise({ email });
 }
}

export default ForgotPassword;
