import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography, Box } from "@mui/material";

/**
 * Bulk upload guide table
 */
const UploadTable = ({ headers, columns }) => {
 return (
  <TableContainer component={Paper}>
   <Table aria-label="simple table">
    <TableHead>
     <TableRow>
      {headers.map((header, _i) => (
       <TableCell key={_i} color="primary">
        <Typography fontWeight={header.required ? "bold" : "normal"}>
         {header.name}
         {header.required ? "*" : ""}
        </Typography>
       </TableCell>
      ))}
     </TableRow>
    </TableHead>
    <TableBody>
     <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      {columns.map((col, _i) => (
       <TableCell key={_i}>
        <Box>
         <Typography color="primary">{col.description}</Typography>
         <br />
         I.e. {col.example}
        </Box>
       </TableCell>
      ))}
     </TableRow>
    </TableBody>
   </Table>
  </TableContainer>
 );
};

export default UploadTable;
