import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "post" method,
 * accepting an Item argument in the sendRequest function,
 * updating the url to organization,
 * and providing the Item as the request body
 */
class PostOrganization extends Request {
 constructor() {
  super("post", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const Item = args[0];

  this.url = `organization`;
  return this.sendRequestPromise(Item);
 }
}

export default PostOrganization;
