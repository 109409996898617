import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "post" method,
 * accepting an ids argument in the sendRequest function,
 * updating the url to bulk-get,
 * and providing a body object containing the ids
 */
class BulkGetOrganizations extends Request {
 constructor() {
  super("post", `bulk-get/organization/organization`);
 }
 sendRequest(ids) {
  return this.sendRequestPromise({ ids });
 }
}

export default BulkGetOrganizations;
