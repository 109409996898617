import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, TextField, Paper, Typography, FormControl, FormGroup, FormControlLabel, Switch, FormLabel, Divider, Box } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import { PageHeader, FooterButtons, PageContainer, PageContent, FormHeader } from "../components/styled-components";

import { Context } from "../SDK/context";
import ImageUpload from "../components/ImageUpload";
import GalleryUpload from "../components/GalleryUpload";
import AutoLoadSite from "../components/AutoLoadSite";
import FormDetails from "../components/form-details";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SaveIcon from "@mui/icons-material/Save";
import MapPicker from "../components/MapPicker";

import FOV from "../SDK/src/main";

import { handleSwitchChange, handleMedia, getNewItem, handleFloatChange } from "../utilities";

import { getDefaultForm } from "../assets/default-forms";

/**
 * Record form page for burials/monuments/POIs
 */
const RecordForm = ({ type, paramName }) => {
 const submitButton = React.useRef();
 const navigate = useNavigate();
 const params = useParams();
 const { state, setLoading, setLoadingRecord } = React.useContext(Context);
 const [formState, setFormState] = React.useState(getDefaultForm(type));
 const [defaultFormState, setDefaultFormState] = React.useState(getDefaultForm(type));
 const [newMedia, setNewMedia] = React.useState({});
 const [media, setMedia] = React.useState([]);
 const [open, setOpen] = React.useState(false);
 const [imageCoords, setImageCoords] = React.useState();
 const [jump, setJump] = React.useState();
 const [selectedSite, setSelectedSite] = React.useState();
 const [mapPickerState, setMapPickerState] = React.useState({ Lat: 0, Lng: 0 });

 /**
  * On map picker state change, updates the form state with Lat and Lng fields corresponding to the map picker values.
  */
 React.useEffect(() => {
  if (mapPickerState.Lat != formState.Lat && mapPickerState.Lng != formState.Lng) {
   setFormState({ ...formState, Lat: mapPickerState.Lat, Lng: mapPickerState.Lng });
  }
 }, [mapPickerState]);

 /**
  * On record ID parameter change and selected site change, resets state if the parameter is undefined,
  * otherwise attempts to fetch the record and open the existing record dialog.
  */
 React.useEffect(() => {
  if (!params[paramName]) {
   reset();
  } else if (params[paramName] && state.selectedSite) {
   getRecord();
   handleOpen();
  }
 }, [params[paramName], state.selectedSite]);

 /**
  * On selected site change, sets local selected site state to the site item corresponding
  * to the selected site. Sets image coordinates state to the site's map coordinates and jumps
  * to the centre of the coordinates if they are valid.
  * are valid
  */
 React.useEffect(() => {
  if (!state.selectedSite) {
   return;
  }
  const cemetery = state.cemeteryItems.filter((a) => a.SK === state.selectedSite)[0];
  setSelectedSite(cemetery);
  if (
   typeof cemetery.MapALat !== "undefined" &&
   typeof cemetery.MapBLat !== "undefined" &&
   typeof cemetery.MapCLat !== "undefined" &&
   typeof cemetery.MapDLat !== "undefined" &&
   typeof cemetery.MapALng !== "undefined" &&
   typeof cemetery.MapBLng !== "undefined" &&
   typeof cemetery.MapCLng !== "undefined" &&
   typeof cemetery.MapDLng !== "undefined" &&
   cemetery.MapALat != cemetery.MapDLat &&
   cemetery.MapCLat != cemetery.MapBLat &&
   cemetery.MapALng != cemetery.MapBLng &&
   cemetery.MapCLng != cemetery.MapDLng
  ) {
   setImageCoords([
    [cemetery.MapALng, cemetery.MapALat],
    [cemetery.MapBLng, cemetery.MapBLat],
    [cemetery.MapCLng, cemetery.MapCLat],
    [cemetery.MapDLng, cemetery.MapDLat],
   ]);
   setJump([(cemetery.MapALng + cemetery.MapBLng + cemetery.MapCLng + cemetery.MapDLng) / 4, (cemetery.MapALat + cemetery.MapBLat + cemetery.MapCLat + cemetery.MapDLat) / 4]);
  }
 }, [state.selectedSite]);

 /**
  * Updates the map picker state with a new Lat,Lng coordinate position
  */
 const updatePosition = (newPosition) => {
  setMapPickerState({ Lat: newPosition.Lat, Lng: newPosition.Lng });
 };

 /**
  * Opens the existing record dialog
  */
 const handleOpen = () => {
  setOpen(true);
 };

 /**
  * Closes the existing record dialog
  */
 const handleClose = () => {
  setOpen(false);
 };

 /**
  * Attempts to fetch a record item.
  * Combines formState with the record item and sets map picker coordinates
  * to the record lat,lng on success.
  * Otherwise, calls startFresh.
  * Sets loading record state to true for the duration of the fetch.
  */
 const getRecord = async () => {
  setLoadingRecord(true);
  try {
   const result = await FOV.api.getRecord(state.selectedSite, type, params[paramName]);
   if (result.data.SK) {
    setFormState({
     ...formState,
     ...result.data,
     Id: result.data.SK.split("_")[1],
    });
    setMapPickerState({ Lat: result.data.Lat, Lng: result.data.Lng });
   } else {
    startFresh();
   }
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
  setLoadingRecord(false);
 };

 /**
  * Attempts to upsert a record item to the database.
  */
 const postRecord = (newItem) => {
  return FOV.api.postRecord(state.selectedSite, newItem, type);
 };

 /**
  * Attempts to upsert a record item to the database, making use
  * of handleMedia and getNewItem utility functions.
  * Parses coordinate fields before submitting.
  * If the record is not a veteran, sets military fields
  * to default.
  * On success, sets formState to the new record item,
  * resets newMedia state, and sets media state to the new
  * record media list
  * Sets loading to true for the duration of the update.
  * @param {*} event  form submit event
  */
 const handleSubmit = async (event) => {
  event.preventDefault();
  setLoading(true);
  try {
   const newFormState = { ...formState };

   for (let field of ["Lat", "Lng"]) {
    const value = parseFloat(newFormState[field], 10);
    if (isNaN(value)) {
     newFormState[field] = 0;
    } else {
     newFormState[field] = value;
    }
   }

   if (!newFormState.BurialTypeVeteran) {
    newFormState.Regiment = defaultFormState.Regiment;
    newFormState.SecondaryRegiment = defaultFormState.SecondaryRegiment;
    newFormState.CountryOfService = defaultFormState.CountryOfService;
    newFormState.Rank = defaultFormState.Rank;
    newFormState.Unit = defaultFormState.Unit;
    newFormState.ForceServiceBranch = defaultFormState.ForceServiceBranch;
    newFormState.Trade = defaultFormState.Trade;
    newFormState.Conflicts = defaultFormState.Conflicts;
   }

   const mapping = await handleMedia(newMedia, newFormState, state.selectedSite, type, newFormState.SK || newFormState.Id);
   const newRecord = getNewItem(mapping, newFormState, media);
   await postRecord(newRecord);
   setFormState(newRecord);
   setNewMedia({});
   setMedia(newRecord.Media.split(";"));
   if (!params[paramName]) {
    navigate(`/${paramName}/${state.selectedSite}/${formState.Id}`);
   }
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
  setLoading(false);
 };

 /**
  * Rests component state to empty defaults.
  */
 const reset = () => {
  setFormState(getDefaultForm(type));
  setNewMedia({});
  setMedia([]);
 };

 /**
  * Calls reset and clears any existing record ID from
  * the url. Also closes existing record dialog.
  */
 const startFresh = () => {
  reset();
  navigate(`/${paramName}/${state.selectedSite}`);
  handleClose();
 };

 return (
  <PageContainer className="scrollbar-hidden">
   <AutoLoadSite />
   <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">Existing {type === "poi" ? "POI" : type}</DialogTitle>
    <DialogContent>
     <DialogContentText id="alert-dialog-description">Any further changes will affect the existing {type === "poi" ? "POI" : type}.</DialogContentText>
    </DialogContent>
    <DialogActions>
     <Button onClick={startFresh}>Create new</Button>
     <Button onClick={handleClose}>Continue to edit the existing {type}</Button>
    </DialogActions>
   </Dialog>
   <PageHeader>Manage {paramName}</PageHeader>
   <PageContent>
    <form
     id="myForm"
     onSubmit={handleSubmit}
     style={{
      height: "100%",
      display: "block",
      marginTop: "30px",
      marginBottom: "30px",
     }}
    >
     <Paper
      elevation={3}
      sx={{
       display: "flex",
       maxWidth: "900px",
       margin: "auto",
       background: (theme) => theme.palette.form.main,
      }}
     >
      <FormControl sx={{ flexGrow: 1 }}>
       <FormGroup>
        <FormControlLabel
         sx={{ margin: "auto" }}
         control={<Switch checked={formState.Active} name="Active" onChange={(e) => handleSwitchChange(e, setFormState, formState)} />}
         label="Display in mobile app"
        />
        <Divider />

        <FormHeader>{paramName} Gallery</FormHeader>
        <Paper
         square
         sx={{
          m: "10px",
          padding: "10px",
          maxWidth: "calc(100% - 20px)",
          background: (theme) => theme.palette.tertiary.main,
         }}
        >
         <GalleryUpload formState={formState} newMedia={newMedia} setNewMedia={setNewMedia} media={media} setMedia={setMedia} />
        </Paper>
        <FormHeader>Photo of location</FormHeader>

        <ImageUpload fieldName="Photo" formState={formState} setFormState={setFormState} newMedia={newMedia} setNewMedia={setNewMedia} />
        {/* </Paper> */}
        <Divider />
        <FormHeader>{paramName} Details</FormHeader>
        <FormDetails type={type} setFormState={setFormState} formState={formState} />
        <Divider />
        {/* <Attribution /> */}
        {jump && imageCoords && (
         <>
          <FormHeader>Click or tap on the map below to choose a location</FormHeader>
          <MapPicker location={jump} coordinates={imageCoords} url={selectedSite?.Map} lat={mapPickerState?.Lat} lng={mapPickerState?.Lng} updatePosition={updatePosition} />
         </>
        )}

        <Button ref={submitButton} variant="contained" sx={{ background: (theme) => theme.palette.primary.main, display: "none" }} type="submit">
         <Typography fontSize="large" sx={{ display: "flex", alignItems: "center" }}>
          Save
          <SaveIcon sx={{ m: 1 }} />
         </Typography>
        </Button>
       </FormGroup>
      </FormControl>
     </Paper>
    </form>
   </PageContent>
   <FooterButtons sx={{}}>
    <Button
     size="large"
     variant="contained"
     color="primary"
     onClick={() => {
      submitButton.current.click();
     }}
    >
     Save <SaveIcon sx={{ ml: 1 }} />
    </Button>
   </FooterButtons>
  </PageContainer>
 );
};

export default RecordForm;
