import * as React from "react";
import { Layer, Feature, Popup, MapContext } from "react-mapbox-gl";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import { PathingControls } from "./MapControls";
import { Mode } from "./Mode";
import JumpTo from "./JumpTo";
import { ImageLayer } from "./MapWithImage";

import Mapbox from "./Mapbox";

/**
 * Combines the PathMap component with a map image layer and JumpTo utility component
 * all wrapped inside a Mapbox component
 */
const PathEditor = (props) => {
 const { containerStyle, map, onMapChange, location, imageCoord, imageUrl, imageOpacity, setImageOpacity, pathFile, setPathFile, lastNode, setLastNode } = props;

 const toggleOpacity = () => {
  if (imageOpacity) {
   setImageOpacity(0);
  } else {
   setImageOpacity(1);
  }
 };

 return (
  <Mapbox style="mapbox://styles/mapbox/satellite-streets-v11" center={location} containerStyle={containerStyle}>
   <ImageLayer coordinates={imageCoord} url={imageUrl} opacity={imageOpacity} />
   <PathMap graph={pathFile} setGraph={setPathFile} lastNode={lastNode} setLastNode={setLastNode} {...{ onMapChange }} map={map} toggleOpacity={toggleOpacity} opacity={imageOpacity} />
   <JumpTo {...{ location }} />
  </Mapbox>
 );
};

const circleStyles = {
 "circle-color": "#FFFFFF",
 "circle-radius": ["interpolate", ["linear"], ["zoom"], 14, 2, 18, 8],
 "circle-stroke-width": ["interpolate", ["linear"], ["zoom"], 14, 1, 18, 3],
 "circle-stroke-color": ["case", ["boolean", ["get", "selected"], true], "#FF7F1D", "#FFFFFF"],
};

const lineLayout = {
 "line-cap": "round",
 "line-join": "round",
};

const linePaint = {
 "line-color": ["case", ["boolean", ["get", "selected"], true], "#FF2F0D", "#FF7F1D"],
 "line-width": 3,
};

/**
 * Converts map to an array of key,value objects and stringifies it to remove references
 */
export const stringifyMapObj = (map) => {
 return JSON.stringify(mapToArrayObj(map));
};
/**
 * Returns map, converted to an array of key,value objects
 */
export const mapToArrayObj = (map) => {
 return [...map.entries()].map((entry) => ({
  key: entry[0],
  value: entry[1],
 }));
};

/**
 * Generates a new Map from a stringified source
 */
export const parseMap = (source) => {
 return new Map(JSON.parse(source));
};

/**
 * Returns a stringified array of map entries
 */
export const stringifyMap = (map) => {
 return JSON.stringify([...map.entries()]);
};

/**
 * Component for displaying and editing a cemetery path.
 * For use within a Mapbox component
 */
const PathMap = (props) => {
 const map = React.useContext(MapContext);

 const [mode, setMode] = React.useState(Mode.Default);
 const { graph, setGraph, lastNode, setLastNode, toggleOpacity, opacity } = props;
 // Keeps track of the last selected line
 const [lastLine, setLastLine] = React.useState();

 // If the path graph changes and the editor mode is set to default,
 // set mode state to Add
 React.useEffect(() => {
  if (mode === Mode.Default) {
   setMode(Mode.Add);
  }
 }, [graph]);

 const hover = React.useRef(false);
 const [dragging, setDragging] = React.useState(false);

 // Clears last selected line and node
 const deselect = () => {
  setLastNode(null);
  setLastLine(null);
 };

 // Creates a new node in the pathing graph and sets it as
 // the last selected node
 const addPoint = (coordinates) => {
  const newGraph = graph;
  const key = uuid();
  newGraph.set(key, {
   coordinates,
   adjacent: lastNode ? [lastNode] : [],
  });

  if (lastNode) {
   addToAdjacencyArray(newGraph, lastNode, key);
  }

  setGraph(new Map(newGraph));

  setLastNode(key);
 };

 // If key1 and key2 are connected, returns True, otherwise False
 const connected = (key1, key2) => {
  return key1 === key2 || graph.get(key1).adjacent.includes(key2) || graph.get(key2).adjacent.includes(key1);
 };

 // Delets a node in the pathing graph and clears the last selected node.
 // Also sets hover to false since the hovered node no longer exists
 const deleteNode = (key) => {
  const newGraph = graph;
  newGraph.get(key).adjacent.forEach((value) => {
   let entryValue = newGraph.get(value);
   newGraph.set(value, {
    ...entryValue,
    // filter deleted key from adjacent keys
    adjacent: entryValue.adjacent.filter((adjKey) => adjKey !== key),
   });
  });
  newGraph.delete(key);
  setLastNode(null);
  setGraph(new Map(newGraph));

  hover.current = false;
 };

 // Connects two nodes in the pathing graph
 const connectPoints = (key) => {
  const newGraph = graph;
  addToAdjacencyArray(newGraph, key, lastNode);
  addToAdjacencyArray(newGraph, lastNode, key);

  setGraph(new Map(newGraph));
  setLastNode(key);
 };

 // Implements dragging
 const updateNodePosition = (key, coordinates) => {
  const newGraph = graph;
  const val = newGraph.get(key);
  newGraph.set(key, {
   ...val,
   coordinates,
  });

  setGraph(new Map(newGraph));
 };

 // delete and adjacencies
 const deleteAdjacency = (key1, key2) => {
  const newGraph = graph;
  const val1 = newGraph.get(key1);
  const val2 = newGraph.get(key2);

  newGraph.set(key1, {
   ...val1,
   adjacent: val1.adjacent.filter((adjKey) => adjKey !== key2),
  });
  newGraph.set(key2, {
   ...val2,
   adjacent: val2.adjacent.filter((adjKey) => adjKey !== key1),
  });

  setLastLine(null);
  setGraph(new Map(newGraph));
 };

 // Connects key and adjKey in the adjacency array for graphRef
 const addToAdjacencyArray = (graphRef, key, adjKey) => {
  const keyValue = graphRef.get(key);
  graphRef.set(key, {
   ...keyValue,
   adjacent: [...keyValue.adjacent, adjKey],
  });
 };

 // When you click on the map itself
 const onMapClick = (coordinates) => {
  switch (mode) {
   case Mode.Add:
    // If you're not hovering over a part of the graph
    if (!hover.current) addPoint(coordinates);
    break;
   default:
  }
 };

 // When you click on a features on the map
 const onFeatureClick = (key) => {
  switch (mode) {
   case Mode.Add:
    // If there is no last node
    // set selected as last node
    // if there is a last node connect
    // clicked node to the last node
    if (lastNode && !connected(lastNode, key)) {
     connectPoints(key);
    } else {
     setLastNode(key);
     setLastLine(null);
    }
    break;
   case Mode.Edit:
    setLastNode(key);
    setLastLine(null);
    break;
   default:
  }
 };

 // Ensure that the mode changing side effects are executed
 const changeMode = (targetMode) => {
  switch (targetMode) {
   case Mode.Add:
   case Mode.Edit:
    setLastNode(null);
    setLastLine(null);
    break;
   default:
  }

  setMode(targetMode);
 };

 // Returns an arary of Featuers corresponding to graph nodes
 // Recalculate only when graph or lastNode changes
 const renderNodes = React.useCallback(() => {
  return [...graph.keys()].map((key) => (
   <Feature
    coordinates={graph.get(key).coordinates}
    key={key}
    properties={{ selected: key === lastNode }}
    onClick={() => onFeatureClick(key)}
    onMouseEnter={() => {
     hover.current = true;
    }}
    onMouseLeave={() => {
     hover.current = false;
    }}
    draggable={true}
    onDragStart={() => setDragging(true)}
    onDrag={(e) => updateNodePosition(key, [e.lngLat.lng, e.lngLat.lat])}
    onDragEnd={() => setDragging(false)}
   />
  ));
 }, [graph, lastNode]);

 // useCallback ensure this is cached so that it doesn't render
 // unless graph or selected node/line has changed
 const renderLines = React.useCallback(() => {
  let lines = [...graph.keys()]
   // Skip nodes with no adjacencies
   .filter((key) => graph.get(key).adjacent.length > 0)
   // Generate line coordinates and flatten one level
   .flatMap(
    (key) =>
     graph
      .get(key)
      // filter half of the adjacencies so no duplicates
      .adjacent.filter((adj) => adj < key)
      .map((adj) => [adj, key])
    // create lines coordinates
   );

  const lastLineStringified = JSON.stringify(lastLine);

  return (
   <>
    <Layer id="lines" type="line" layout={lineLayout} paint={linePaint}>
     {lines.map((line) => (
      <Feature
       key={line.toString()}
       properties={{
        selected: JSON.stringify(line) === lastLineStringified || (lastNode !== null && mode === Mode.Edit && line.includes(lastNode)),
       }}
       coordinates={[graph.get(line[0]).coordinates, graph.get(line[1]).coordinates]}
      />
     ))}
    </Layer>
    <Layer
     id="linesClick"
     type="line"
     layout={lineLayout}
     paint={{
      "line-color": "#000000",
      "line-width": 12,
      "line-opacity": 0,
     }}
    >
     {lines.map((line) => (
      <Feature
       key={line.toString()}
       coordinates={[graph.get(line[0]).coordinates, graph.get(line[1]).coordinates]}
       onClick={() => {
        if (mode === Mode.Edit) {
         setLastLine(line);
         setLastNode(null);
        }
       }}
      />
     ))}
    </Layer>
   </>
  );
 }, [graph, lastNode, lastLine, mode, onFeatureClick, updateNodePosition, setLastNode, setLastLine]);

 const onMapClickRef = React.useRef(onMapClick);
 onMapClickRef.current = onMapClick;

 // Registers click and mouse move listeners on the map on mount
 React.useEffect(() => {
  map.on("click", (evt) => {
   onMapClickRef.current([evt.lngLat.lng, evt.lngLat.lat]);
  });
  map.on("mousemove", (targetMap, _) => {
   if (hover.current) map.getCanvas().style.cursor = "pointer";
   else if (dragging.current) map.getCanvas().style.cursor = "grabbing";
   else map.getCanvas().style.cursor = "default";
  });
 }, []);

 return (
  <>
   <PathingControls mode={mode} onModeClick={changeMode} deselect={deselect} toggleOpacity={toggleOpacity} opacity={opacity} />
   {renderLines()}
   <Layer id="nodes" type="circle" paint={circleStyles}>
    {renderNodes()}
   </Layer>
   {/* Show popup only in edit more */}
   {mode === Mode.Edit &&
    /* Show delete popup on selection*/
    (lastNode ? (
     <DeletePopup coordinates={graph.get(lastNode).coordinates} onClick={() => deleteNode(lastNode)} />
    ) : lastLine ? (
     <DeletePopup coordinates={midPoint(graph.get(lastLine[0]).coordinates, graph.get(lastLine[1]).coordinates)} onClick={() => deleteAdjacency(lastLine[0], lastLine[1])} />
    ) : null)}
  </>
 );
};

// Find the midpoint between two nodes
const midPoint = (point1, point2) => {
 return [(point1[0] + point2[0]) / 2, (point1[1] + point2[1]) / 2];
};

// Component responsible for feature popup
const DeletePopup = ({ coordinates, onClick }) => {
 return (
  <Popup style={{ position: "absolute", top: 0 }} offset={{ bottom: [0, -20] }} coordinates={coordinates}>
   <DeleteButton id="delete" onClick={() => onClick()}>
    <Trash />
   </DeleteButton>
  </Popup>
 );
};

// Styled Button
const DeleteButton = styled.button`
 border: none;
 padding-top: 4;
 background-color: #fff;
 &:hover {
  color: #ff2f0d;
 }
 cursor: "pointer";
`;

// Styled Icon
const Trash = styled(DeleteOutlineIcon)`
 width: 30px;
 height: 30px;
 font-size: 22px;
 z-index: 100;
`;

export default PathEditor;
