import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "post" method,
 * accepting three arguments (cemetery, type, ids) in the sendRequest function,
 * updating the url to bulk-delete,
 * and providing a body object containing the ids
 */
class BulkDelete extends Request {
 constructor() {
  super("post", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const cemetery = args[0];
  const type = args[1];
  const ids = [...new Set(args[2])];

  this.url = `bulk-delete/${cemetery}/${type}`;
  //return new Promise((resolve) => resolve());
  return this.sendRequestPromise({ ids });
 }
}

export default BulkDelete;
