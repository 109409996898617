import React from "react";
import { Box } from "@mui/material";

import FindOnMap from "./Mapbox/FindOnMap";

/**
 * Wraps FindOnMap component within a material ui box and renders
 * FindOnMap conditionally, based on whether location and coordinates
 * have been provided as props
 */
const MapPicker = ({ location, coordinates, url, lat, lng, updatePosition }) => {
 return (
  <Box
   sx={{
    width: "100%",
    height: "400px",
    maxWidth: { sm: "80%", lg: "100%" },
    overflow: "hidden",
    m: "auto",
   }}
  >
   {location && coordinates && (
    <FindOnMap
     containerStyle={{
      maxWidth: "100%",
      height: "100%",
      position: "relative",
     }}
     location={lat !== 0 && lng !== 0 ? [lng, lat] : location}
     coordinates={coordinates}
     url={url}
     lat={lat}
     lng={lng}
     updatePosition={updatePosition}
    />
   )}
  </Box>
 );
};

export default MapPicker;
