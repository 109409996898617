import axios from "axios";
import Controller from "../Controller.js";

// Axios instance with a base url configured, withCredentials set to true, and credentials set to "include"
const authAxios = axios.create({
 baseURL: Controller.baseUrl,
 withCredentials: true,
 credentials: "include",
});

authAxios.defaults.headers["Cache-Control"] = "no-store";
authAxios.defaults.headers["Pragma"] = "no-cache";
authAxios.defaults.headers["Expires"] = "0";

/**
 * Base request class,
 * Request details are configured via the constructor.
 * resolveRequest and rejectRequest can be overriden to implement custom
 * promise handlers.
 * sendRequest can be overriden to utilise function arguments.
 */
class Request {
 constructor(method, url, withCredentials = true, headers, useBase = true) {
  this.method = method;
  this.url = url;
  this.withCredentials = withCredentials;
  this.headers = headers;
  this.useBase = useBase;
 }
 resolveRequest(result, resolve) {
  resolve(result);
 }
 rejectRequest(error, reject) {
  reject(error);
 }

 sendRequest() {
  return this.sendRequestPromise({});
 }

 sendRequestPromise(data) {
  return new Promise((resolve, reject) => {
   let config = {
    method: this.method,
    // url: (this.useBase ? Controller.baseUrl + "/" + this.url : this.url) + "?timestamp=" + Date.now(),
    url: this.useBase ? Controller.baseUrl + "/" + this.url : this.url,
    data: this.method === "get" ? undefined : data,
    headers: this.headers ? this.headers : { Authorization: Controller.idToken },
    withCredentials: this.withCredentials,
    credentials: "include",
   };
   // if (this.isFormData) {
   //   config.headers[
   //     "content-type"
   //   ] = `multipart/form-data; boundary=${data._boundary}`;
   // }

   authAxios(config)
    .then((result) => {
     this.resolveRequest(result, resolve);
    })
    .catch((error) => {
     this.rejectRequest(error, reject);
    });
  });
 }
}

export default Request;
