import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Paper, FormControl, FormGroup, Divider } from "@mui/material";

import { PageHeader, PageContainer, PageContent, FormHeader } from "../components/styled-components";

import { Context } from "../SDK/context";
import ImageUpload from "../components/ImageUpload";
import GalleryUpload from "../components/GalleryUpload";

import FormDetails from "../components/form-details";

import FOV from "../SDK/src/main";

import { handleMedia, getNewItem } from "../utilities";

import { getDefaultForm } from "../assets/default-forms";

const defaultForm = getDefaultForm("organization");

/**
 * Organization page containing organization form
 */
const Organization = ({}) => {
 const navigate = useNavigate();
 const { Organization } = useParams();
 const { state, setLoading, setLoadingRecord } = React.useContext(Context);
 const [formState, setFormState] = React.useState(defaultForm);
 const [newMedia, setNewMedia] = React.useState({});
 const [media, setMedia] = React.useState([]);

 /**
  * Attempts to get organization if the organization parameter or selected site changes.
  * Navigates home if the organization parameter is undefined.
  */
 React.useEffect(() => {
  if (!Organization) {
   goHome();
  } else {
   getOrganization();
  }
 }, [Organization, state.selectedSite]);

 const goHome = () => {
  navigate(`/SiteManager`);
 };

 /**
  * Attempts to fetch an organization.
  * Sets formState to the fetched organization on success.
  * Navigates home on error.
  * Sets loadingRecord to true for the duration of the fetch.
  */
 const getOrganization = async () => {
  setLoadingRecord(true);
  try {
   const result = await FOV.api.getOrganization(Organization);
   if (result.data.SK) {
    setFormState({ ...formState, ...result.data, Id: result.data.SK });
   } else {
    goHome();
   }
  } catch (err) {
   goHome();
   console.error(err);
  }
  setLoadingRecord(false);
 };

 /**
  * Attempts to update an organization given the submitted form values.
  * Sets form state to the newly updated organization and resets the newMedia object on success.
  * Regenerates media object from new organization.
  * Sets loading state to true for the duration of the update.
  * @param {*} event form submit event
  */
 const handleSubmit = async (event) => {
  event.preventDefault();
  setLoading(true);
  try {
   const mapping = await handleMedia(newMedia, formState, formState.SK, "Organization", formState.SK);
   const newOrganization = getNewItem(mapping, formState, media);
   await FOV.api.postOrganization(newOrganization);
   setFormState(newOrganization);
   setNewMedia({});
   setMedia(newOrganization.Media.split(";"));
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
  setLoading(false);
 };

 return (
  <PageContainer className="scrollbar-hidden">
   <PageHeader>Organization</PageHeader>
   <PageContent>
    <form
     id="myForm"
     onSubmit={handleSubmit}
     style={{
      height: "100%",
      display: "block",
      marginTop: "30px",
      marginBottom: "30px",
     }}
    >
     <Paper
      elevation={3}
      sx={{
       display: "flex",
       maxWidth: "900px",
       margin: "auto",
       background: (theme) => theme.palette.form.main,
      }}
     >
      <FormControl sx={{ flexGrow: 1 }}>
       <FormGroup>
        <FormHeader>Organization Logo</FormHeader>
        <ImageUpload fieldName="Logo" formState={formState} newMedia={newMedia} setNewMedia={setNewMedia} />
        <Divider />
        <FormHeader>Organization Details</FormHeader>
        <FormDetails type="organization" setFormState={setFormState} formState={formState} />
        <Divider />
        <FormHeader>Organization Gallery</FormHeader>
        <Paper
         square
         sx={{
          m: "10px",
          padding: "10px",
          maxWidth: "calc(100% - 20px)",
          background: (theme) => theme.palette.tertiary.main,
         }}
        >
         <GalleryUpload formState={formState} newMedia={newMedia} setNewMedia={setNewMedia} media={media} setMedia={setMedia} />
        </Paper>
        <Button variant="contained" sx={{ background: (theme) => theme.palette.primary.main }} type="submit">
         Submit
        </Button>
       </FormGroup>
      </FormControl>
     </Paper>
    </form>
   </PageContent>
  </PageContainer>
 );
};

export default Organization;
