import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Context } from "../SDK/context";

/**
 * Utility component to navigate to a selected site when the
 * global cemetery list changes
 */
const AutoLoadSite = () => {
 const navigate = useNavigate();
 const { cemetery } = useParams();
 const { state, setSelectedSite } = React.useContext(Context);

 React.useEffect(() => {
  if (!state.cemeteryItems.length) {
   return;
  }
  let cemeteryItemExists = false;
  for (let item of state.cemeteryItems) {
   if (item.SK == cemetery) {
    setSelectedSite(cemetery);
    cemeteryItemExists = true;
    break;
   }
  }
  if (!cemeteryItemExists) {
   navigate("/SiteManager");
  }
 }, [state.cemeteryItems]);
 return <div></div>;
};

export default AutoLoadSite;
