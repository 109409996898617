import * as React from "react";

import { Box, TextField } from "@mui/material";
import { handleTextChange, handleFloatChange, handleNumberChange } from "../../utilities";

/**
 * Form details component for a monument item
 * @param {*} param0 object containing formState and setFormState function
 */
const RecordDetails = ({ setFormState, formState }) => {
 return (
  <Box
   sx={{
    display: "flex",
    flexDirection: "column",
    m: 1,
   }}
  >
   <TextField
    required
    label="Name (Enter the name of the record)"
    variant="filled"
    name="Name"
    value={formState.Name}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Country (Enter the country where the record is located)"
    variant="filled"
    name="Country"
    value={formState.Country}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Locality (Enter the locality of the record)"
    variant="filled"
    name="Locality"
    value={formState.Locality}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Monument Ref Location (Enter the location)"
    variant="filled"
    name="MonumentRefLocation"
    value={formState.MonumentRefLocation}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="State / Province (Enter the state or province where the record is located)"
    variant="filled"
    name="StateProvince"
    value={formState.StateProvince}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Accessibility (Enter a description of the accessibility of the record)"
    variant="filled"
    name="Accessibility"
    multiline
    maxRows={10}
    value={formState.Accessibility}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Owner (Enter the name of the owner of the record)"
    variant="filled"
    name="Owner"
    value={formState.Owner}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
    <TextField
     sx={{ flexGrow: 1 }}
     label="Dedicated By (Enter the name of the individual who dedicated this record)"
     variant="filled"
     name="DedicatedBy"
     value={formState.DedicatedBy}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
    />
    <TextField
     sx={{ flexGrow: 1 }}
     label="Dedicated Date (Enter the date for when record was dedicated)"
     variant="filled"
     name="DedicatedDate"
     value={formState.DedicatedDate}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
     type="date"
     InputLabelProps={{ shrink: true }}
    />
   </Box>
   <TextField
    label="Conflicts (Enter the relevant conflicts for this record separated by commas e.g. Second World War, Korean War, NATO Peacekeeping)"
    variant="filled"
    name="Conflicts"
    value={formState.Conflicts}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Casualties (Enter the number of casualties for this record)"
    variant="filled"
    name="Casualties"
    type="number"
    value={formState.Casualties.toString()}
    onChange={(e) => {
     handleNumberChange(e, setFormState, formState);
    }}
    inputProps={{
     min: 0,
    }}
   />
   <TextField
    label="Artist (Enter the name of the artist for this record)"
    variant="filled"
    name="Artist"
    value={formState.Artist}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Architect (Enter the name of the architect for this record)"
    variant="filled"
    name="Architect"
    value={formState.Architect}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Description (Enter a description)"
    variant="filled"
    name="Description"
    multiline
    maxRows={10}
    value={formState.Description}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Weblinks (Enter colon separated weblinks related to this record separated by semicolons)"
    variant="filled"
    name="Weblinks"
    value={formState.Weblinks}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Labels (Enter labels for this record separated by semicolons)"
    variant="filled"
    name="Labels"
    value={formState.Labels}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Aux (Enter any additional information here)"
    variant="filled"
    name="Aux"
    multiline
    maxRows={10}
    value={formState.Aux}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
    <TextField
     required
     sx={{ flexGrow: 1 }}
     label="Latitude (Enter the Latitude of the record, or click on the below map to place the record on the map)"
     variant="filled"
     name="Lat"
     type="number"
     value={formState.Lat.toString()}
     onChange={(e) => {
      handleFloatChange(e, setFormState, formState);
     }}
     inputProps={{
      min: -90,
      max: 90,
      step: "any",
     }}
    />
    <TextField
     required
     sx={{ flexGrow: 1 }}
     label="Longitude (Enter the Longitude of the record, or click on the below map to place the record on the map)"
     variant="filled"
     name="Lng"
     type="number"
     value={formState.Lng.toString()}
     onChange={(e) => {
      handleFloatChange(e, setFormState, formState);
     }}
     inputProps={{
      min: -180,
      max: 180,
      step: "any",
     }}
    />
   </Box>
  </Box>
 );
};

export default RecordDetails;
