import React from "react";
import { Layer, Source, MapContext, Feature } from "react-mapbox-gl";

import Mapbox from "./Mapbox";

/**
 * Mapbox map component with a MutableImageLayer component
 * configurable via props
 */
const MapWithImage = ({ coordinates, opacity = 1, url }) => {
 const IMAGE_SOURCE = {
  type: "image",
  url,
  coordinates,
 };
 const imagePaint = {
  "raster-opacity": opacity,
 };

 return (
  <Mapbox
   //eslint-disable-next-line
   style="mapbox://styles/mapbox/dark-v10"
   containerStyle={{
    height: "80vh",
    width: "80vw",
   }}
  >
   <MutableImageLayer id="nyan" source={IMAGE_SOURCE} paint={imagePaint} />
  </Mapbox>
 );
};

/**
 * Wraps MutableImageLayer to provide opacity and url control via props
 */
export const ImageLayer = ({ coordinates, opacity = 0.4, url }) => {
 const imagePaint = {
  "raster-opacity": opacity,
 };

 return url ? <MutableImageLayer id="nyan" url={`${url}?cacheblock=true`} coordinates={coordinates} paint={imagePaint} /> : null;
};

/**
 * Generates a Source and Layer component, configured via props,
 * for use with a Mapbox map component
 */
const MutableImageLayer = ({ id, url, coordinates, paint }) => {
 const map = React.useContext(MapContext);

 React.useEffect(() => {
  // Update coordinates using the map context api
  const imageSource = map.getSource(id);
  if (imageSource) {
   imageSource.setCoordinates(coordinates);
  }
  map.moveLayer(`${id}-layer`, "lines");
 }, [map, url, coordinates, id]);

 return (
  <>
   <Source
    id={id}
    tileJsonSource={{
     type: "image",
     url,
     coordinates,
    }}
   />
   <Layer id={`${id}-layer`} sourceId={id} type="raster" paint={paint} />
  </>
 );
};

/**
 * Provides map image with a repositionable feature, initially positioned via props,
 * which calls updatePosition when moved.
 * For use with a Mapbox map component
 */
export const PickerImageLayer = ({ id, url, coordinates, lat, lng, updatePosition }) => {
 const map = React.useContext(MapContext);
 const [update, setUpdate] = React.useState();

 React.useEffect(() => {
  map.on("click", (evt) => {
   updatePosition({ Lat: evt.lngLat.lat, Lng: evt.lngLat.lng });
  });
 }, []);

 React.useEffect(() => {
  setUpdate(Math.random());
 }, [lat, lng]);

 React.useEffect(() => {
  // Update coordinates using the map context api
  const imageSource = map.getSource(id);
  if (imageSource) {
   imageSource.setCoordinates(coordinates);
  }
 }, [map, url, coordinates, id]);

 return (
  <>
   <Source
    id={id}
    tileJsonSource={{
     type: "image",
     url: `${url}?cacheblock=true`,
     coordinates,
    }}
   />
   <Layer
    id={`${id}-layer`}
    sourceId={id}
    type="raster"
    paint={{
     "raster-opacity": 1,
    }}
   />
   <Layer
    type="symbol"
    id="marker"
    layout={{
     "icon-image": "hospital-15",
     "icon-size": 2,
    }}
   >
    <Feature coordinates={[lng, lat]} />
   </Layer>
  </>
 );
};

export default MapWithImage;
