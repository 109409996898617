import { useState, useContext, useEffect } from "react";
import { Button, Box, Paper, Typography } from "@mui/material";

import AutoLoadSite from "../components/AutoLoadSite";
import { FooterButtons, PageContainerNoScroll } from "../components/styled-components";

import PathEditor, { mapToArrayObj } from "../components/Mapbox/PathEditor";

import { handleMedia, getNewItem } from "../utilities";
import FOV from "../SDK/src/main";
import { Context } from "../SDK/context";

import { getDefaultForm } from "../assets/default-forms";

import SaveIcon from "@mui/icons-material/Save";

const emptyPoint = [0, 0];

async function getPathFile(fileUrl) {
 return await (await fetch(fileUrl)).json();
}

const defaultForm = getDefaultForm("cemetery");

/**
 * Pathing page, showing map and path editor or a message indicating that the
 * site coordinates are invalid.
 */
const Pathing = () => {
 const { state, setLoading, setCemeteryItems } = useContext(Context);
 const [pathFile, setPathFile] = useState(new Map());
 const [formState, setFormState] = useState(defaultForm);
 const [newMedia, setNewMedia] = useState({});
 const [media, setMedia] = useState([]);
 const [lastNode, setLastNode] = useState();
 const [imageCoords, setImageCoords] = useState();
 const [invalidCoords, setInvalidCoords] = useState(false);
 const [imageUrl, setImageUrl] = useState("");
 const [jump, setJump] = useState();
 const [imageOpacity, setImageOpacity] = useState(1);

 /**
  * On selected site change, combines formState with site item corresponding to selected site.
  * Initialises media array with site item.
  * Checks that the cemetery map coordinates are valid and sets invalidCoords to true if not.
  * Otherwise, sets imageCoords state and jumps to the centre of the coordinates on the map.
  * Sets the map overlay image source to the cemetery item map.
  * Attempts to fetch and set the cemetery pathfile if it exists.
  */
 useEffect(() => {
  if (!state.selectedSite) {
   return;
  }
  let cemetery;
  for (let item of state.cemeteryItems) {
   if (item.SK == state.selectedSite) {
    cemetery = item;
    setFormState({ ...formState, ...cemetery });
    if (cemetery.Media) {
     setMedia(cemetery.Media.split(";"));
    } else {
     setMedia([]);
    }
    break;
   }
  }
  if (
   typeof cemetery.MapALat !== "undefined" &&
   typeof cemetery.MapBLat !== "undefined" &&
   typeof cemetery.MapCLat !== "undefined" &&
   typeof cemetery.MapDLat !== "undefined" &&
   typeof cemetery.MapALng !== "undefined" &&
   typeof cemetery.MapBLng !== "undefined" &&
   typeof cemetery.MapCLng !== "undefined" &&
   typeof cemetery.MapDLng !== "undefined" &&
   cemetery.MapALat != cemetery.MapDLat &&
   cemetery.MapCLat != cemetery.MapBLat &&
   cemetery.MapALng != cemetery.MapBLng &&
   cemetery.MapCLng != cemetery.MapDLng
  ) {
   setImageCoords([
    [cemetery.MapALng, cemetery.MapALat],
    [cemetery.MapBLng, cemetery.MapBLat],
    [cemetery.MapCLng, cemetery.MapCLat],
    [cemetery.MapDLng, cemetery.MapDLat],
   ]);
   setJump([(cemetery.MapALng + cemetery.MapBLng + cemetery.MapCLng + cemetery.MapDLng) / 4, (cemetery.MapALat + cemetery.MapBLat + cemetery.MapCLat + cemetery.MapDLat) / 4]);
  } else {
   setInvalidCoords(true);
  }
  setImageUrl(cemetery.Map);
  if (cemetery.PathFile) {
   getPathFile(cemetery.PathFile).then((res) => {
    setPathFile(new Map(res.map.map((entry) => [entry.key, entry.value])));
   });
  }
 }, [state.selectedSite]);

 /**
  * Attempts to update the cemetery with a new cemetery item.
  */
 const updateCemetery = (newCemeteryItem) => {
  return FOV.api.updateCemetery(state.selectedSite, newCemeteryItem);
 };

 /**
  * Converts path file to an array of key,value objects, then stringifies an object with a single key
  * "map" and value being the array of key,value objects.
  * Converts this to a blob, then attempts to update the cemetery with this new path file, making use
  * of the handleMedia and getNewItem utility functions.
  * Finally, resets form state, cemetery items list, and new media state with the newly updated cemetery.
  * Sets loading to true for the duration of the process.
  */
 const saveJson = async (event) => {
  event.preventDefault();
  setLoading(true);

  const mapToArray = mapToArrayObj(pathFile);
  const jsonStr = JSON.stringify({ map: mapToArray });
  newMedia.PathFile = new Blob([jsonStr], { type: "text/json" });
  try {
   const mapping = await handleMedia(newMedia, formState, state.selectedSite, "cemetery", formState.SK);
   const newCemetery = getNewItem(mapping, formState, media);
   await updateCemetery(newCemetery);
   setFormState(newCemetery);
   setCemeteryItems(state.cemeteryItems.map((item) => (item.SK === newCemetery.SK ? newCemetery : item)));
   setNewMedia({});
  } catch (err) {
   console.error(err);
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
  setLoading(false);
 };

 return (
  <PageContainerNoScroll style={{ overflowY: "hidden !important" }} className="scrollbar-hidden">
   <AutoLoadSite />
   <Box sx={{ height: "100%", background: "black", position: "relative" }}>
    {invalidCoords && (
     <Typography
      fontSize="large"
      sx={{
       position: "absolute",
       width: "100%",
       height: "100%",
       display: "flex",
       flexDirection: "column",
       justifyContent: "center",
       alignItems: "center",
       color: "white",
       textAlign: "center",
      }}
     >
      Unable to process map coordinates.
      <br />
      Please check the Lat, Lng coordinates for the corners of your map.
      <br />
      <br />
      <div style={{ textAlign: "left" }}>
       <li>A => Top Left</li>
       <li>B => Top Right</li>
       <li>C => Bottom Right</li>
       <li>D => Bottom Left</li>
      </div>
     </Typography>
    )}

    {jump && imageCoords && (
     <PathEditor
      containerStyle={{
       position: "relative",
       height: "100%",
      }}
      pathFile={pathFile}
      setPathFile={setPathFile}
      lastNode={lastNode}
      setLastNode={setLastNode}
      map={pathFile}
      location={jump}
      imageCoord={imageCoords}
      imageUrl={imageUrl}
      imageOpacity={imageOpacity}
      setImageOpacity={setImageOpacity}
     />
    )}
   </Box>
   <FooterButtons sx={{}}>
    <Button size="large" variant="contained" color="primary" onClick={saveJson}>
     Save <SaveIcon sx={{ ml: 1 }} />
    </Button>
   </FooterButtons>
  </PageContainerNoScroll>
 );
};

export default Pathing;
