import Request from "../utility/Request.js";
import { convertAndCompress } from "../utility/ImageConversion.js";

/**
 * Extends the Request class, specifying the "put" method,
 * accepting four arguments (file, type, url, shouldCompress) in the sendRequest function,
 * updating the url to the provided url,
 * generating a compressed newFile value from the file parameter,
 * and providing the generated newFile value as the request body
 */
class UploadMedia extends Request {
 constructor() {
  super("put", ``, false, {}, false);
 }
 async sendRequest() {
  const args = arguments[0];
  let file = args[0];
  let type = args[1];
  const url = args[2];
  let shouldCompress = true;
  if (args.length > 3) {
   shouldCompress = args[3];
  }

  let newFile;

  if (!["TourFile", "PathFile"].includes(type)) {
   try {
    newFile = await convertAndCompress(file, 5000);
   } catch (err) {
    console.error(err);
    return new Promise((resolve, reject) => {
     reject(err);
    });
   }
  } else {
   newFile = file;
  }

  this.url = url;
  return this.sendRequestPromise(newFile);
 }
}

export default UploadMedia;
