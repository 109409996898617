import Refresher from "./Refresher.js";

/**
 * Simplifies calling a class instance's sendRequest method bound to itself
 */
function wrap(Cls) {
 let clsInstance = new Cls();
 return clsInstance.sendRequest.bind(clsInstance);
}

/**
 * Simplifies calling a class instance's sendRequest method bound to itself while also
 * wrapping that function in a Refresher handleRefresh function.
 */
function wrapRefresh(Cls) {
 let clsInstance = new Cls();
 let clsInstanceRefresher = new Refresher(clsInstance.sendRequest.bind(clsInstance));
 return clsInstanceRefresher.handleRefresh.bind(clsInstanceRefresher);
}

export { wrap, wrapRefresh };
