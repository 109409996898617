import { Dialog, List, ListItem, Typography } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CallIcon from "@mui/icons-material/Call";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import React from "react";

/**
 * Additional settings menu dialog
 */
const SettingsDialog = (props) => {
 const { onClose, open, logoutUser } = props;

 const handleClose = () => {
  onClose();
 };

 return (
  <Dialog onClose={handleClose} open={open}>
   <List sx={{ p: 0 }}>
    <ListItem button component="a" href="https://memoryanchor.com/account">
     <Typography
      textAlign="center"
      color="primary"
      fontSize="large"
      sx={{
       textDecoration: "none",
       display: "flex",
       alignItems: "center",
      }}
     >
      <AccountBoxIcon sx={{ mr: 1 }} />
      Account
     </Typography>
    </ListItem>
    <ListItem button component="a" href="https://memoryanchor.com/cloudcontact">
     <Typography
      textAlign="center"
      color="primary"
      fontSize="large"
      sx={{
       textDecoration: "none",
       display: "flex",
       alignItems: "center",
      }}
     >
      <CallIcon sx={{ mr: 1 }} />
      Contact
     </Typography>
    </ListItem>
    <ListItem button component="a" href="https://memoryanchor.com/help">
     <Typography
      textAlign="center"
      color="primary"
      fontSize="large"
      sx={{
       textDecoration: "none",
       display: "flex",
       alignItems: "center",
      }}
     >
      <HelpIcon sx={{ mr: 1 }} />
      Help
     </Typography>
    </ListItem>
    <ListItem button>
     <Typography
      textAlign="center"
      color="primary"
      onClick={() => {
       onClose();
       logoutUser();
      }}
      fontSize="large"
      sx={{
       display: "flex",
       alignItems: "center",
      }}
     >
      <LogoutIcon sx={{ mr: 1 }} />
      Logout
     </Typography>
    </ListItem>
   </List>
  </Dialog>
 );
};

export default SettingsDialog;
