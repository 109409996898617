import * as React from "react";

import { useParams, useNavigate } from "react-router-dom";
import { Button, Box, Paper } from "@mui/material";
import AutoLoadSite from "../components/AutoLoadSite";
import { FooterButtons, PageContainer, PageContent, FormHeader } from "../components/styled-components";

import { getDefaultForm } from "../assets/default-forms";
import { Context } from "../SDK/context";

import FOV from "../SDK/src/main";
import { getNewItem, handleMedia } from "../utilities";
import TourForm from "../components/Tour/TourForm";
import TourSearch from "../components/Tour/TourSearch";
import TourPoints from "../components/Tour/TourPoints";
import ToursPreview from "../components/Mapbox/ToursPreview";

import InvalidPathDialog from "../components/Tour/InvalidPathDialog";

import SaveIcon from "@mui/icons-material/Save";

const defaultForm = getDefaultForm("tour");

/**
 * Tour page containing the tour preview, tour search, and tour points components.
 */
const Tour = () => {
 const navigate = useNavigate();
 const { Tour } = useParams();
 const { state, setLoading, setLoadingRecord } = React.useContext(Context);
 const [formState, setFormState] = React.useState(defaultForm);
 const [newMedia, setNewMedia] = React.useState({});
 const [media, setMedia] = React.useState([]);
 const [points, setPoints] = React.useState([]);
 const [pathFile, setPathFile] = React.useState({});
 const [tourFile, setTourFile] = React.useState([]);
 const [imageUrl, setImageUrl] = React.useState("");
 const [imageCoords, setImageCoords] = React.useState([
  [0, 0],
  [1, 0],
  [1, -1],
  [0, -1],
 ]);
 const [jump, setJump] = React.useState();
 const [invalidDialogOpen, setInvalidDialogOpen] = React.useState(false);
 const [invalidIssue, setInvalidIssue] = React.useState(false);
 const submitButton = React.useRef();

 /**
  * When the tour URL parameter or selected site changes, fetches tour and
  * updates tour state, as well as checking whether the selected site's map
  * coordinates are valid.
  * If the coordinates are valid, sets image coords state and jumps to the centre of the site
  * on the map.
  * Sets the image url state to the cemetery Map value, and fetches the cemetery's path file.
  * If the cemetery's path file exists, updates th epath file state with it. Otherwise,
  * Opens the invalid path dialog and sets the invalid issue state to "missing".
  */
 React.useEffect(async () => {
  if (Tour && state.selectedSite) {
   getTour();
   let cemetery;
   for (let item of state.cemeteryItems) {
    if (item.SK == state.selectedSite) {
     cemetery = item;
     break;
    }
   }
   if (
    typeof cemetery.MapALat !== "undefined" &&
    typeof cemetery.MapBLat !== "undefined" &&
    typeof cemetery.MapCLat !== "undefined" &&
    typeof cemetery.MapDLat !== "undefined" &&
    typeof cemetery.MapALng !== "undefined" &&
    typeof cemetery.MapBLng !== "undefined" &&
    typeof cemetery.MapCLng !== "undefined" &&
    typeof cemetery.MapDLng !== "undefined" &&
    cemetery.MapALat != cemetery.MapDLat &&
    cemetery.MapCLat != cemetery.MapBLat &&
    cemetery.MapALng != cemetery.MapBLng &&
    cemetery.MapCLng != cemetery.MapDLng
   ) {
    setImageCoords([
     [cemetery.MapALng, cemetery.MapALat],
     [cemetery.MapBLng, cemetery.MapBLat],
     [cemetery.MapCLng, cemetery.MapCLat],
     [cemetery.MapDLng, cemetery.MapDLat],
    ]);
    setJump([(cemetery.MapALng + cemetery.MapBLng + cemetery.MapCLng + cemetery.MapDLng) / 4, (cemetery.MapALat + cemetery.MapBLat + cemetery.MapCLat + cemetery.MapDLat) / 4]);
   }
   setImageUrl(cemetery.Map);
   if (cemetery.PathFile) {
    getJsonFile(cemetery.PathFile).then((res) => {
     setPathFile(convertPaths(res.map));
    });
   } else {
    setInvalidIssue("missing");
    setInvalidDialogOpen(true);
   }
  }
 }, [Tour, state.selectedSite]);

 /**
  * When the points array state or pathFile state changes, calculates a new tour
  * and updates the tour file if successful.
  * If the points array contains no points but the pathFile contains an adjacencyList,
  * A point is created at the first node in the adjacency list before calculating the tour
  * (this is so that the pathFile connectivity is also checked when there are no points).
  * If creating the tour is unsuccessful, sets the invalid issue state to "unconnected" and
  * opens the invalid path dialog.
  */
 React.useEffect(() => {
  if (points.length && pathFile.adjacencyList) {
   const pgg = new FOV.ProjectedGraphGenerator(pathFile.adjacencyList, points);
   const tour = pgg.calcTour();
   if (tour) {
    setTourFile(tour);
   } else {
    setInvalidIssue("unconnected");
    setInvalidDialogOpen(true);
   }
  } else if (pathFile.adjacencyList) {
   const al = pathFile.adjacencyList;
   const point = { lat: al[0].lat, lng: al[0].lng };
   const pgg = new FOV.ProjectedGraphGenerator(pathFile.adjacencyList, [point, point]);
   const tour = pgg.calcTour();
   if (tour) {
    setTourFile([]);
   } else {
    setInvalidIssue("unconnected");
    setInvalidDialogOpen(true);
   }
  }
 }, [points, pathFile]);

 /**
  * Converts an array of tour paths from an external path file to an object compatable with
  * the a ProjectedGraphGenerator.
  */
 function convertPaths(paths) {
  let newPaths = {
   adjacencyList: [],
  };
  let mapping = {};

  for (let i = 0; i < paths.length; ++i) {
   mapping[paths[i].key] = i;
  }

  for (let i = 0; i < paths.length; ++i) {
   let newNode = {
    index: i,
    lat: paths[i].value.coordinates[1],
    lng: paths[i].value.coordinates[0],
    neighbours: [],
   };
   for (let adjacent of paths[i].value.adjacent) {
    newNode.neighbours.push(mapping[adjacent]);
   }
   newPaths.adjacencyList.push(newNode);
  }
  return newPaths;
 }

 /**
  * Appends a record object to the points array state.
  * Trimming the record to only the necessary fields for the tour points component.
  */
 function addPoint(record) {
  if (Object.keys(record).length) {
   setPoints([
    ...points,
    {
     lng: record.Lng,
     lat: record.Lat,
     name: record.Name,
     forename: record.Forename,
     surname: record.Surname,
     type: record.type,
     id: record.SK,
     photo: record.Photo,
     description: record.Description,
    },
   ]);
  }
 }

 /**
  * Attempts to fetch a file from a provided url and return it, converted
  * to json.
  */
 async function getJsonFile(fileUrl) {
  return await (await fetch(fileUrl)).json();
 }

 /**
  * Attempts to fetch a tour record and updates tour form state if successful.
  * Otherwise, clears the Tour url parameter.
  * If the tour record contains a TourFile url source property, Attempts to fetch the json
  * from the url and sets the tour file state to the result if successful.
  * Fetches all of the records from the tour file and generates a list of tour points from
  * them, trimmed to only the necessary fields for the tour points component. Then sets the
  * points state to this generated list.
  * Sets loading record state to true for the duration of the process.
  */
 const getTour = async () => {
  setLoadingRecord(true);
  try {
   const result = await FOV.api.getRecord(state.selectedSite, "tour", Tour);
   if (result.data.SK) {
    setFormState({
     ...formState,
     ...result.data,
     Id: result.data.SK.split("_")[1],
    });
    if (result.data.TourFile) {
     getJsonFile(result.data.TourFile).then((obj) => {
      setTourFile(obj);
      const ids = obj.filter((node) => node.id).map((node) => node.id);
      const poiIds = ids.filter((id) => id.split("_")[0] === "poi").map((id) => id.split("_")[1]);
      const monumentIds = ids.filter((id) => id.split("_")[0] === "monument").map((id) => id.split("_")[1]);
      const burialIds = ids.filter((id) => id.split("_")[0] === "burial").map((id) => id.split("_")[1]);

      let bulkPois = [];
      let bulkMonuments = [];
      let bulkBurials = [];

      let promises = [];
      if (poiIds.length) {
       promises.push(
        new Promise((resolve, reject) => {
         FOV.api
          .bulkGet(state.selectedSite, "poi", poiIds)
          .then((result) => {
           bulkPois = result.data.Responses[Object.keys(result.data.Responses)[0]];
           resolve();
          })
          .catch(reject);
        })
       );
      }
      if (monumentIds.length) {
       promises.push(
        new Promise((resolve, reject) => {
         FOV.api
          .bulkGet(state.selectedSite, "monument", monumentIds)
          .then((result) => {
           bulkMonuments = result.data.Responses[Object.keys(result.data.Responses)[0]];
           resolve();
          })
          .catch(reject);
        })
       );
      }
      if (burialIds.length) {
       promises.push(
        new Promise((resolve, reject) => {
         FOV.api
          .bulkGet(state.selectedSite, "burial", burialIds)
          .then((result) => {
           bulkBurials = result.data.Responses[Object.keys(result.data.Responses)[0]];
           resolve();
          })
          .catch(reject);
        })
       );
      }

      Promise.all(promises)
       .then((values) => {
        let newPoints = [];
        for (let node of obj) {
         if (node.id) {
          let point = {};
          switch (node.id.split("_")[0]) {
           case "burial":
            point = bulkBurials.filter((burial) => burial.SK === node.id)[0];
            break;
           case "monument":
            point = bulkMonuments.filter((monument) => monument.SK === node.id)[0];
            break;
           case "poi":
            point = bulkPois.filter((poi) => poi.SK === node.id)[0];
            break;
           default:
            break;
          }
          newPoints.push({
           lng: point.Lng,
           lat: point.Lat,
           name: point.Name,
           forename: point.Forename,
           surname: point.Surname,
           type: point.SK.split("_")[0],
           id: point.SK,
           photo: point.Photo,
           description: point.Description,
          });
         }
        }

        setPoints(newPoints);
       })
       .catch(console.error);
     });
    }
   } else {
    navigate(`/Tours/${state.selectedSite}`);
   }
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
  setLoadingRecord(false);
 };

 /**
  * Creates a blob from the tourFile state, then attempts to upsert a tour record, making use of
  * the handleMedia and getNewItem utility functions.
  * Updates form state to the new tour record, resets new media state to an empty object, and
  * sets media state to the new tour's media list on success.
  * Sets loading state to true for the duration of the process.
  * @param {*} e tour form submit event
  */
 const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  newMedia.TourFile = new Blob([JSON.stringify(tourFile)], {
   type: "text/json",
  });
  try {
   const mapping = await handleMedia(newMedia, formState, state.selectedSite, "tour", formState.SK);
   const newTour = getNewItem(mapping, formState, media);
   await postTour(newTour);
   setFormState(newTour);
   setNewMedia({});
   setMedia(newTour.Media.split(";"));
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
  setLoading(false);
 };

 /**
  * Attempts to upsert a tour record to the database.
  */
 const postTour = (newItem) => {
  return FOV.api.postRecord(state.selectedSite, newItem, "tour");
 };

 return (
  <PageContainer className="scrollbar-hidden">
   <AutoLoadSite />
   <InvalidPathDialog open={invalidDialogOpen} issue={invalidIssue} />
   <PageContent>
    <Box
     style={{
      display: "flex",
      flexGrow: 1,
      height: "100%",
      minHeight: "100%",
     }}
    >
     <Box
      sx={{
       display: "flex",
       minWidth: "100%",
       height: "100%",
       minHeight: "100%",
       flexGrow: 1,
       display: "flex",
       flexGrow: 1,
       width: "100%",
       minHeight: "600px",
       flexWrap: "wrap",
      }}
     >
      <Box
       sx={{
        maxWidth: "80%",
        ml: { xs: "auto", xl: 1 },
        mr: { xs: "auto", xl: 1 },
       }}
      >
       <FormHeader>Tour Preview</FormHeader>
       <Paper
        elevation={2}
        sx={{
         width: "700px",
         height: "700px",
         maxWidth: "100%",
         position: "relative",
         overflow: "hidden",

         background: "black",
        }}
       >
        {pathFile.adjacencyList && jump && (
         <ToursPreview
          tour={tourFile}
          containerStyle={{
           height: "100%",
          }}
          location={jump}
          imageCoord={imageCoords}
          imageUrl={imageUrl}
          imageOpacity={1}
          style={{ margin: 0, padding: 0 }}
         />
        )}
       </Paper>
      </Box>
      <Box
       sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        flexGrow: 1,
        width: "100%",
        maxWidth: "500px",
       }}
      >
       <TourSearch setResult={addPoint} />
       <TourPoints points={points} setPoints={setPoints} />
      </Box>
      <Box sx={{ p: 1, width: "100%", maxWidth: "440px" }}>
       <TourForm formState={formState} setFormState={setFormState} media={media} setMedia={setMedia} newMedia={newMedia} setNewMedia={setNewMedia} />
      </Box>
     </Box>
     <Button ref={submitButton} sx={{ display: "none" }} type="submit" onClick={handleSubmit}></Button>
    </Box>
   </PageContent>
   <FooterButtons>
    <Button
     size="large"
     variant="contained"
     color="primary"
     onClick={() => {
      submitButton.current.click();
     }}
    >
     Save <SaveIcon sx={{ ml: 1 }} />
    </Button>
   </FooterButtons>
  </PageContainer>
 );
};

export default Tour;
