import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Backdrop, Button, TextField, Paper, Box, Divider, CircularProgress, BackdropRoot } from "@mui/material";

import AutoLoadSite from "../components/AutoLoadSite";
import { PageHeader, FooterButtons, PageContainer, PageContent, FormHeader } from "../components/styled-components";

import Fuse from "fuse.js";
import { Context } from "../SDK/context";

import FOV from "../SDK/src/main";
import NewOrganizationDialog from "../components/Organization/NewOrganizationDialog";
import OrganizationCard from "../components/Organization/OrganizationCard";
import DeleteOrganizationDialog from "../components/Organization/DeleteOrganizationDialog";

/**
 * Attempts to fetch all organization items
 * @returns sorted list of organizations items
 */
async function getAllOrganizations() {
 let gotAll = false;
 let startKey = undefined;
 let allOrganizations = [];
 while (!gotAll) {
  try {
   const result = await FOV.api.getOrganizations(startKey);
   allOrganizations.push(...result.data.Items);
   if (result.data.LastEvaluatedKey) {
    startKey = result.data.LastEvaluatedKey;
   } else {
    gotAll = true;
   }
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
   gotAll = true;
  }
 }
 return allOrganizations.sort((a, b) => a.Name.localeCompare(b.Name));
}

/**
 * Organizations page containing a searchable list of organizations and a list of organizations that are assigned
 * to the selected cemetery.
 * Provides UI for adding/editing/deleting/assigning/unassigning organizations.
 */
const Organizations = () => {
 const navigate = useNavigate();
 const { state, getProfile, setLoading } = React.useContext(Context);
 const [newDialogOpen, setNewDialogOpen] = React.useState(false);
 const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
 const [organizationToDelete, setOrganizationToDelete] = React.useState(false);
 const [organizations, setOrganizations] = React.useState([]);
 const [filteredOrganizations, setFilteredOrganizations] = React.useState([]);
 const [loadingOrganizations, setLoadingOrganizations] = React.useState(true);
 const [selectedSite, setSelectedSite] = React.useState();

 /**
  * On selected site change, sets the local selected site to the site item corresponding to the selected site and
  * attempts to fetch all organizations and store it in state.
  * Sets loadingOrganizations to true for the duration of the fetch.
  */
 React.useEffect(async () => {
  if (!state.selectedSite) {
   return;
  }
  setSelectedSite(state.cemeteryItems.filter((a) => a.SK === state.selectedSite)[0]);
  const allOrganizations = await getAllOrganizations();
  setLoadingOrganizations(false);
  setOrganizations(allOrganizations);
 }, [state.selectedSite]);

 /**
  * On cemetery items change, sets the local selected site state to the site item corresponding to the selected site.
  */
 React.useEffect(async () => {
  setSelectedSite(state.cemeteryItems.filter((a) => a.SK === state.selectedSite)[0]);
 }, [state.cemeteryItems]);

 /**
  * On organizations list change, resets filtered organizations state to the entire list of organizations.
  */
 React.useEffect(() => {
  setFilteredOrganizations(organizations);
 }, [organizations]);

 /**
  * Attempts to assign an organization to the selected site.
  * Sets loading to true for the duration of the request.
  */
 const assignOrganization = async (e, organization) => {
  e.stopPropagation();
  setLoading(true);
  try {
   await FOV.api.assignOrganizations(state.selectedSite, [organization.SK]);
   getProfile();
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
  setLoading(false);
 };
 /**
  * Attempts to unassign an organization to the selected site.
  * Sets loading to true for the duration of the request.
  */
 const unassignOrganization = async (e, organization) => {
  e.stopPropagation();
  setLoading(true);
  try {
   await FOV.api.dissociateOrganization(state.selectedSite, organization.SK);
   getProfile();
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
  setLoading(false);
 };

 const goToOrganization = (organization) => {
  navigate(`/Organization/${organization.SK}`);
 };

 /**
  * Opens new organization dialog
  */
 const handleNewOrganization = () => {
  setNewDialogOpen(true);
 };

 /**
  * Sets organizationToDelete to the provided organization and
  * opens delete organization dialog
  */
 const deleteOrganization = (e, organization) => {
  e.stopPropagation();
  setOrganizationToDelete(organization);
  setDeleteDialogOpen(true);
 };

 /**
  * Fuzzy searches the organizations and sets the filtered
  * organizations state to the result.
  * Sets filtered organizations to the entire array of organizations
  * if the query string is empty.
  * @param {*} event
  */
 function fuzzySearch(event) {
  if (!event.target.value) {
   setFilteredOrganizations(organizations);
   return;
  }
  const options = {
   keys: ["Name"],
  };
  const fuse = new Fuse(organizations, options);
  setFilteredOrganizations([...fuse.search(event.target.value).map((a) => a.item)]);
 }

 return (
  <PageContainer className="scrollbar-hidden">
   <AutoLoadSite />
   <NewOrganizationDialog setOpen={setNewDialogOpen} open={newDialogOpen} goToOrganization={goToOrganization} />
   <DeleteOrganizationDialog
    open={deleteDialogOpen}
    setOpen={setDeleteDialogOpen}
    organizationToDelete={organizationToDelete}
    setOrganizationToDelete={setOrganizationToDelete}
    results={organizations}
    setResults={setOrganizations}
   />
   <PageHeader> Organizations </PageHeader>
   <PageContent sx={{}}>
    <Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
     <Paper sx={{ display: "flex", m: 1, height: "100%" }}>
      <TextField autoComplete="off" varient="standard" id="outlined-required" label="Filter Organizations" onChange={fuzzySearch} />
     </Paper>
    </Box>
    <Divider />
    <Paper
     sx={{
      display: "flex",
      background: (theme) => theme.palette.grey.main,
      p: 1,
      flexDirection: { xs: "column", md: "row" },
     }}
    >
     <Box
      sx={{
       display: "flex",
       flexDirection: "column",
       width: { xs: "100%", md: "50%" },
       p: 1,
      }}
     >
      <FormHeader>Assigned</FormHeader>
      <Paper
       sx={{
        overflowY: "scroll",
        height: "700px",
        background: (theme) => theme.palette.grey.main,
        pr: 1,
       }}
      >
       {loadingOrganizations && !(state.loading || state.loadingSites || state.waiting || !state.signedIn) && <CircularProgress sx={{ margin: "auto", mt: 2, ml: 2 }} color="primary" />}
       {filteredOrganizations
        .filter((org) => selectedSite.Organizations?.includes(org.SK))
        .map((organization, _i) => (
         <OrganizationCard
          key={_i}
          owned={organization.Owner.toLowerCase() === state.email.toLowerCase()}
          organization={organization}
          goToOrganization={goToOrganization}
          assignOrganization={assignOrganization}
          unassignOrganization={unassignOrganization}
          deleteOrganization={deleteOrganization}
         />
        ))}
      </Paper>
     </Box>
     <Box
      sx={{
       display: "flex",
       flexDirection: "column",
       width: { xs: "100%", md: "50%" },
       p: 1,
      }}
     >
      <FormHeader>Unassigned</FormHeader>
      <Paper
       sx={{
        overflowY: "scroll",
        height: "700px",
        background: (theme) => theme.palette.grey.main,
        pr: 1,
       }}
      >
       {loadingOrganizations && !(state.loading || state.loadingSites || state.waiting || !state.signedIn) && <CircularProgress sx={{ margin: "auto", mt: 2, ml: 2 }} color="primary" />}
       {filteredOrganizations
        .filter((org) => !selectedSite.Organizations?.includes(org.SK))
        .map((organization, _i) => (
         <OrganizationCard
          assignable={true}
          key={_i}
          owned={organization.Owner.toLowerCase() === state.email.toLowerCase()}
          organization={organization}
          goToOrganization={goToOrganization}
          assignOrganization={assignOrganization}
          unassignOrganization={unassignOrganization}
          deleteOrganization={deleteOrganization}
         />
        ))}
      </Paper>
     </Box>
    </Paper>
   </PageContent>
   <FooterButtons>
    <Button size="large" variant="contained" color="primary" onClick={handleNewOrganization}>
     CREATE NEW ORGANIZATION
    </Button>
   </FooterButtons>
  </PageContainer>
 );
};

export default Organizations;
