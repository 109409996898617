import React from "react";

import Mapbox from "./Mapbox";
import JumpTo from "./JumpTo";
import { PickerImageLayer } from "./MapWithImage";

/**
 * Record location picker component
 * Automatically jumps to existing record location
 */
const FindOnMap = ({ location, containerStyle, coordinates, url, lat, lng, updatePosition }) => {
 return (
  <Mapbox
   //eslint-disable-next-line
   containerStyle={containerStyle}
   style="mapbox://styles/mapbox/satellite-streets-v11"
   center={location}
  >
   <PickerImageLayer id="nyan" coordinates={coordinates} url={url} lat={lat} lng={lng} updatePosition={updatePosition} />
   <JumpTo zoom={[15]} {...{ location }} />
  </Mapbox>
 );
};

export default FindOnMap;
