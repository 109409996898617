import Controller from "../Controller.js";

import Refresh from "../routes/Refresh.js";
let refresh = new Refresh();

/**
 * Utility class used to allow authenticated requests to attempt
 * to refresh the user's idToken on failure.
 * Sets this.request to the request provided to the constructor.
 */
class Refresher {
 constructor(request) {
  this.request = request;
 }

 /**
  * Initiates this.request and automatically retries after attempting
  * to refresh the idToken if the request fails.
  */
 handleRefresh() {
  const refreshAndRequest = (resolve, reject) => {
   refresh
    .sendRequest()
    .then((result) => {
     Controller.idToken = result.data.IdToken;

     this.request(arguments).then(resolve).catch(reject);
    })
    .catch((err) => {
     reject(err);
    });
  };
  return new Promise((resolve, reject) => {
   if (Controller.idToken !== null) {
    this.request(arguments)
     .then((result) => {
      resolve(result);
     })
     .catch((err) => {
      console.log(err);
      refreshAndRequest(resolve, reject);
     });
   } else {
    refreshAndRequest(resolve, reject);
   }
  });
 }
}

export default Refresher;
