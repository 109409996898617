import * as React from "react";
import { Dialog, DialogContent } from "@mui/material";

import UploadProgressBox from "./UploadProgressBox";

/**
 * Bulk upload progress dialog
 */
const UploadDialog = ({ open, progress, uploading }) => {
 return (
  <Dialog open={open}>
   <DialogContent>
    {progress && <UploadProgressBox variant="determinate" progress={progress.progress * 100} process={progress.process} state={progress.state} message={progress.message} uploading={uploading} />}
   </DialogContent>
  </Dialog>
 );
};

export default UploadDialog;
