import * as React from "react";

import { Box, TextField } from "@mui/material";
import { handleTextChange } from "../../utilities";

/**
 * Form details component for an organization item
 * @param {*} param0 object containing formState and setFormState function
 */
const OrganizationDetails = ({ setFormState, formState }) => {
 return (
  <Box
   sx={{
    display: "flex",
    flexDirection: "column",
    m: 1,
   }}
  >
   <TextField
    required
    label="Name"
    variant="filled"
    name="Name"
    value={formState.Name}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Description (Enter a description)"
    variant="filled"
    name="Description"
    multiline
    maxRows={10}
    value={formState.Description}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Weblinks (Enter colon separated weblinks related to this burial)"
    variant="filled"
    name="Weblinks"
    value={formState.Weblinks}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
  </Box>
 );
};

export default OrganizationDetails;
