import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "get" method,
 * accepting two arguments (startKey, count) in the sendRequest function,
 * updating the url to get-all-cemeteries
 */
class GetRecords extends Request {
 constructor() {
  super("get", ``);
 }
 sendRequest(startKey, count) {
  let queries = [];
  if (startKey) {
   queries.push("startKey=" + startKey);
  }
  if (count) {
   queries.push("count=" + count);
  }
  this.url = `get-all-cemeteries${queries.length ? "?" + queries.join("&") : ""}`;
  return this.sendRequestPromise({});
 }
}

export default GetRecords;
