import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "put" method,
 * accepting two arguments (cemetery, organization) in the sendRequest function,
 * updating the url to assign-organizations,
 * and providing a body object containing the organizations
 */
class AssignOrganizations extends Request {
 constructor() {
  super("put", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const cemetery = args[0];
  const organizations = args[1];

  this.url = `assign-organizations/${cemetery}`;
  return this.sendRequestPromise({ organizations });
 }
}

export default AssignOrganizations;
