import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { InputAdornment, Paper, TextField, Box, MenuItem, Select, InputLabel, FormControl, Avatar, Typography, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FormHeader } from "../styled-components";
import { Context } from "../../SDK/context";

import FOV from "../../SDK/src/main";

const searchAttributes = {
 burial: "forename,surname",
 monument: "name",
 poi: "name",
};

/**
 * Simplified record search component for use within the tour page
 */
const TourSearch = ({ setResult }) => {
 const { state } = React.useContext(Context);
 const [searchQuery, setSearchQuery] = React.useState("");
 const [recordType, setRecordType] = React.useState("burial");
 const [loadingResults, setLoadingResults] = React.useState(false);
 const [results, setResults] = React.useState([]);

 const handleSubmit = async (e) => {
  e.preventDefault();
  performSearch();
 };

 /**
  * Attempts to search for a record using state query parameters.
  * Updates loading state.
  * Updates results with response.
  */
 const performSearch = async () => {
  setLoadingResults(true);
  setResults([]);
  const attribute = searchAttributes[recordType];

  const currentSearch = {
   cemetery: state.selectedSite,
   type: recordType + "s",
   attribute,
   searchQuery,
  };

  try {
   const result = await FOV.api.search(currentSearch.cemetery, currentSearch.type, currentSearch.attribute, "", "", currentSearch.searchQuery, 1, 50);
   if (result.data.hits) {
    const ids = result.data.hits.map((hit) => hit.document.id.split("_")[1]);
    if (ids.length) {
     const batchResult = await FOV.api.bulkGet(state.selectedSite, recordType, ids);
     let results = ids.map((id) => {
      for (let item of batchResult.data.Responses.Cemetery) {
       if (item.SK.split("_")[1] === id) {
        return item;
       }
      }
      return null;
     });
     results = results.filter((a) => a !== null);
     setResults(results);
    }
   }
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
  setLoadingResults(false);
 };

 return (
  <Box sx={{ width: "100%" }}>
   <Paper elevation={2} component="form" sx={{ m: 1 }} onSubmit={handleSubmit}>
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
     <FormControl sx={{ m: 1 }}>
      <InputLabel>Record Type</InputLabel>
      <Select
       sx={{ width: "150px" }}
       //   variant="standard"
       value={recordType}
       label="Record Type"
       onChange={(e) => {
        setRecordType(e.target.value);
        // setSearchAttribute(e.target.value);
       }}
      >
       <MenuItem value="burial">Burial</MenuItem>
       <MenuItem value="monument">Monument</MenuItem>
       <MenuItem value="poi">Point of Interest</MenuItem>
      </Select>
     </FormControl>
     <TextField
      label="Enter a name"
      sx={{
       display: "flex",
       flexGrow: 1,
       flexShrink: 0,
       m: 1,
      }}
      value={searchQuery}
      onChange={(e) => {
       setSearchQuery(e.target.value);
      }}
      InputProps={{
       startAdornment: (
        <InputAdornment position="start">
         <SearchIcon />
        </InputAdornment>
       ),
      }}
     />
     <LoadingButton loading={loadingResults} variant="contained" sx={{ m: 1, height: 55 }} type="submit">
      Search
     </LoadingButton>
    </Box>
    <Box sx={{ p: 1 }}>
     <Paper
      sx={{
       overflowY: "scroll",
       background: (theme) => theme.palette.tertiary.main,
       height: "200px",
       display: "flex",
       flexDirection: "column",
       border: "1px solid",
       borderColor: "lightgrey",
       width: "100%",
      }}
     >
      {results.map((result, _i) => (
       <Button
        onClick={() => {
         setResult({ ...result, type: recordType });
        }}
        sx={{
         display: "flex",
         flexGrow: 0,
         alignItems: "center",
         borderTop: "1px solid",
         borderBottom: "1px solid",
         borderColor: "lightgrey",
         background: "white",
         justifyContent: "space-between",
        }}
        key={_i}
       >
        <Box
         sx={{
          // mr: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          maxWidth: "380px",
         }}
        >
         {_i + 1}
         <Typography noWrap={true} sx={{ width: "100%" }}>
          {result.Name || `${result.Forename} ${result.Surname}`}
         </Typography>
         <Typography
          noWrap={true}
          fontSize="small"
          color="dark"
          sx={{
           textAlign: "left",
           textTransform: "none",
           width: "100%",
          }}
         >
          {result.Description ? result.Description : ""}
         </Typography>
        </Box>
        <Avatar sx={{ ml: 1, width: "50px", height: "50px" }} src={result.Photo} />
       </Button>
      ))}
     </Paper>
    </Box>
   </Paper>
  </Box>
 );
};

export default TourSearch;
