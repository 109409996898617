import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "delete" method,
 * accepting a cemetery argument in the sendRequest function,
 * updating the url to empty-cemetery,
 */
class DeleteCemetery extends Request {
 constructor() {
  super("delete", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const cemetery = args[0];
  this.url = `empty-cemetery/${cemetery}`;
  return this.sendRequestPromise();
 }
}

export default DeleteCemetery;
