import Controller from "../Controller.js";
import Request from "../utility/Request.js";
import jwtDecode from "jwt-decode";

/**
 * Extends the Request class, specifying the "get" method,
 * updating the url to refresh,
 * overriding resolve request to update the Controller's groups
 */
class Refresh extends Request {
 constructor() {
  super("get", `refresh`);
 }
 sendRequest() {
  return this.sendRequestPromise();
 }
 resolveRequest(result, resolve) {
  Controller.idToken = result.data.IdToken;
  if (Controller.idToken) {
   const decoded = jwtDecode(Controller.idToken);
   const groups = decoded["cognito:groups"];
   if (groups) {
    Controller.groups = groups;
   }
  } else {
   Controller.groups = [];
  }
  // if (decoded['email']) {
  //   Controller.email = decoded['email']
  // }
  resolve(result);
 }
}

export default Refresh;
