import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "get" method,
 * accepting three arguments (cemetery, type, id) in the sendRequest function,
 * updating the url to record
 */
class GetRecord extends Request {
 constructor() {
  super("get", ``);
 }
 sendRequest(cemetery, type, id) {
  this.url = `record/${cemetery}/${type}/${id}`;
  return this.sendRequestPromise({});
 }
}

export default GetRecord;
