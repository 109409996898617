import * as React from "react";

import { useNavigate } from "react-router-dom";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { Context } from "../../SDK/context";

/**
 * Invalid pathing graph warning dialog
 */
const InvalidPathDialog = ({ open, recordToDelete, issue }) => {
 const navigate = useNavigate();
 const { state } = React.useContext(Context);

 const goToPathing = () => {
  navigate(`/Pathing/${state.selectedSite}`);
 };

 return (
  <Dialog open={open}>
   <DialogTitle>{`Site path is ${issue}`}</DialogTitle>
   <DialogContent>
    <DialogContentText>
     {issue === "unconnected"
      ? "Please ensure that your paths are connected so that a route can be made between any two points"
      : "Before you can create a tour, the paths for this site need to be created."}
    </DialogContentText>
   </DialogContent>
   <DialogActions>
    <Button onClick={goToPathing}>Go to Pathing</Button>
   </DialogActions>
  </Dialog>
 );
};

export default InvalidPathDialog;
