import * as React from "react";

import { Box, TextField, FormControlLabel, Switch } from "@mui/material";
import { handleTextChange, handleSwitchChange, handleFloatChange, handleNumberChange } from "../../utilities";

/**
 * Form details component for a burial item
 * @param {*} param0 object containing formState and setFormState function
 */
const BurialDetails = ({ setFormState, formState }) => {
 return (
  <Box
   sx={{
    display: "flex",
    flexDirection: "column",
    m: 1,
   }}
  >
   <Box sx={{ display: "flex" }}>
    <FormControlLabel
     sx={{ margin: "auto" }}
     control={<Switch checked={formState.BurialTypeVeteran} name="BurialTypeVeteran" onChange={(e) => handleSwitchChange(e, setFormState, formState)} />}
     label="Veteran Burial"
    />
    <FormControlLabel sx={{ margin: "auto" }} control={<Switch checked={formState.Notable} name="Notable" onChange={(e) => handleSwitchChange(e, setFormState, formState)} />} label="Notable" />
   </Box>
   <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
    <TextField
     sx={{ flexGrow: 1 }}
     required
     label="First Name (Enter the first name of the individual)"
     variant="filled"
     name="Forename"
     value={formState.Forename}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
    />
    <TextField
     sx={{ flexGrow: 1 }}
     required
     label="Last Name (Enter the last name of the individual)"
     variant="filled"
     name="Surname"
     value={formState.Surname}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
    />
   </Box>

   <TextField
    label="Middle Names (Enter the first name of the individual)"
    variant="filled"
    name="MiddleNames"
    value={formState.MiddleNames}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />

   <TextField
    label="Initials (Enter initials)"
    variant="filled"
    name="Initials"
    value={formState.Initials}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Aliases (Enter an alias or nickname)"
    variant="filled"
    name="Aliases"
    value={formState.Aliases}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Description (Enter the description, biography or tell a story about this record)"
    variant="filled"
    name="Description"
    multiline
    maxRows={10}
    value={formState.Description}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
    <TextField
     sx={{ flexGrow: 1 }}
     label="Date of Birth (Enter the date of birth)"
     variant="filled"
     name="DoB"
     value={formState.DoB}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
     type="date"
     InputLabelProps={{ shrink: true }}
    />
    <TextField
     sx={{ flexGrow: 1 }}
     label="Date of Death (Enter the date of death)"
     variant="filled"
     name="DoD"
     value={formState.DoD}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
     type="date"
     InputLabelProps={{ shrink: true }}
    />
    <TextField
     sx={{ flexGrow: 1 }}
     label="Date of Death 2 (Enter an alternate date of death)"
     variant="filled"
     name="DoD2"
     value={formState.DoD2}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
     type="date"
     InputLabelProps={{ shrink: true }}
    />
   </Box>
   <TextField
    label="Educational Honours (Enter any degrees earned e.g. PhD)"
    variant="filled"
    name="EducationalHonours"
    value={formState.EducationalHonours}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Weblinks (Enter colon separated weblinks related to this burial)"
    variant="filled"
    name="Weblinks"
    value={formState.Weblinks}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
    <TextField
     sx={{ flexGrow: 1 }}
     label="Country (Enter the country where the burial is located)"
     variant="filled"
     name="Country"
     value={formState.Country}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
    />
    <TextField
     sx={{ flexGrow: 1 }}
     label="Nationality (Enter the nationality of the individual)"
     variant="filled"
     name="Nationality"
     value={formState.Nationality}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
    />
   </Box>
   <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
    <TextField
     sx={{ flexGrow: 1, display: formState.BurialTypeVeteran ? "flex" : "none" }}
     label="Regiment (Enter the regiment or branch they last served in)"
     variant="filled"
     name="Regiment"
     value={formState.Regiment}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
    />
    <TextField
     sx={{ flexGrow: 1, display: formState.BurialTypeVeteran ? "flex" : "none" }}
     label="Secondary Regiment (Enter any additional regiments they may have served in the past, separated by commas)"
     variant="filled"
     name="SecondaryRegiment"
     value={formState.SecondaryRegiment}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
    />
   </Box>

   <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
    <TextField
     sx={{ flexGrow: 1 }}
     label="Country Commemorations (Enter the country they are commemorated in)"
     variant="filled"
     multiline
     maxRows={10}
     name="CountryCommemorations"
     value={formState.CountryCommemorations}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
    />
    <TextField
     sx={{ flexGrow: 1 }}
     label="Monument Commemorations (Enter the name of a monument they are commemorated on)"
     variant="filled"
     multiline
     maxRows={10}
     name="MonumentCommemorations"
     value={formState.MonumentCommemorations}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
    />
   </Box>
   <TextField
    sx={{ display: formState.BurialTypeVeteran ? "flex" : "none" }}
    label="Country of Service (Enter the name of the country whose forces they served with)"
    variant="filled"
    name="CountryOfService"
    value={formState.CountryOfService}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    sx={{ display: formState.BurialTypeVeteran ? "flex" : "none" }}
    label="Rank (Enter their final rank)"
    variant="filled"
    name="Rank"
    value={formState.Rank}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    sx={{ display: formState.BurialTypeVeteran ? "flex" : "none" }}
    label="Unit (Enter the unit, ship, or squadron they served with)"
    variant="filled"
    name="Unit"
    value={formState.Unit}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    sx={{ display: formState.BurialTypeVeteran ? "flex" : "none" }}
    label="Force / Service / Branch (Enter which branch of the military they served with e.g. Navy)"
    variant="filled"
    name="ForceServiceBranch"
    value={formState.ForceServiceBranch}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    sx={{ display: formState.BurialTypeVeteran ? "flex" : "none" }}
    label="Trade (Enter the trade they performed e.g. Pilot)"
    variant="filled"
    name="Trade"
    value={formState.Trade}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Age at Death (Enter their age at death in years e.g. 48)"
    variant="filled"
    name="AgeAtDeath"
    type="number"
    value={formState.AgeAtDeath.toString()}
    onChange={(e) => {
     handleNumberChange(e, setFormState, formState);
    }}
    inputProps={{
     min: 0,
    }}
   />
   <TextField
    label="Honours / Awards (Enter any honours or awards given separated by commas e.g. Military Cross, Air Force Medal)"
    variant="filled"
    name="HonoursAwards"
    value={formState.HonoursAwards}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    sx={{ display: formState.BurialTypeVeteran ? "flex" : "none" }}
    label="Conflicts (Enter the conflicts in which they served separated by commas e.g. Second World War, Korean War, NATO Peacekeeping)"
    variant="filled"
    name="Conflicts"
    value={formState.Conflicts}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Family Info (Enter the next of kin and survivors e.g. Son of John and Teresa Smith of Toronto, Canada. Survived by Emma, Jonah, and Katy.)"
    variant="filled"
    name="FamilyInfo"
    multiline
    maxRows={10}
    value={formState.FamilyInfo}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Grave Ref Lot (Enter the Lot)"
    variant="filled"
    name="GraveRefLot"
    value={formState.GraveRefLot}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Grave Ref Block (Enter the Block)"
    variant="filled"
    name="GraveRefBlock"
    value={formState.GraveRefBlock}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Grave Ref Section (Enter the Section)"
    variant="filled"
    name="GraveRefSection"
    value={formState.GraveRefSection}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Plot or Monument (Enter the Plot or Monument)"
    variant="filled"
    name="PlotOrMonument"
    value={formState.PlotOrMonument}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Status (Enter the status of the burial e.g. Occupied, Not Occupied)"
    variant="filled"
    name="Status"
    value={formState.Status}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Date of Interment (Enter the date of internment)"
    variant="filled"
    name="DoI"
    value={formState.DoI}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
    type="date"
    InputLabelProps={{ shrink: true }}
   />
   <TextField
    label="Estate Details (Enter the name of the responsible individual or next-of-kin)"
    variant="filled"
    name="EstateDetails"
    value={formState.EstateDetails}
    multiline
    maxRows={10}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Aux (Enter any additional information here)"
    variant="filled"
    name="Aux"
    value={formState.Aux}
    multiline
    maxRows={10}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
    <TextField
     required
     sx={{ flexGrow: 1 }}
     label="Latitude (Enter the Latitude of the burial, or click on the below map to place the burial on the map)"
     variant="filled"
     name="Lat"
     type="number"
     value={formState.Lat.toString()}
     onChange={(e) => {
      handleFloatChange(e, setFormState, formState);
     }}
     inputProps={{
      min: -90,
      max: 90,
      step: "any",
     }}
    />
    <TextField
     required
     sx={{ flexGrow: 1 }}
     label="Longitude (Enter the Longitude of the burial, or click on the below map to place the burial on the map)"
     variant="filled"
     name="Lng"
     type="number"
     value={formState.Lng.toString()}
     onChange={(e) => {
      handleFloatChange(e, setFormState, formState);
     }}
     inputProps={{
      min: -180,
      max: 180,
      step: "any",
     }}
    />
   </Box>
  </Box>
 );
};

export default BurialDetails;
