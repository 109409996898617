import React from "react";

import { Paper, Typography, Divider, Box, LinearProgress, CircularProgress } from "@mui/material";

/**
 * Progress graphic component for bulk-upload
 */
const UploadProgressBox = ({ progress, process, state, message, uploading }) => {
 return (
  <Paper
   sx={{
    p: 1,
    width: "500px",
    minWidth: 0,
    maxWidth: "100%",
    background: (theme) => theme.palette.form.main,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
   }}
  >
   {uploading && <CircularProgress sx={{ m: "auto" }} />}
   <Typography sx={{ textAlign: "center" }}>
    {process} - {state}
   </Typography>
   <Divider />
   <LinearProgress variant="determinate" value={progress} />
   <Paper
    sx={{
     mt: 1,
     background: (theme) => theme.palette.grey.main,
     color: (theme) => theme.palette.primary.main,
     maxHeight: "400px",
     overflowY: "scroll",
    }}
   >
    {message.map((msg, _i) => (
     <Box key={_i}>
      <Typography sx={{ textAlign: "center" }}>{msg}</Typography>
      <Divider />
     </Box>
    ))}
   </Paper>
  </Paper>
 );
};

export default UploadProgressBox;
