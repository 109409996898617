import * as React from "react";

import { Box, TextField, FormControlLabel, Switch, FormGroup } from "@mui/material";
import { handleTextChange, handleSwitchChange, handleFloatChange } from "../../utilities";

/**
 * Form details component for a monument item with reduced editable fields
 * @param {*} param0 object containing formState and setFormState function
 */
const MonumentShortForm = ({ setFormState, formState }) => {
 return (
  <Box
   sx={{
    display: "flex",
    flexDirection: "column",
    m: 1,
   }}
  >
   <FormControlLabel
    sx={{ margin: "auto" }}
    control={<Switch checked={formState.Active} name="Active" onChange={(e) => handleSwitchChange(e, setFormState, formState)} />}
    label="Display in mobile app"
   />
   <TextField
    required
    label="Name"
    variant="filled"
    name="Name"
    value={formState.Name}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />

   <TextField
    label="Description"
    variant="filled"
    name="Description"
    multiline
    maxRows={10}
    value={formState.Description}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />

   <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
    <TextField
     sx={{ flexGrow: 1 }}
     label="Latitude"
     variant="filled"
     name="Lat"
     type="number"
     value={formState.Lat}
     onChange={(e) => {
      handleFloatChange(e, setFormState, formState);
     }}
     inputProps={{
      min: -90,
      max: 90,
      step: "any",
     }}
    />
    <TextField
     sx={{ flexGrow: 1 }}
     label="Longitude"
     variant="filled"
     name="Lng"
     type="number"
     value={formState.Lng}
     onChange={(e) => {
      handleFloatChange(e, setFormState, formState);
     }}
     inputProps={{
      min: -180,
      max: 180,
      step: "any",
     }}
    />
   </Box>
  </Box>
 );
};

export default MonumentShortForm;
