import React, { useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { TextField, Paper, FormGroup, FormControl, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LoadingButton from "@mui/lab/LoadingButton";

import { handleTextChange } from "../utilities";

import { Context } from "../SDK/context";

import FOV from "../SDK/src/main";

/**
 * Sign in page containing sign in form, as well as forget-and-reset password dialogs.
 */
const SignIn = () => {
 const navigate = useNavigate();
 const { state, signIn, setLoading } = useContext(Context);
 const [submitting, setSubmitting] = useState({
  signIn: false,
  forgot: false,
  reset: false,
 });
 const [userNotFound, setUserNotFound] = useState(false);
 const [userNotConfirmed, setUserNotConfirmed] = useState(false);
 const [incorrectPassword, setIncorrectPassword] = useState(false);
 const [incorrectCode, setIncorrectCode] = useState(false);
 const [limitExceeded, setLimitExceeded] = useState(false);
 const [open, setOpen] = React.useState(false);
 const [hasCode, setHasCode] = React.useState(false);
 const [formState, setFormState] = useState({
  email: "",
  password: "",
 });
 const [resetState, setResetState] = useState({
  code: "",
  password: "",
 });

 /**
  * Opens forgot password dialog.
  */
 const handleClickOpen = () => {
  setHasCode(false);
  setOpen(true);
 };

 /**
  * Closes forgot password dialog.
  */
 const handleClose = () => {
  setOpen(false);
 };

 /**
  * Attempts to reset a users password, given a reset code and new password.
  * Closes the forgot password dialog on success.
  * Otherwise, if the code is incorrect, sets incorrect code state to true.
  * Sets reset value of submitting state to true for the duration of the request.
  * @param {*} event reset password submit event
  */
 const handleReset = async (event) => {
  event.preventDefault();
  setSubmitting({ ...submitting, reset: true });
  try {
   await FOV.api.resetPassword(formState.email, resetState.code, resetState.password);
   handleClose();
  } catch (err) {
   console.error(err);
   if (err.response) {
    const data = err.response.data;
    switch (data.message) {
     case "CodeMismatchException":
      setIncorrectCode(true);
      break;
     default:
      break;
    }
   }
  }
  setSubmitting({ ...submitting, reset: false });
 };
 /**
  * Attempts to send a user an email containing a reset code to be used
  * for resetting their password.
  * Sets has code state to true on success.
  * Otherwise, can set either user not found state or limit exceeded state to true
  * dpenending on the error response.
  * Sets forgot value of submitting state to true for the duration of the request.
  * @param {*} event forgot password submit event
  */
 const handleForgot = async (event) => {
  event.preventDefault();
  setSubmitting({ ...submitting, forgot: true });
  try {
   await FOV.api.forgotPassword(formState.email);
   setHasCode(true);
  } catch (err) {
   console.error(err);
   if (err.response) {
    const data = err.response.data;
    switch (data.message) {
     case "UserNotFoundException":
      setUserNotFound(true);
      break;
     case "LimitExceededException":
      setLimitExceeded(true);
      break;
     default:
      break;
    }
   }
  }
  setSubmitting({ ...submitting, forgot: false });
 };

 /**
  * Attempts to log in a user, given a username and password.
  * Resets error message states to false.
  * Navigates to home (Site Manager page) on success.
  * Otherwise, can set user not found state, incorrect password state, or
  * user not confirmed state to true depending on the error response.
  * Sets signIn value of submitting state to true for the duration of the request.
  * @param {*} event sign in form event
  */
 const handleSubmit = async (event) => {
  event.preventDefault();
  setUserNotFound(false);
  setUserNotConfirmed(false);
  setIncorrectPassword(false);
  setSubmitting({ ...submitting, signIn: true });
  try {
   await signIn(formState);
   navigate(`/SiteManager`);
  } catch (err) {
   setSubmitting({ ...submitting, signIn: false });

   console.error(err);
   if (err.response) {
    const data = err.response.data;

    switch (data.message) {
     case "UserNotFoundException":
      setUserNotFound(true);
      break;
     case "NotAuthorizedException":
      setIncorrectPassword(true);
      break;
     case "UserNotConfirmedException":
      setUserNotConfirmed(true);
     default:
      break;
    }
   }
  }
 };

 return !(state.signedIn && state.email) && !state.waiting ? (
  <>
   <Dialog open={open}>
    <DialogTitle>Reset Password</DialogTitle>
    {!hasCode ? (
     <form autoComplete={Math.random().toString()} onSubmit={handleForgot}>
      <DialogContent>
       <DialogContentText>Please confirm your email below. Once you submit, an email will be sent to this address with a verification code to reset your password.</DialogContentText>
       <TextField
        required
        autoComplete={Math.random().toString()}
        autoFocus
        margin="dense"
        label="Email Address"
        name="email"
        type="email"
        fullWidth
        variant="standard"
        value={formState.email}
        onChange={(event) => {
         handleTextChange(event, setFormState, formState);
        }}
        inputProps={{
         type: "email",
         autoComplete: Math.random().toString(),
        }}
        error={userNotFound || limitExceeded}
        helperText={limitExceeded ? "Limit exceeded" : userNotFound ? "User not found" : ""}
       />
      </DialogContent>
      <DialogActions>
       <Button disabled={submitting.forgot} onClick={handleClose}>
        Cancel
       </Button>
       <LoadingButton loading={submitting.forgot} type="submit">
        Submit
       </LoadingButton>
      </DialogActions>
     </form>
    ) : (
     <form autoComplete={Math.random().toString()} onSubmit={handleReset}>
      <DialogContent>
       <DialogContentText>Enter your code and a new 8+ character password below.</DialogContentText>
       <>
        <TextField
         required
         autoComplete={Math.random().toString()}
         margin="dense"
         label="Verification Code"
         name="code"
         fullWidth
         variant="standard"
         value={resetState.email}
         onChange={(event) => handleTextChange(event, setResetState, resetState)}
         inputProps={{
          pattern: "[0-9]{6}",
          title: "Verification codes contain 6 digits",
          autoComplete: Math.random().toString(),
         }}
         error={incorrectCode || limitExceeded}
         helperText={limitExceeded ? "Limit exceeded" : incorrectCode ? "Code mismatch" : ""}
        />
        <TextField
         required
         autoComplete={Math.random().toString()}
         margin="dense"
         label="New Password"
         type="password"
         name="password"
         fullWidth
         variant="standard"
         value={resetState.password}
         onChange={(event) => handleTextChange(event, setResetState, resetState)}
         inputProps={{
          minLength: "8",
          autoComplete: "none",
         }}
         error={limitExceeded}
         helperText={limitExceeded ? "Limit exceeded" : ""}
        />
       </>
      </DialogContent>
      <DialogActions>
       <Button disabled={submitting.reset} onClick={handleClose}>
        Cancel
       </Button>
       <LoadingButton loading={submitting.reset} type="submit">
        Submit
       </LoadingButton>
      </DialogActions>
     </form>
    )}
   </Dialog>
   <form onSubmit={handleSubmit} style={{ height: "100%", display: "flex" }}>
    <Paper
     sx={{
      padding: 3,
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      maxWidth: "650px",
      margin: "auto",
     }}
    >
     <LockIcon
      sx={{
       width: "50px",
       height: "auto",
       margin: "auto",
       color: (theme) => theme.palette.primary.main,
      }}
     />
     <Typography
      sx={{
       margin: "auto",
       fontSize: "30px",
       color: (theme) => theme.palette.primary.main,
      }}
     >
      Sign In
     </Typography>
     <FormControl>
      <FormGroup>
       <TextField
        autoFocus
        required
        label="Email"
        variant="filled"
        name="email"
        value={formState.email}
        onChange={(event) => handleTextChange(event, setFormState, formState)}
        inputProps={{
         type: "email",
        }}
        error={userNotFound || userNotConfirmed}
        helperText={userNotFound ? "User not found" : userNotConfirmed ? "User not confirmed" : ""}
       />
       <TextField
        required
        label="Password"
        type="password"
        name="password"
        value={formState.password}
        onChange={(event) => handleTextChange(event, setFormState, formState)}
        inputProps={{
         minLength: "8",
        }}
        variant="filled"
        error={incorrectPassword}
        helperText={incorrectPassword ? "Incorrect password" : ""}
       />
       <Button variant="text" sx={{ display: incorrectPassword ? "block" : "none" }} onClick={handleClickOpen}>
        Reset Password
       </Button>
       <LoadingButton loading={submitting.signIn} variant="contained" sx={{ background: (theme) => theme.palette.secondary.main }} type="submit">
        Submit
       </LoadingButton>
      </FormGroup>
     </FormControl>
    </Paper>
   </form>
  </>
 ) : state.email ? (
  <Navigate to={{ pathname: `SiteManager` }} />
 ) : null;
};

export default SignIn;
