import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "post" method,
 * accepting two arguments (email, password) in the sendRequest function,
 * updating the url to register,
 * and providing a body object containing the email and password
 */
class Register extends Request {
 constructor() {
  super("post", `register`);
 }
 sendRequest(email, password) {
  return this.sendRequestPromise({ email, password });
 }
}

export default Register;
