import React from "react";
// // import { RiMapPinAddFill, RiEditBoxFill } from "react-icons/ri";
// import { FormOutlined, EnvironmentOutlined } from "@ant-design/icons";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import styled from "styled-components";
import { Mode } from "./Mode";
import "./Table.css";

/**
 * Overlaid pathing UI
 */
export const PathingControls = ({ mode, onModeClick, deselect, toggleOpacity, opacity }) => {
 return (
  <Controls>
   <AddButton selected={mode === Mode.Add} onClick={() => onModeClick(Mode.Add)} />
   <EditButton selected={mode === Mode.Edit} onClick={() => onModeClick(Mode.Edit)} />
   <DeselectButton onClick={() => deselect()} />
   <OpacityButton onClick={() => toggleOpacity()} show={opacity} />
  </Controls>
 );
};

const Controls = styled.div`
 position: absolute;
 z-index: 1;
 display: flex;
 flex-direction: column;
 box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
 border: 1px solid rgba(0, 0, 0, 0.1);
 top: 24px;
`;

const ControlButton = styled.button`
 background-color: ${(props) => (props.selected ? "#c96015" : "#787878")};
 color: white;
 opacity: 0.95;
 transition: background-color 0.16s ease-out;
 cursor: pointer;
 border: 0;
 height: 32px;
 width: 132px;
 display: flex;
 flex-direction: row;
 align-items: center;
 text-transform: uppercase;
 font-weight: bold;
 font-size: 14px;
`;

const NonModeButton = styled.button`
 background-color: #485f75;
 color: white;
 opacity: 0.95;
 transition: background-color 0.16s ease-out;
 cursor: pointer;
 border: 0;
 height: 32px;
 width: 132px;
 display: flex;
 flex-direction: row;
 align-items: center;
 text-transform: uppercase;
 font-weight: bold;
 font-size: 14px;
`;

/**
 * Button for adding a new path node
 */
const AddButton = (props) => {
 return (
  <ControlButton {...props}>
   {/* <RiMapPinAddFill style={{ width: 22, height: 22 }} /> */}
   <LocationOnIcon style={{ margin: "0px 12px 0px 3px", fontSize: 22 }} />
   Add point
  </ControlButton>
 );
};

/**
 * Button to trigger pathing edit mode
 */
const EditButton = (props) => {
 return (
  <ControlButton {...props}>
   {/* <RiEditBoxFill style={{ width: 22, height: 22 }} /> */}
   <EditIcon style={{ margin: "0px 12px 0px 3px", fontSize: 22 }} />
   EDIT POINT
  </ControlButton>
 );
};

/**
 * Button to deselect all nodes/arcs on the path
 */
const DeselectButton = (props) => {
 return (
  <NonModeButton {...props}>
   {/* <RiEditBoxFill style={{ width: 22, height: 22 }} /> */}
   <CircleIcon style={{ margin: "0px 12px 0px 3px", fontSize: 22 }} />
   DESELECT
  </NonModeButton>
 );
};

/**
 * Button to toggle map image opacity
 */
const OpacityButton = (props) => {
 return (
  <NonModeButton {...props}>
   {/* <RiEditBoxFill style={{ width: 22, height: 22 }} /> */}
   {props.show ? <CircleIcon style={{ margin: "0px 12px 0px 3px", fontSize: 22 }} /> : <CircleOutlinedIcon style={{ margin: "0px 12px 0px 3px", fontSize: 22 }} />}

   {props.show ? "HIDE MAP" : "SHOW MAP"}
  </NonModeButton>
 );
};
