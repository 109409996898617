import { Paper, Box, Divider, Typography, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import React from "react";
import { handleTextChange } from "../../utilities";
import FOV from "../../SDK/src/main";

const RegisterForm = () => {
 const [formState, setFormState] = React.useState({ email: "", password: "" });
 const [submitting, setSubmitting] = React.useState(false);
 const [userExists, setUserExists] = React.useState(false);
 const [open, setOpen] = React.useState(false);

 /**
  * Sets open to false
  */
 const handleClose = () => {
  setOpen(false);
 };

 /**
  * Attempts to register a new user given the submitted form values.
  * Updates helper-text and loading state.
  * @param {*} event form submit event
  */
 const handleSubmit = async (event) => {
  event.preventDefault();
  setUserExists(false);
  setSubmitting(true);
  try {
   await FOV.api.register(formState.email, formState.password);
   setOpen(true);
  } catch (err) {
   console.error(err);
   if (err.response) {
    const data = err.response.data;
    switch (data.message) {
     case "UsernameExistsException":
      setUserExists(true);
      break;
     default:
      break;
    }
   }
  }
  setSubmitting(false);
 };

 return (
  <form onSubmit={handleSubmit} style={{ height: "100%", display: "flex" }}>
   <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">Submitted</DialogTitle>
    <DialogContent>
     <DialogContentText id="alert-dialog-description">The new user has been registered</DialogContentText>
    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose}>Okay</Button>
    </DialogActions>
   </Dialog>
   <Paper
    elevation={2}
    sx={{
     width: "100%",
     maxWidth: { xs: "700px", xl: "500px" },
     display: "flex",
     flexDirection: "column",
     m: "auto",
     mt: 5,
    }}
   >
    <Typography
     sx={{
      margin: "auto",
      fontSize: "30px",
      color: (theme) => theme.palette.primary.main,
     }}
    >
     Register
    </Typography>
    <Divider />
    <Box sx={{ width: "100%" }}>
     <Box
      sx={{
       display: "flex",
       flexDirection: "column",
       m: 1,
      }}
     >
      <TextField
       required
       label="Email (Enter the new email address to register)"
       variant="filled"
       name="email"
       value={formState.email}
       onChange={(e) => {
        handleTextChange(e, setFormState, formState);
       }}
       inputProps={{
        type: "email",
       }}
       error={userExists}
       helperText={userExists ? "User is already registered" : ""}
      />
      <TextField
       required
       label="Password (Enter an initial 8+ character password)"
       name="password"
       variant="filled"
       value={formState.password}
       onChange={(e) => {
        handleTextChange(e, setFormState, formState);
       }}
       inputProps={{
        minLength: "8",
       }}
      />
     </Box>
    </Box>
    <Divider />
    <LoadingButton loading={submitting} variant="contained" sx={{ background: (theme) => theme.palette.secondary, m: 1 }} type="submit">
     Submit
    </LoadingButton>
   </Paper>
  </form>
 );
};

export default RegisterForm;
