import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "post" method,
 * accepting a Name argument in the sendRequest function,
 * updating the url to cemetery,
 * and providing a body object containing the Name
 */
class PostBurial extends Request {
 constructor() {
  super("post", `cemetery`);
 }
 sendRequest() {
  const args = arguments[0];
  const Name = args[0];

  return this.sendRequestPromise({ Name });
 }
}

export default PostBurial;
