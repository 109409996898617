import * as React from "react";

import { Box, Paper, Typography, Divider, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import BurialShortForm from "./BurialShortForm";
import MonumentShortForm from "./MonumentShortForm";
import PoiShortForm from "./PoiShortForm";

import { Context } from "../../SDK/context";
import FOV from "../../SDK/src/main";

/**
 * Search result quicklook form
 */
const ResultForm = ({ record, setTitle }) => {
 const { state, setLoading } = React.useContext(Context);

 const [formState, setFormState] = React.useState({
  ...record,
  Id: record.SK.split("_")[1],
 });

 /**
  * Attempts to upsert a new record given the submitted form values.
  * Parses coordinate fields before making request and calls setTitle
  * to update the quicklook title.
  * Updates record quicklook.
  * @param {*} e form submit event
  */
 const handleSubmit = async (e) => {
  setLoading(true);
  e.preventDefault();
  try {
   const newFormState = { ...formState };
   for (let field of ["Lat", "Lng"]) {
    const value = parseFloat(newFormState[field], 10);
    if (isNaN(value)) {
     newFormState[field] = 0;
    } else {
     newFormState[field] = value;
    }
   }
   await postRecord(newFormState);
   switch (record.SK.split("_")[0]) {
    case "burial":
     setTitle(`${newFormState.Forename} ${newFormState.Surname}`);
     break;
    case "monument":
    case "poi":
     setTitle(newFormState.Name);
     break;
   }
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
  setLoading(false);
 };

 const postRecord = (newItem) => {
  return FOV.api.postRecord(state.selectedSite, newItem, newItem.SK.split("_")[0]);
 };

 const height = 175;
 const width = 300;

 return (
  <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
   {record.SK.split("_")[0] === "burial" ? (
    <BurialShortForm formState={formState} setFormState={setFormState} />
   ) : record.SK.split("_")[0] === "monument" ? (
    <MonumentShortForm formState={formState} setFormState={setFormState} />
   ) : (
    <PoiShortForm formState={formState} setFormState={setFormState} />
   )}

   <Button type="submit" size="large" variant="contained" color="primary" sx={{ flexGrow: 0, m: "auto", mt: 1 }}>
    Save <SaveIcon sx={{ ml: 1 }} />
   </Button>
   {(record.Media || record.Photo) && (
    <>
     <Divider sx={{ mt: 2 }} />
     <Box
      sx={{
       p: 1,
       display: "flex",
       flexGrow: 1,
       justifyContent: "start",
       flexDirection: { xs: "column", md: "row" },
      }}
     >
      {record.Photo && (
       <Box sx={{ maxWidth: "fit-content" }}>
        <Typography fontSize="30px" color="primary" sx={{ textAlign: "center" }}>
         Photo
        </Typography>
        <Paper
         square
         elevation={2}
         sx={{
          mr: 1,
          p: 1,
          display: "flex",
          border: "1px solid grey",
         }}
        >
         <img
          src={record.Photo || "https://www.thesprucepets.com/thmb/hThcFCxT20ql0opGe4B8WGICbc4=/1851x1851/smart/filters:no_upscale()/cat-talk-eyes-553942-hero-df606397b6ff47b19f3ab98589c3e2ce.jpg"}
          style={{
           height: `${height}px`,
           // width: `${width}px`,
           maxWidth: `${width}px`,
           objectFit: "contain",
           display: "flex",
           minWidth: 0,
          }}
         />
        </Paper>
       </Box>
      )}
      {record.Media && (
       <Box sx={{ minWidth: 0 }}>
        <Typography color="primary" fontSize="30px" sx={{ textAlign: "center" }}>
         Gallery
        </Typography>
        <Paper
         square
         elevation={2}
         sx={{
          display: "flex",
          overflowX: "scroll",
          minWidth: 0,
          border: "1px solid grey",
          p: 1,
         }}
        >
         {record.Media.split(";").map((src) => (
          <img
           key={src}
           src={src}
           style={{
            height: `${height}px`,
            width: `${width}px`,
            objectFit: "contain",
            marginLeft: "10px",
            marginRight: "10px",
           }}
          />
         ))}
        </Paper>
       </Box>
      )}
     </Box>
    </>
   )}
  </form>
 );
};

export default ResultForm;
