import * as React from "react";
import { Button, ButtonGroup, Paper } from "@mui/material";

/**
 * Component for adding and removing a photo for a photo field in a form.
 */
const ImageUpload = ({ fieldName, formState, setFormState, newMedia, setNewMedia, small, children }) => {
 const [src, setSrc] = React.useState();

 // Initialises source in state to null when the form record changes
 React.useEffect(() => {
  setSrc(null);
 }, [formState.SK]);

 /**
  * When a photo file is uploaded (to the browser), an object url is created and used as a temporary source
  * (used in place of image url) and mapped to the file in the newMedia object.
  */
 const onUpload = (event) => {
  const file = event.target.files[0];
  if (file.type.split("/")[0] !== "image") {
   console.error("file is not an image");
  } else {
   const tempSrc = URL.createObjectURL(file);
   setSrc(tempSrc);
   setNewMedia({
    ...newMedia,
    [fieldName]: file,
   });
  }
 };

 // Clears source state and removes photo source from the form and newMedia object.
 const onDeleteImage = () => {
  setSrc(null);
  setFormState({ ...formState, [fieldName]: undefined });
  setNewMedia({
   ...newMedia,
   [fieldName]: undefined,
  });
 };

 return (
  <Paper
   square
   sx={{
    m: "10px",
    padding: "10px",
    background: (theme) => theme.palette.tertiary.main,
    maxWidth: "calc(100% - 20px)",
   }}
  >
   <div
    style={{
     padding: `10px`,
     maxWidth: `100%`,
    }}
   >
    <Paper
     elevation={3}
     sx={{
      p: 0.5,
      background: (theme) => theme.palette.grey.main,
      minHeight: "50px",
      maxHeight: "350px",
      display: "flex",
      justifyContent: "center",
     }}
    >
     <img
      src={src || formState[fieldName]}
      style={{
       maxWidth: "100%",
       maxHeight: small ? "190px" : "300px",
       objectFit: "contain",
      }}
     />
    </Paper>
    <ButtonGroup sx={{ width: "100%" }}>
     <Button variant="contained" component="label" sx={{ width: "100%" }}>
      {src || formState[fieldName] ? "Replace" : "Add"} {fieldName}
      <input type="file" hidden onChange={onUpload} accept=".jpg, .jpeg, .png, image/jpeg, image/png" />
     </Button>
     <Button
      disabled={!(src || formState[fieldName])}
      color="error"
      variant="contained"
      component="label"
      sx={{ width: "100%" }}
      onClick={onDeleteImage}
     >
      Delete
     </Button>
    </ButtonGroup>
    <p style={{ fontSize: "medium", textAlign: "center" }}>Please note supported filetypes are: jpg, png</p>
   </div>
   {children}
  </Paper>
 );
};

export default ImageUpload;
