import * as React from "react";

import { useNavigate } from "react-router-dom";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { handleTextChange } from "../../utilities";

import { Context } from "../../SDK/context";

import FOV from "../../SDK/src/main";

/**
 * Cemetery creation dialog
 */
const NewSiteDialog = ({ open, setOpen }) => {
 const navigate = useNavigate();

 const { state, setCemeteries, setCemeteryItems, setSelectedSite } = React.useContext(Context);
 const [formState, setFormState] = React.useState({ Name: "" });
 const [submitting, setSubmitting] = React.useState(false);

 // Triggers navigation to a site if the selected site changes
 React.useEffect(() => {
  if (submitting && state.selectedSite) {
   goToSite(state.selectedSite);
  }
 }, [state.selectedSite]);

 const goToSite = (sk) => {
  navigate(`/Overview/${sk}`);
 };

 const handleClose = () => {
  setOpen(false);
 };

 /**
  * Attempts to create a cemetery given the submitted form values.
  * Sets selected site, which triggers navigation to the new cemetery
  * @param {*} event form submit event
  */
 const handleSubmit = async (event) => {
  event.preventDefault();
  setSubmitting(true);
  try {
   const result = await FOV.api.createCemetery(formState.Name);
   setCemeteries([...(state.cemeteries || []), result.data.SK]);
   setCemeteryItems([...(state.cemeteryItems || []), result.data]);
   setSelectedSite(result.data.SK);
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
 };

 return (
  <Dialog open={open}>
   <DialogTitle>Create New Site</DialogTitle>
   <form onSubmit={handleSubmit}>
    <DialogContent>
     <DialogContentText>Enter a name for your new site.</DialogContentText>
     <TextField
      required
      autoFocus
      autoComplete="off"
      margin="dense"
      label="Site Name"
      name="Name"
      fullWidth
      variant="standard"
      value={formState.Name}
      onChange={(event) => {
       handleTextChange(event, setFormState, formState);
      }}
     />
    </DialogContent>
    <DialogActions>
     <Button disabled={submitting} onClick={handleClose}>
      Cancel
     </Button>
     <LoadingButton loading={submitting} type="submit">
      Submit
     </LoadingButton>
    </DialogActions>
   </form>
  </Dialog>
 );
};

export default NewSiteDialog;
