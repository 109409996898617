import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "post" method,
 * accepting three arguments (cemetery, type, ids) in the sendRequest function,
 * updating the url to bulk-get,
 * and providing a body object containing the ids
 */
class BulkGet extends Request {
 constructor() {
  super("post", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const cemetery = args[0];
  const type = args[1];
  const ids = [...new Set(args[2])];

  this.url = `bulk-get/${cemetery}/${type}`;
  return this.sendRequestPromise({ ids });
 }
}

export default BulkGet;
