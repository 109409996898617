/**
 * API controller class used to perform web requests to the backend.
 * Keeps hold of auth state and axios configuration.
 */

class Controller {
 //  static baseUrl = "https://rgmwnl02zd.execute-api.eu-west-2.amazonaws.com/Prod";
 //  static baseUrl = "https://kum1l18mh1.execute-api.ca-central-1.amazonaws.com/Prod";
 static baseUrl = "https://api.memoryanchor.com";
 //  static baseUrl = "https://api3.memoryanchor.com";
 static idToken = null;
 static groups = [];
}

export default Controller;
