/**
 * Class for generating and storing a directed graph made by projecting
 * additional nodes onto a provided graph, corresponding to the closest
 * points on the graph to a provided list of points.
 */
class ProjectedGraphGenerator {
 constructor(paths, points) {
  try {
   this.paths = JSON.parse(JSON.stringify(paths));
   this.points = JSON.parse(JSON.stringify(points));
  } catch (err) {}
  this.graph = [];
  this.mapping = {};
  this.inverseMapping = {};
 }
}

/**
 * Finds the closest point on a line to a provided point
 * @param {*} p coordinate point to project onto line
 * @param {*} a start coordinate of line
 * @param {*} b end coordinate of line
 * @returns distance point projects to on line
 */
ProjectedGraphGenerator.prototype.project = function (p, a, b) {
 const atob = { x: b.x - a.x, y: b.y - a.y };
 const atop = { x: p.x - a.x, y: p.y - a.y };
 const len = atob.x * atob.x + atob.y * atob.y;
 const dot = atop.x * atob.x + atop.y * atob.y;
 const t = Math.min(1, Math.max(0, dot / len));

 return {
  x: a.x + atob.x * t,
  y: a.y + atob.y * t,
  t,
 };
};

/**
 * Calculates the distance between two points
 * @param {*} a first point coordinate
 * @param {*} b last point coordinate
 * @returns distance between a and b
 */
ProjectedGraphGenerator.prototype.calcDistance = function (a, b) {
 return Math.sqrt((a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y));
};

/**
 * Finds the closest projections of this.points onto the graph defined by this.paths,
 * then sets this.graph to the graph generated by combining this.paths and the projections.
 * Sets this.inverseMapping to a object mapping this.points to the corresponding projected nodes.
 */
ProjectedGraphGenerator.prototype.calcProjections = function () {
 const graph = this.paths;
 let mapping = {};
 let inverseMapping = {};

 // For each point of interest
 for (let point of this.points) {
  const pointPos = { x: point.lat, y: point.lng };
  let closestArc = null;
  // For each node in the paths graph
  for (let node of graph) {
   const nodePos = { x: node.lat, y: node.lng };
   // For each neighbour of the current node
   for (let neighbour of node.neighbours) {
    const neighbourPos = {
     x: this.paths[neighbour].lat,
     y: this.paths[neighbour].lng,
    };

    // Arc between current node and current neighbour
    const arc = { a: nodePos, b: neighbourPos };

    const closestpoint = this.project(pointPos, arc.a, arc.b);

    const distance = this.calcDistance(pointPos, closestpoint);

    // If the distance to the closest point on the arc between the current node and neighbour is shorter than
    // anything so far, set it as the closest
    if (!closestArc || distance < closestArc.distance) {
     closestArc = { closestpoint, distance, node, neighbour };
    }
   }
  }
  // If the closest point isn't already a node
  if (closestArc.closestpoint.t > 0 && closestArc.closestpoint.t < 1) {
   // Remove the neighbour index from the node's neighbour list
   closestArc.node.neighbours = closestArc.node.neighbours.filter((index) => index !== closestArc.neighbour);
   // Add a reference to a future node to the node's neighbour list
   closestArc.node.neighbours.push(graph.length);
   // Remove the node index from its neighbours neighbour list
   graph[closestArc.neighbour].neighbours = graph[closestArc.neighbour].neighbours.filter((index) => index !== graph.indexOf(closestArc.node));
   // Add a reference to a future node to the node's neighbour's neighbour list
   graph[closestArc.neighbour].neighbours.push(graph.length);
   // Add a reference to a future node to the poe
   mapping[graph.length] = this.points.indexOf(point);
   inverseMapping[this.points.indexOf(point)] = graph.length;
   // Add the future node
   graph.push({
    index: graph.length,
    lat: closestArc.closestpoint.x,
    lng: closestArc.closestpoint.y,
    neighbours: [graph.indexOf(closestArc.node), closestArc.neighbour],
    ids: [point.id],
   });
  } else {
   if (closestArc.closestpoint.t === 0) {
    // select the closestArc node as the corresponding node
    if (graph[closestArc.node.index].ids && graph[closestArc.node.index].ids.length) {
     graph[closestArc.node.index].ids.push(point.id);
    } else {
     graph[closestArc.node.index].ids = [point.id];
    }
    mapping[closestArc.node.index] = this.points.indexOf(point);
    inverseMapping[this.points.indexOf(point)] = closestArc.node.index;
   } else {
    // select the closestArc neighbour as the corresponding node
    if (graph[closestArc.neighbour].ids && graph[closestArc.neighbour].ids.length) {
     graph[closestArc.neighbour].ids.push(point.id);
    } else {
     graph[closestArc.neighbour].ids = [point.id];
    }
    mapping[closestArc.neighbour] = this.points.indexOf(point);
    inverseMapping[this.points.indexOf(point)] = closestArc.neighbour;
   }
  }
 }
 this.graph = graph;
 this.mapping = mapping;
 this.inverseMapping = inverseMapping;
 return { graph, mapping, inverseMapping };
};

/**
 * Implements Dijkstra's algorithm to traverse this.graph and find the shortest
 * path between two provided nodes.
 * @param {*} start node to start traversal from
 * @param {*} finish node to end shortest path at
 * @returns shortest path between start and finish
 */
ProjectedGraphGenerator.prototype.calcPath = function (start, finish) {
 const visited = [];
 let unvisited = [];
 let result = {};

 unvisited.push(start);
 result[start] = { distance: 0 };
 for (let node of this.graph) {
  if (node.index === start) continue;
  result[node.index] = { distance: Infinity };
  unvisited.push(node.index);
 }

 while (unvisited.length) {
  let closestUnvisitedDistance = Infinity;
  let closestIndex = null;
  for (let index of unvisited) {
   if (result[index].distance < closestUnvisitedDistance) {
    closestUnvisitedDistance = result[index].distance;
    closestIndex = index;
   }
  }
  if (closestIndex === null) {
   return false;
  }
  let previous = this.graph[closestIndex];
  let previousPos = { x: previous.lat, y: previous.lng };
  for (let neighbour of previous.neighbours) {
   let node = this.graph[neighbour];
   let nodePos = { x: node.lat, y: node.lng };
   let distance = this.calcDistance(previousPos, nodePos) + result[closestIndex].distance;

   if (distance < result[neighbour].distance) {
    result[neighbour].distance = distance;
    result[neighbour].previous = closestIndex;
   }
  }
  visited.push(closestIndex);
  unvisited = unvisited.filter((node) => node !== closestIndex);
 }
 let complete = false;
 let path = [finish];
 while (!complete) {
  let previous = result[path[path.length - 1]].previous;
  if (typeof previous === "undefined") {
   break;
  }
  if (previous !== start) {
   path.push(previous);
  } else {
   break;
  }
 }

 return path.reverse();
};

/**
 * Uses calcPath to determine the shortest path in this.graph that visits
 * all points mapped this.inverseMapping, in order.
 * @returns shortest path that visits all projected nodes
 */
ProjectedGraphGenerator.prototype.calcTour = function () {
 if (!this.points || !this.paths) {
  return true;
 }
 this.calcProjections();

 let tour = [this.inverseMapping[0]];

 for (let i = 0; i < this.points.length - 1; ++i) {
  const path = this.calcPath(this.inverseMapping[i], this.inverseMapping[i + 1]);

  if (!path) {
   return false;
  }
  tour = tour.concat(path);
 }

 const start = this.graph[tour[0]];

 let tempPath = [start];
 for (let i = 1; i < tour.length; ++i) {
  const node = this.graph[tour[i]];

  tempPath.push(node);
 }

 let pointOrdering = this.points.map((point) => point.id);

 let newPath = [];

 for (let node of tempPath) {
  if (node.ids) {
   for (let id of node.ids) {
    if (pointOrdering[0] === id) {
     newPath.push({ id, lat: node.lat, lng: node.lng });
     pointOrdering.shift();
     break;
    }
   }
  } else {
   newPath.push({ lat: node.lat, lng: node.lng });
  }
 }

 return newPath;
};

export default ProjectedGraphGenerator;
