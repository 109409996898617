import React from "react";
import { Layer, Feature } from "react-mapbox-gl";
import JumpTo from "./JumpTo";
import { ImageLayer } from "./MapWithImage";

import Mapbox from "./Mapbox";

/**
 * Combines the ToursMap component with a map image layer and JumpTo utility component
 * all wrapped inside a Mapbox component
 */
const ToursPreview = (props) => {
 const { containerStyle, tour, location, imageCoord, imageUrl, imageOpacity } = props;
 const zoom = React.useRef();

 return (
  <Mapbox
   //eslint-disable-next-line
   // style="mapbox://styles/mapbox/dark-v10"
   style="mapbox://styles/mapbox/satellite-streets-v11"
   center={location}
   containerStyle={containerStyle}
   // onZoomEnd={(val) => (zoom.current = val.getZoom())}
  >
   <ToursMap tour={tour} />
   <JumpTo zoom={zoom.current} location={location} />

   <ImageLayer
    coordinates={imageCoord}
    url={imageUrl}
    //url={url}
    opacity={imageOpacity}
   />
  </Mapbox>
 );
};

const circleStyles = {
 "circle-color": "#FFFFFF",
 "circle-radius": ["interpolate", ["linear"], ["zoom"], 14, 2, 18, 8],
 "circle-stroke-width": ["interpolate", ["linear"], ["zoom"], 14, 1, 18, 3],
 "circle-stroke-color": ["case", ["boolean", ["get", "selected"], true], "#FF7F1D", "#FFFFFF"],
};

const textStyles = {
 "text-color": "#FFFFFF",
};
const textLayout = {
 "text-anchor": "bottom",
 "text-offset": [0, -1],
 "text-field": ["get", "label"],
};

const lineLayout = {
 "line-cap": "round",
 "line-join": "round",
};

const linePaint = {
 "line-color": "#FF7F1D",
 "line-width": 3,
};

/**
 * Converts map to an array of key,value objects and stringifies it to remove references
 */
export const stringifyMapObj = (map) => {
 return JSON.stringify(mapToArrayObj(map));
};
/**
 * Returns map, converted to an array of key,value objects
 */
export const mapToArrayObj = (map) => {
 return [...map.entries()].map((entry) => ({
  key: entry[0],
  value: entry[1],
 }));
};
/**
 * Generates a new Map from a stringified source
 */
export const parseMap = (source) => {
 return new Map(JSON.parse(source));
};
/**
 * Returns a stringified array of map entries
 */
export const stringifyMap = (map) => {
 return JSON.stringify([...map.entries()]);
};

/**
 * Component for displaying tour path.
 * For use within a Mapbox component
 */
const ToursMap = ({ tour, selectedNode }) => {
 const [update, setUpdate] = React.useState();
 React.useEffect(() => {
  setUpdate(Math.random());
 }, [tour]);
 // Recalculate only when graph or lastNode changes
 const renderNodes = React.useCallback(() => {
  return tour.map((point, index) => <Feature coordinates={[point.lng, point.lat]} key={index} properties={{ selected: point.id === selectedNode }} />);
 }, [tour, selectedNode]);

 const renderText = React.useCallback(() => {
  return tour.filter((p) => p.id !== undefined).map((point, index) => <Feature coordinates={[point.lng, point.lat]} key={point.id} properties={{ label: index + 1 }} />);
 }, [tour]);

 // useCallback ensure this is cached so that it doesn't render
 // unless graph or selected node/line has changed
 const renderLines = React.useCallback(() => {
  let lines = tour
   // Generate line coordinates
   .map(
    (point, index) =>
     index !== 0 && [
      [tour[index - 1].lng, tour[index - 1].lat],
      [point.lng, point.lat],
     ]
   );

  return (
   <>
    <Layer id="lines" type="line" layout={lineLayout} paint={linePaint}>
     {lines.map((line) => (
      <Feature key={line.toString()} coordinates={line} />
     ))}
    </Layer>
   </>
  );
 }, [tour]);

 return (
  <>
   {renderLines()}
   <Layer id="nodes" type="circle" paint={circleStyles}>
    {renderNodes()}
   </Layer>
   <Layer id="text" type="symbol" paint={textStyles} layout={textLayout}>
    {renderText()}
   </Layer>
  </>
 );
};

export default ToursPreview;
