import React from "react";
import { Avatar, Button, ButtonGroup, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Box, Paper, Typography } from "@mui/material";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FormHeader } from "../styled-components";

const pointHeight = 100;

/**
 * Re-orderable list of tour points (records to visit)
 */
const TourPoints = ({ points, setPoints }) => {
 const scrollBarRef = React.useRef(null);

 // Swaps the order of two tour points
 const swapElements = (index) => {
  if (index >= 0 && index < points.length) {
   const newPoints = [...points];
   const elements = newPoints.splice(index, 2);

   newPoints.splice(index, 0, ...elements.reverse());
   setPoints(newPoints);
  }
 };

 // Removes a tour point from the tour
 const removeElement = (index) => {
  const newPoints = [...points];
  newPoints.splice(index, 1);
  setPoints(newPoints);
 };

 return (
  <Box>
   <FormHeader>Tour Stops</FormHeader>
   <Paper
    ref={scrollBarRef}
    elevation={2}
    sx={{
     m: 1,
     flexGrow: 1,
     overflowY: "scroll",
     minHeight: "475px",
     maxHeight: "675px",
     display: "flex",
     flexDirection: "column",
     position: "relative",
    }}
   >
    {points.map((point, index) => (
     <Paper
      variant="outlined"
      key={index}
      sx={{
       display: "flex",
       alignItems: "center",
       height: pointHeight,
       minHeight: pointHeight,
       maxHeight: pointHeight,
      }}
     >
      <Typography noWrap={true} sx={{ ml: 1 }}>
       {index + 1}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
       {index !== 0 && (
        <Button
         sx={{ p: 0 }}
         size="small"
         variant="contained"
         onClick={(e) => {
          // e.target.scrollIntoView({
          //   behavior: "smooth",
          // });
          // e.target.scroll(0, 0);
          scrollBarRef.current.scroll({
           left: 0,
           top: scrollBarRef.current.scrollTop - pointHeight,
           behavior: "smooth",
          });
          // scrollBarRef.scroll(10, 0);
          // scrollBarRef.current.scrollTo(0, 0);
          swapElements(index - 1);
         }}
        >
         <ArrowDropUpIcon />
        </Button>
       )}
       {index !== points.length - 1 && (
        <Button
         sx={{ p: 0 }}
         size="small"
         variant="contained"
         onClick={(e) => {
          scrollBarRef.current.scroll({
           left: 0,
           top: scrollBarRef.current.scrollTop + pointHeight,
           behavior: "smooth",
          });
          // scrollBarRef.current.scroll(0, 0);
          // scrollBarRef.current.scrollTo(0, 0);
          swapElements(index);
         }}
        >
         <ArrowDropDownIcon />
        </Button>
       )}
      </Box>
      <Box sx={{ maxWidth: "230px" }}>
       <Typography fontSize="large" noWrap={true}>
        {point.name || `${point.forename} ${point.surname}`}
       </Typography>
       <Typography fontSize="small" noWrap={true}>
        {point.description ? point.description : ""}
       </Typography>
      </Box>
      <Box sx={{ display: "flex", ml: "auto", alignItems: "center" }}>
       <Avatar sx={{ width: "50px", height: "50px" }} src={point.photo} />
       <Button sx={{ height: "40px" }} onClick={() => removeElement(index)}>
        remove
       </Button>
      </Box>
     </Paper>
    ))}
   </Paper>
  </Box>
 );
};

export default TourPoints;
