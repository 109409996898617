import * as React from "react";

import { Box, TextField, FormControlLabel, Switch, FormGroup } from "@mui/material";
import { handleTextChange, handleSwitchChange, handleFloatChange } from "../../utilities";

/**
 * Form details component for a burial item with reduced editable fields
 * @param {*} param0 object containing formState and setFormState function
 */
const BurialShortForm = ({ setFormState, formState }) => {
 return (
  <Box
   sx={{
    display: "flex",
    flexDirection: "column",
    m: 1,
   }}
  >
   <Box sx={{ display: "flex" }}>
    <FormControlLabel
     sx={{ margin: "auto" }}
     control={<Switch checked={formState.Active} name="Active" onChange={(e) => handleSwitchChange(e, setFormState, formState)} />}
     label="Display in mobile app"
    />
    <FormControlLabel
     sx={{ margin: "auto" }}
     control={<Switch checked={formState.BurialTypeVeteran} name="BurialTypeVeteran" onChange={(e) => handleSwitchChange(e, setFormState, formState)} />}
     label="Burial Type Veteran"
    />
    <FormControlLabel sx={{ margin: "auto" }} control={<Switch checked={formState.Notable} name="Notable" onChange={(e) => handleSwitchChange(e, setFormState, formState)} />} label="Notable" />
   </Box>
   <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
    <TextField
     sx={{ flexGrow: 1 }}
     required
     label="Forename"
     variant="filled"
     name="Forename"
     value={formState.Forename}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
    />
    <TextField
     sx={{ flexGrow: 1 }}
     required
     label="Surname"
     variant="filled"
     name="Surname"
     value={formState.Surname}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
    />
   </Box>

   <TextField
    label="Description"
    variant="filled"
    name="Description"
    multiline
    maxRows={10}
    value={formState.Description}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />

   <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
    <TextField
     sx={{ flexGrow: 1 }}
     label="Latitude"
     variant="filled"
     name="Lat"
     type="number"
     value={formState.Lat}
     onChange={(e) => {
      handleFloatChange(e, setFormState, formState);
     }}
     inputProps={{
      min: -90,
      max: 90,
      step: "any",
     }}
    />
    <TextField
     sx={{ flexGrow: 1 }}
     label="Longitude"
     variant="filled"
     name="Lng"
     type="number"
     value={formState.Lng}
     onChange={(e) => {
      handleFloatChange(e, setFormState, formState);
     }}
     inputProps={{
      min: -180,
      max: 180,
      step: "any",
     }}
    />
   </Box>
  </Box>
 );
};

export default BurialShortForm;
