import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "post" method,
 * accepting three arguments (cemetery, Item, type) in the sendRequest function,
 * updating the url to record,
 * and providing the Item as the request body
 */
class PostRecord extends Request {
 constructor() {
  super("post", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const cemetery = args[0];
  const Item = args[1];
  const type = args[2];

  this.url = `record/${cemetery}/${type}`;
  return this.sendRequestPromise(Item);
 }
}

export default PostRecord;
