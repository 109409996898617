import Controller from "./Controller.js";

import GetRecord from "./routes/GetRecord.js";
import GetOrganization from "./routes/GetOrganization.js";
import Refresh from "./routes/Refresh.js";
import Register from "./routes/Register.js";
import Profile from "./routes/Profile.js";
import DeleteRecord from "./routes/DeleteRecord.js";
import PostRecord from "./routes/PostRecord.js";
import PostOrganization from "./routes/PostOrganization.js";
import DeleteOrganization from "./routes/DeleteOrganization.js";
import Authenticate from "./routes/Authenticate.js";
import CreateCemetery from "./routes/CreateCemetery.js";
import GetCemetery from "./routes/GetCemetery.js";
import UpdateCemetery from "./routes/UpdateCemetery.js";
import RemoveMedia from "./routes/RemoveMedia.js";
import DeleteCemetery from "./routes/DeleteCemetery.js";
import EmptyCemetery from "./routes/EmptyCemetery.js";
import GetRecords from "./routes/GetRecords.js";
import GetOrganizations from "./routes/GetOrganizations.js";
import GetCemeteries from "./routes/GetCemeteries.js";
import BulkDelete from "./routes/BulkDelete.js";
import BulkGet from "./routes/BulkGet.js";
import BulkGetCemeteries from "./routes/BulkGetCemeteries.js";
import BulkGetOrganizations from "./routes/BulkGetOrganizations.js";
import GetMediaUrl from "./routes/GetMediaUrl.js";
import UploadMedia from "./routes/UploadMedia.js";
import ChunkUpload from "./routes/BulkUpload.js";
import Search from "./routes/Search.js";
import Logout from "./routes/Logout.js";
import ForgotPassword from "./routes/ForgotPassword.js";
import ResetPassword from "./routes/ResetPassword.js";
import AssignOrganizations from "./routes/AssignOrganizations.js";
import DissociateOrganization from "./routes/DissociateOrganization.js";
import { wrap, wrapRefresh } from "./utility/Wrappers.js";
import bulkUpload from "./utility/BulkUpload.js";

import { convertAndCompress } from "./utility/ImageConversion.js";

import { ProjectedGraphGenerator } from "./utility/tours/src/main.js";

// Extends the Controller class with API request functions.

// Without Authorizer
Controller.prototype.refresh = wrap(Refresh);
Controller.prototype.logout = wrap(Logout);
Controller.prototype.authenticate = wrap(Authenticate);
Controller.prototype.forgotPassword = wrap(ForgotPassword);
Controller.prototype.resetPassword = wrap(ResetPassword);
Controller.prototype.register = wrap(Register);
Controller.prototype.getRecord = wrap(GetRecord);
Controller.prototype.getOrganization = wrap(GetOrganization);
Controller.prototype.getRecords = wrap(GetRecords);
Controller.prototype.getOrganizations = wrap(GetOrganizations);
Controller.prototype.getCemeteries = wrap(GetCemeteries);
Controller.prototype.bulkGetCemeteries = wrap(BulkGetCemeteries);
Controller.prototype.search = wrap(Search);
Controller.prototype.bulkGetOrganizations = wrap(BulkGetOrganizations);
// With Authorizer
Controller.prototype.profile = wrapRefresh(Profile);
Controller.prototype.deleteRecord = wrapRefresh(DeleteRecord);
Controller.prototype.createCemetery = wrapRefresh(CreateCemetery);
Controller.prototype.getCemetery = wrapRefresh(GetCemetery);
Controller.prototype.updateCemetery = wrapRefresh(UpdateCemetery);
Controller.prototype.postRecord = wrapRefresh(PostRecord);
Controller.prototype.postOrganization = wrapRefresh(PostOrganization);
Controller.prototype.uploadMedia = wrapRefresh(UploadMedia);
Controller.prototype.removeMedia = wrapRefresh(RemoveMedia);
Controller.prototype.bulkDelete = wrapRefresh(BulkDelete);
Controller.prototype.bulkGet = wrapRefresh(BulkGet);
Controller.prototype.deleteCemetery = wrapRefresh(DeleteCemetery);
Controller.prototype.emptyCemetery = wrapRefresh(EmptyCemetery);
Controller.prototype.deleteOrganization = wrapRefresh(DeleteOrganization);
Controller.prototype.getMediaUrl = wrapRefresh(GetMediaUrl);
Controller.prototype.chunkUpload = wrapRefresh(ChunkUpload);
Controller.prototype.assignOrganizations = wrapRefresh(AssignOrganizations);
Controller.prototype.dissociateOrganization = wrapRefresh(DissociateOrganization);

Controller.prototype.bulkUpload = bulkUpload;
Controller.prototype.convertAndCompress = convertAndCompress;

// Faces of Valour SDK object
const FOV = { api: new Controller(), ProjectedGraphGenerator };
// Globally accessible reference to Controller
window.Controller = Controller;
export default FOV;
