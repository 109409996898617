import React, { createContext, useReducer } from "react";
import { chunkArrayInGroups } from "../../utilities";
import FOV from "../src/main.js";
/**
 * Attempts to sign in a user and then fetch their profile.
 * Sets state cemeteries list to the user's cemetery list on success.
 * Sets signed in to true and email to the user's email on success.
 * Sets signed in to false on failure.
 * Sets waiting to true for the duration of the request.
 */
const signIn = (dispatch) => (params) => {
 return new Promise((resolve, reject) => {
  FOV.api
   .authenticate(params.email, params.password)
   .then(() => {
    dispatch({ type: "SET_SIGNED_IN", payload: true });
    dispatch({ type: "SET_WAITING", payload: false });
    dispatch({ type: "SET_EMAIL", payload: params.email });
    FOV.api
     .profile()
     .then((result) => {
      dispatch({
       type: "SET_CEMETERIES",
       payload: result.data.Cemeteries,
      });
      resolve(result.data.SK);
     })
     .catch((err) => {
      console.error(err);
      reject(err);
     });
   })
   .catch((err) => {
    dispatch({ type: "SET_SIGNED_IN", payload: false });
    dispatch({ type: "SET_WAITING", payload: false });
    reject(err);
   });
 });
};
/**
 * Attempts to sign in a user via refresh token and then fetch their profile.
 * Sets state cemeteries list to the user's cemetery list on success.
 * Sets signed in to true and email to the user's email on success.
 * Sets signed in to false on failure.
 * Sets waiting to true for the duration of the request.
 */
const refresh = (dispatch) => (params) => {
 FOV.api
  .refresh()
  .then(() => {
   FOV.api
    .profile()
    .then((result) => {
     dispatch({
      type: "SET_CEMETERIES",
      payload: result.data.Cemeteries,
     });
     dispatch({ type: "SET_EMAIL", payload: result.data.SK });
     dispatch({ type: "SET_WAITING", payload: false });
    })
    .catch(console.error);
  })
  .catch(() => {
   dispatch({ type: "SET_SIGNED_IN", payload: false });
   dispatch({ type: "SET_WAITING", payload: false });
  });
};

/**
 * Attempts to fetch a user's profile.
 * Sets state cemeteries list to the user's cemetery list on success.
 */
const getProfile = (dispatch) => (params) => {
 FOV.api.profile().then((result) => {
  dispatch({
   type: "SET_CEMETERIES",
   payload: result.data.Cemeteries,
  });
 });
};
/**
 * Attempts to logout a user.
 * Resets state to logged out default.
 */
const logout = (dispatch) => (params) => {
 FOV.api
  .logout()
  .then(() => {
   dispatch({ type: "SET_SIGNED_IN", payload: false });
   dispatch({ type: "SET_WAITING", payload: false });
   dispatch({ type: "SET_SELECTED_SITE", payload: null });
   dispatch({ type: "SET_EMAIL", payload: undefined });
   dispatch({ type: "SET_CEMETERIES", payload: [] });
   dispatch({ type: "SET_CEMETERY_ITEMS", payload: [] });
   dispatch({ type: "SET_FILTERED_CEMETERY_ITEMS", payload: [] });
  })
  .catch(console.error);
};
/**
 * Sets signed in state to the provided value
 */
const setSignedIn = (dispatch) => (state) => {
 dispatch({ type: "SET_SIGNED_IN", payload: state });
};
/**
 * Sets cemeteries array to the provided value or an empty array
 */
const setCemeteries = (dispatch) => (cemeteries) => {
 dispatch({ type: "SET_CEMETERIES", payload: cemeteries || [] });
};
/**
 * Attempts to batch get a list of cemeteries.
 * Sets state cemetery items list to the fetched cemeteries on success.
 * Sets loading sites to true for the duration of the request.
 */
const getCemeteryItems = (dispatch) => async (cemeteries) => {
 dispatch({
  type: "SET_LOADING_SITES",
  payload: true,
 });
 let cemeteryList = [];
 if (cemeteries.length) {
  let groups = chunkArrayInGroups(cemeteries, 100);
  for (let group of groups) {
   try {
    const result = await FOV.api.bulkGetCemeteries(group);
    const bulkItems = result.data.Responses[Object.keys(result.data.Responses)[0]];
    cemeteryList.push(...bulkItems);
   } catch (err) {
    console.error(err);
   }
  }
 }
 dispatch({
  type: "SET_CEMETERY_ITEMS",
  payload: cemeteryList.sort((a, b) => a.Name.localeCompare(b.Name)),
 });
 dispatch({
  type: "SET_LOADING_SITES",
  payload: false,
 });
};
/**
 * Sets the cemetery items state to the provided value, sorted by Name property
 */
const setCemeteryItems = (dispatch) => (state) => {
 dispatch({
  type: "SET_CEMETERY_ITEMS",
  payload: state.sort((a, b) => a.Name.localeCompare(b.Name)),
 });
};
/**
 * Sets the filtered cemetery items state to the provided value
 */
const setFilteredCemeteryItems = (dispatch) => (cemeteries) => {
 dispatch({ type: "SET_FILTERED_CEMETERY_ITEMS", payload: cemeteries });
};
/**
 * Attempts to fetch a cemetery.
 * Sets overview state to the result on success.
 */
const getOverview = (dispatch) => (id) => {
 FOV.api
  .getCemetery(id)
  .then((result) => {
   dispatch({ type: "SET_OVERVIEW", payload: result.data });
  })
  .catch(console.error);
};
/**
 * Sets loading state to the provided value
 */
const setLoading = (dispatch) => (state) => {
 dispatch({ type: "SET_LOADING", payload: state });
};
/**
 * Sets loading record state to the provided value
 */
const setLoadingRecord = (dispatch) => (state) => {
 dispatch({ type: "SET_LOADING_RECORD", payload: state });
};
/**
 * Sets the query string state for sites to the provided value
 */
const setSitesQuery = (dispatch) => (state) => {
 dispatch({ type: "SET_SITES_QUERY", payload: state });
};
/**
 * Sets the selected site state to the provided value
 */
const setSelectedSite = (dispatch) => (state) => {
 dispatch({ type: "SET_SELECTED_SITE", payload: state });
};

/**
 * Returns state, mutated by an action, dependent on the action type
 */
const Reducer = (state, action) => {
 switch (action.type) {
  case "SET_WAITING":
   return {
    ...state,
    waiting: action.payload,
   };
  case "SET_EMAIL":
   return {
    ...state,
    email: action.payload,
   };
  case "SET_SIGNED_IN":
   return {
    ...state,
    signedIn: action.payload,
   };
  case "SET_CEMETERIES":
   return {
    ...state,
    cemeteries: action.payload,
   };
  case "SET_CEMETERY_ITEMS":
   return {
    ...state,
    cemeteryItems: action.payload,
   };
  case "SET_FILTERED_CEMETERY_ITEMS":
   return {
    ...state,
    filteredCemeteryItems: action.payload,
   };
  case "SET_OVERVIEW":
   return {
    ...state,
    overview: action.payload,
   };
  case "SET_LOADING":
   return {
    ...state,
    loading: action.payload,
   };
  case "SET_LOADING_RECORD":
   return {
    ...state,
    loadingRecord: action.payload,
   };
  case "SET_LOADING_SITES":
   return {
    ...state,
    loadingSites: action.payload,
   };
  case "SET_SITES_QUERY":
   return {
    ...state,
    sitesQuery: action.payload,
   };
  case "SET_SELECTED_SITE":
   return {
    ...state,
    selectedSite: action.payload,
   };
  default:
   return state;
 }
};

/**
 * Creates react context and a context provider component and returns an
 * object containing both
 */
const createAppContext = (reducer, actions, initialState) => {
 const Context = createContext();

 const Provider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const boundActions = {};

  for (let key in actions) {
   boundActions[key] = actions[key](dispatch);
  }
  return <Context.Provider value={{ state, ...boundActions }}>{props.children}</Context.Provider>;
 };

 return { Context, Provider };
};

export const { Provider, Context } = createAppContext(
 Reducer,
 {
  signIn,
  setSignedIn,
  setCemeteries,
  setCemeteryItems,
  getCemeteryItems,
  setFilteredCemeteryItems,
  getOverview,
  refresh,
  getProfile,
  logout,
  setLoading,
  setSitesQuery,
  setSelectedSite,
  setLoadingRecord,
 },
 {
  signedIn: true,
  cemeteries: [],
  cemeteryItems: [],
  filteredCemeteryItems: [],
  overview: {},
  email: undefined,
  waiting: true,
  loading: true,
  loadingSites: true,
  sitesQuery: "",
  selectedSite: null,
  loadingRecord: false,
 }
);
