import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "get" method,
 * updating the url to profile
 */
class Profile extends Request {
 constructor() {
  super("get", `profile`);
 }
 sendRequest() {
  return this.sendRequestPromise({});
 }
}

export default Profile;
