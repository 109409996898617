import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "post" method,
 * accepting four arguments (cemetery, type, id, mapping) in the sendRequest function,
 * updating the url to trim-media,
 * and providing a body object containing the mapping
 */
class TrimMediaFolder extends Request {
 constructor() {
  super("post", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const cemetery = args[0];
  const type = args[1];
  const id = args[2];
  const mapping = args[3];

  this.url = `trim-media/${cemetery}/${type}/${id}`;
  return this.sendRequestPromise({ mapping });
 }
}

export default TrimMediaFolder;
