import React from "react";

import { Box, TextField } from "@mui/material";
import { handleTextChange, handleNumberChange } from "../../utilities";

/**
 * Form details component for a cemetery item
 * @param {*} param0 object containing formState and setFormState function
 */
const OverviewDetails = ({ setFormState, formState }) => {
 return (
  <Box
   sx={{
    display: "flex",
    flexDirection: "column",
    m: 1,
   }}
  >
   <TextField
    required
    label="Site Name"
    variant="filled"
    name="Name"
    value={formState.Name}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <TextField
    label="Country"
    name="Country"
    value={formState.Country}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
    variant="filled"
   />
   <TextField
    label="City"
    name="City"
    value={formState.City}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
    variant="filled"
   />
   <TextField
    label="State / Province"
    name="StateProvince"
    value={formState.StateProvince}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
    variant="filled"
   />
   <TextField
    label="Operator"
    name="Operator"
    value={formState.Operator}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
    variant="filled"
   />
   <TextField
    label="Website"
    name="Website"
    value={formState.Website}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
    variant="filled"
   />
   <TextField
    label="Primary Account Holder"
    name="PrimaryAccountHolder"
    value={formState.PrimaryAccountHolder}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
    variant="filled"
   />
   <TextField
    label="Casualties"
    name="Casualties"
    type="number"
    value={formState.Casualties.toString()}
    onChange={(e) => {
     handleNumberChange(e, setFormState, formState);
    }}
    variant="filled"
    inputProps={{
     min: 0,
    }}
   />
   <TextField
    label="Description"
    name="Description"
    multiline
    maxRows={10}
    value={formState.Description}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
    variant="filled"
   />
   <TextField
    label="Highlights"
    name="Highlights"
    multiline
    maxRows={10}
    value={formState.Highlights}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
    variant="filled"
   />
   <TextField
    label="Accessibility"
    name="Accessibility"
    multiline
    maxRows={10}
    value={formState.Accessibility}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
    variant="filled"
   />
  </Box>
 );
};

export default OverviewDetails;
