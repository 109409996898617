import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "delete" method,
 * accepting an organization argument in the sendRequest function,
 * updating the url to organization
 */
class DeleteOrganization extends Request {
 constructor() {
  super("delete", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const organization = args[0];
  this.url = `organization/${organization}`;
  return this.sendRequestPromise({});
 }
}

export default DeleteOrganization;
