import { FormControlLabel, Paper, Box, Switch, Divider } from "@mui/material";
import React from "react";
import FormDetails from "../../components/form-details";
import { handleSwitchChange } from "../../utilities";
import GalleryUpload from "../GalleryUpload";
import ImageUpload from "../ImageUpload";

/**
 * Tour form details component
 */
const TourForm = ({ formState, setFormState, media, setMedia, newMedia, setNewMedia }) => {
 return (
  <Paper
   elevation={2}
   sx={{
    flexGrow: 1,
    width: "100%",
    maxWidth: { xs: "700px", xl: "500px" },
    display: "flex",
    mt: 0,
   }}
  >
   <Box sx={{ width: "100%" }}>
    <FormControlLabel
     sx={{
      margin: "auto",
      display: "flex",
      justifyContent: "center",
     }}
     control={<Switch checked={formState.Active} name="Active" onChange={(e) => handleSwitchChange(e, setFormState, formState)} />}
     label="Display in mobile app"
    />
    <Divider />
    <ImageUpload small fieldName="Logo" formState={formState} newMedia={newMedia} setNewMedia={setNewMedia} />
    <FormDetails type="tour" setFormState={setFormState} formState={formState} />
    <Paper
     square
     sx={{
      m: "10px",
      padding: "10px",
      maxWidth: "calc(100% - 20px)",
      background: (theme) => theme.palette.tertiary.main,
     }}
    >
     <GalleryUpload formState={formState} newMedia={newMedia} setNewMedia={setNewMedia} media={media} setMedia={setMedia} />
    </Paper>
   </Box>
  </Paper>
 );
};

export default TourForm;
