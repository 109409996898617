import React from "react";
import { Typography, Divider, Box, Accordion, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadTable from "../../components/Upload/UploadTable";

import { headers, columns } from "../../assets/Upload/example-tables";

/**
 * Bulk Upload guide graphic component
 */
const Guide = ({ type }) => {
 return (
  <Box sx={{ p: 1, maxWidth: "100%" }}>
   <Accordion sx={{ minWidth: "100%", maxWidth: "100%" }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
     <Typography>Guide</Typography>
    </AccordionSummary>
    <Divider />
    <Box sx={{ m: 2, fontSize: "18px" }}>
     <Typography>To bulk upload records, you need to create a zip archive containing a CSV and any images associated with the CSV.</Typography>
     <br />
     <Typography>Before you upload, make sure that you have done the following:</Typography>
     <ol>
      <li>
       <Typography>Check that your csv headings match up with the ones in the template below and all mandatory (*) fields are filled.</Typography>
      </li>
      <br />
      <li>
       <Typography>Make sure to export the csv with all fields enclosed in "speech marks".</Typography>
      </li>
      <br />
      <li>
       <Typography>Make sure any rich media/asset fields have the correct path to their respective media.</Typography>
      </li>
      <br />
      <li>
       {" "}
       <Typography>Add the csv and images to a zip archive before uploading.</Typography>
      </li>
     </ol>
    </Box>
    <Divider />
   </Accordion>
   <Accordion sx={{ minWidth: "100%", maxWidth: "100%" }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ width: "100%" }}>
     <Typography>CSV Template ({type}s)</Typography>
    </AccordionSummary>
    <Divider />
    <UploadTable headers={headers[type]} columns={columns[type]} />
   </Accordion>
  </Box>
 );
};

export default Guide;
