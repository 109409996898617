import React from "react";

import { TextField, Paper, FormLabel } from "@mui/material";

import ImageUpload from "../ImageUpload";

import { handleFloatChange } from "../../utilities";

/**
 * Image Upload and 4 corner coordinate inputs combined into a component
 */
const MapEditor = ({ formState, setFormState, newMedia, setNewMedia }) => {
 return (
  <ImageUpload fieldName="Map" formState={formState} setFormState={setFormState} newMedia={newMedia} setNewMedia={setNewMedia}>
   <FormLabel component="legend" sx={{ textAlign: "center" }}>
    Map Corner Coordinates
   </FormLabel>
   <div style={{ display: "flex" }}>
    <div
     style={{
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
     }}
    >
     <TextField
      label="Lat A"
      name="MapALat"
      type="number"
      value={formState.MapALat.toString()}
      onChange={(e) => {
       handleFloatChange(e, setFormState, formState);
      }}
      variant="filled"
      inputProps={{
       min: -90,
       max: 90,
       step: "any",
      }}
     />
     <TextField
      label="Lat D"
      name="MapDLat"
      type="number"
      value={formState.MapDLat.toString()}
      onChange={(e) => {
       handleFloatChange(e, setFormState, formState);
      }}
      variant="filled"
      inputProps={{
       min: -90,
       max: 90,
       step: "any",
      }}
     />
    </div>
    <div
     style={{
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
     }}
    >
     <TextField
      label="Lng A"
      name="MapALng"
      type="number"
      value={formState.MapALng}
      onChange={(e) => {
       handleFloatChange(e, setFormState, formState);
      }}
      variant="filled"
      inputProps={{
       min: -180,
       max: 180,
       step: "any",
      }}
     />
     <TextField
      label="Lng D"
      name="MapDLng"
      type="number"
      value={formState.MapDLng}
      onChange={(e) => {
       handleFloatChange(e, setFormState, formState);
      }}
      variant="filled"
      inputProps={{
       min: -180,
       max: 180,
       step: "any",
      }}
     />
    </div>
    <div
     style={{
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
     }}
    >
     <TextField
      required
      label="Lat B"
      name="MapBLat"
      type="number"
      value={formState.MapBLat}
      onChange={(e) => {
       handleFloatChange(e, setFormState, formState);
      }}
      variant="filled"
      inputProps={{
       min: -90,
       max: 90,
       step: "any",
      }}
     />
     <TextField
      required
      label="Lat C"
      name="MapCLat"
      type="number"
      value={formState.MapCLat}
      onChange={(e) => {
       handleFloatChange(e, setFormState, formState);
      }}
      variant="filled"
      inputProps={{
       min: -90,
       max: 90,
       step: "any",
      }}
     />
    </div>
    <div
     style={{
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
     }}
    >
     <TextField
      required
      label="Lng B"
      name="MapBLng"
      type="number"
      value={formState.MapBLng}
      onChange={(e) => {
       handleFloatChange(e, setFormState, formState);
      }}
      variant="filled"
      color="error"
      inputProps={{
       min: -180,
       max: 180,
       step: "any",
      }}
     />
     <TextField
      required
      label="Lng C"
      name="MapCLng"
      type="number"
      value={formState.MapCLng}
      onChange={(e) => {
       handleFloatChange(e, setFormState, formState);
      }}
      variant="filled"
      inputProps={{
       min: -180,
       max: 180,
       step: "any",
      }}
     />
    </div>
   </div>
  </ImageUpload>
 );
};

export default MapEditor;
