import * as React from "react";
import { List, Collapse, ListItem, ListItemText, Button, Divider, ListItemIcon, Box, Paper } from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

/**
 * Nav dropdown component
 */
function Dropdown({ title, icon, children }) {
 const [open, setOpen] = React.useState(false);

 const handleClick = () => {
  setOpen((prevOpen) => !prevOpen);
 };

 return (
  <List>
   <ListItem component={Button} onClick={handleClick}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
    {open ? <ExpandLess /> : <ExpandMore />}
   </ListItem>

   <Collapse
    component="li"
    in={open}
    timeout="auto"
    unmountOnExit
    sx={{
     overflowY: "scroll",
     maxHeight: "440px",
    }}
    className="scrollbar-hidden"
   >
    <Box sx={{ ml: 1.25, boxShadow: "5px 0px 20px" }}>
     <Divider />
     {children}
     <Divider />
    </Box>
   </Collapse>
  </List>
 );
}

export default Dropdown;
