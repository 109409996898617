import * as React from "react";

import { Box, TextField } from "@mui/material";
import { handleTextChange } from "../../utilities";

/**
 * Form details component for a tour item
 * @param {*} param0 object containing formState and setFormState function
 */
const TourDetails = ({ setFormState, formState }) => {
 return (
  <Box
   sx={{
    display: "flex",
    flexDirection: "column",
    m: 1,
   }}
  >
   <TextField
    required
    label="Name"
    variant="filled"
    name="Name"
    value={formState.Name}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />

   <TextField
    label="Description"
    variant="filled"
    name="Description"
    multiline
    maxRows={10}
    value={formState.Description}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
   <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
    <TextField
     sx={{ flexGrow: 1 }}
     label="Start Date"
     variant="filled"
     name="DateStart"
     value={formState.DateStart}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
     type="date"
     InputLabelProps={{ shrink: true }}
    />
    <TextField
     sx={{ flexGrow: 1 }}
     label="End Date"
     variant="filled"
     name="DateEnd"
     value={formState.DateEnd}
     onChange={(e) => {
      handleTextChange(e, setFormState, formState);
     }}
     type="date"
     InputLabelProps={{ shrink: true }}
    />
   </Box>
   <TextField
    label="ExpectedDuration"
    variant="filled"
    name="ExpectedDuration"
    value={formState.ExpectedDuration}
    onChange={(e) => {
     handleTextChange(e, setFormState, formState);
    }}
   />
  </Box>
 );
};

export default TourDetails;
