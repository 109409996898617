import Request from "../utility/Request.js";

/**
 * Extends the Request class, specifying the "delete" method,
 * accepting five arguments (cemetery, type, id, mediaType, name) in the sendRequest function,
 * updating the url to media
 */
class RemoveMedia extends Request {
 constructor() {
  super("delete", ``);
 }
 sendRequest() {
  const args = arguments[0];
  const cemetery = args[0];
  const type = args[1];
  const id = args[2];
  const mediaType = args[3];
  const name = args[4];

  this.url = `media/${cemetery}/${type}/${id}/${mediaType}/${name}`;
  return this.sendRequestPromise({});
 }
}

export default RemoveMedia;
