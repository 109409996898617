import * as React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { Context } from "../../SDK/context";

import FOV from "../../SDK/src/main";

/**
 * Confirmation dialog component for deleting a site
 */
const DeleteSiteDialog = ({ open, setOpen, siteToDelete, setSiteToDelete }) => {
 const { state, setCemeteries, setCemeteryItems } = React.useContext(Context);
 const [submitting, setSubmitting] = React.useState(false);

 const handleClose = () => {
  setSubmitting(false);
  setSiteToDelete(false);
  setOpen(false);
 };

 /**
  * Attempts to delete a cemetery given the submitted form values.
  * Updates cemetery list
  * @param {*} event form submit event
  */
 const handleSubmit = async (event) => {
  event.preventDefault();
  setSubmitting(true);
  try {
   await FOV.api.deleteCemetery(siteToDelete.SK);
   setCemeteries(state.cemeteries.filter((sk) => sk !== siteToDelete.SK));
   setCemeteryItems(state.cemeteryItems.filter((site) => site.SK !== siteToDelete.SK));
   handleClose();
  } catch (err) {
   alert(`An unexpected error occurred. See the console for more details\n${JSON.stringify(err)}`);
  }
 };

 return (
  <Dialog open={open}>
   <DialogTitle>Delete Site</DialogTitle>
   <form onSubmit={handleSubmit}>
    <DialogContent>
     <DialogContentText sx={{ mb: 2 }}>
      Are you sure you want to delete <b>"{siteToDelete.Name}"</b> ?
     </DialogContentText>
     <DialogContentText>
      This will permanently remove:
      <li>All files related to the site</li>
      <li>All database records related to the site</li>
      <li>All search engine indexes related to the site</li>
     </DialogContentText>
    </DialogContent>
    <DialogActions>
     <Button disabled={submitting} onClick={handleClose}>
      Cancel
     </Button>
     <LoadingButton color="error" loading={submitting} type="submit">
      Yes, I'm sure
     </LoadingButton>
    </DialogActions>
   </form>
  </Dialog>
 );
};

export default DeleteSiteDialog;
